import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { User } from '../../modules/security/models/user.model';
import { environment } from '../../../environments/environment';
import { httpHeaders } from '../../../environments/httpHeaders';
import { Observable } from 'rxjs';

const apiURL = environment.apiUrl;
const headers = httpHeaders.headers

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  constructor(
    private _http:HttpClient
  ) { }

  public getDoctors(){
      let endPoint = "/api/admin/byrole/DO";
      return this._http.get(apiURL + endPoint, {headers:headers}).pipe(map((resp:User[]) => {
        return resp;
      }))
  }

  public createTreatment(request){
    let endPoint = "/api/treatment"
    return this._http.put(`${apiURL}${endPoint}`, request, {headers:headers});
  }

  public getTreatment(id){
    let endPoint = `/api/treatment/${id}`;
    return this._http.get(`${apiURL}${endPoint}`, {headers:headers});
  }

  public updatePatientData(request: any) {
    let endPoint = "/api/treatment/updatepatientdata"
    return this._http.post(`${apiURL}${endPoint}`, request, {headers:headers});
  }

  public saveTreatment(request){
    let endPoint = "/api/treatment"
    return this._http.post(`${apiURL}${endPoint}`, request, {headers:headers});
  }

  public gotoNextPhaseRevision(request){
    let endPoint = "/api/treatmentplan/gotonext"
    return this._http.post(`${apiURL}${endPoint}`, request, {headers:headers});
  }

  public changeStatus(reason:string, treatmentId: any, endpoint:string, request?:any) {
    let endPoint = `/api/treatment/changestatus/${endpoint}`;

    request = request ? request : {
      idTreatment: +treatmentId,
      reason:reason
    }

    return this._http.post(`${apiURL}${endPoint}`, request, {headers:headers});
  }

  public getTreatmentPlanPdf(idTreatment: any, language: string) {
    let endPoint = `/api/TreatmentPlan/Pdf/${idTreatment}/${language}`;
    return this._http.get<Blob>(`${apiURL}${endPoint}`, {observe: 'response', responseType: 'blob' as 'json'});
  }

  public deleteTreatment(idTreatment: any){
    let endPoint = "/api/treatment"
    return this._http.delete(`${apiURL}${endPoint}/${idTreatment}`, {headers:headers});
  }

  public updateWaitingForSTL(value:boolean, idTreatment: any){
    let endPoint = `/api/treatment/updatewaitingforstl/${value}/${idTreatment}`;
    return this._http.get(`${apiURL}${endPoint}`, {headers:headers});    
  }

  updateTreatmentPlanLevel(idTreatment: any, value: any) {
    let endPoint = `/api/treatmentplan/updatelevel/${idTreatment}/${value}`;
    return this._http.get(`${apiURL}${endPoint}`, {headers:headers});    
  }

  updateTreatmentPlanFieldBool(request: any) {
    let endPoint = `/api/treatmentplan/updatetreatmentplanfield`;
    return this._http.post(`${apiURL}${endPoint}`, request, {headers:headers});
  }

  migrateFromQuote(idQuote: any) {
    let endPoint = `/api/treatment/migratefromquote/${idQuote}`;
    return this._http.get(`${apiURL}${endPoint}`, {headers:headers});    
  }

  confirmQuote(idQuote: any) {
    let endPoint = `/api/quote/confirmquote/${idQuote}`;
    return this._http.get(`${apiURL}${endPoint}`, {headers:headers});    
  }

}
