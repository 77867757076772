import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './core/layout/layout.component';
import { AuthGuard } from './pages/auth/auth.guard';
import { CanDeactivateGuard } from './pages/auth/can-deactivate.guard';
import { TreatmentsNextPhaseComponent } from './pages/treatments-next-phase/treatments-next-phase.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path:"",
        loadChildren: () => import("./pages/auth/auth.module").then(r => r.AuthModule)
      },
    ]
  },
  {
    path:"",
    component:LayoutComponent,
    canActivate:[AuthGuard],
    children:[
      {
        path:"users",
        canActivate:[AuthGuard],
        pathMatch:"full",
        loadChildren: () => import("./pages/users/users.module").then(m => m.UsersModule)
      },
      {
        path:"treatments",
        loadChildren: () => import("./pages/treatments/treatments.module").then(m => m.TreatmentsModule)
      },
      {
        path:"quotes",
        loadChildren: () => import("./pages/quotes/quotes.module").then(m => m.QuotesModule)
      },
      {
        path:"",
        loadChildren: () => import("./pages/new-patient/new-patient.module").then(m=> m.NewPatientModule)
      },
      {
        path:"",
        loadChildren: () => import("./pages/new-quote/new-quote.module").then(m=> m.NewQuoteModule)
      },
      {
        path:"",
        loadChildren: () => import("./pages/new-doctor/new-doctor.module").then(m=> m.NewDoctorModule)
      },
      {
        path:"planning",
        loadChildren: () => import("./pages/planning-page/planning-page.module").then(m=> m.PlanningPageModule)
      },
      {
        path:"users-group",
        loadChildren: () => import("./pages/user-group/user-group.module").then(m => m.UserGroupModule)
      },
      {
        path:"holidays",
        loadChildren: () => import("./pages/holidays/holidays.module").then(m => m.HolidaysModule)
      },
      {
        path:"calendar",
        loadChildren: () => import("./pages/calendar-page/calendar-page.module").then(m => m.CalendarPageModule)
      },
      {
        path: 'myprofile',
        loadChildren: () => import('./pages/my-profile/my-profile.module').then(m => m.MyProfileModule)
      },
      {
        path: 'livechat',
        loadChildren: () => import('./pages/live-chat/live-chat.module').then(m => m.LiveChatModule)
      },
      {
        canActivate:[AuthGuard],
        //canDeactivate: [CanDeactivateGuard],
        path:"next-phase/:id",
        loadChildren: () => import("./pages/treatments-next-phase/treatments-next-phase.module").then(m=> m.TreatmentsNextPhaseModule)
      },
      {
        canActivate:[AuthGuard],
        path:"treatments-report",
        loadChildren: () => import("./pages/treatments-report/treatments-report.module").then(m=> m.TreatmentsReportModule)
      },
      {
        canActivate:[AuthGuard],
        path:"treatment-planning/:id",
        loadChildren: () => import("./pages/treatment-planning/treatment-planning.module").then(m=> m.TreatmentPlanningModule)
      }
    ]
  },
  {
    path:"users",
    component:LayoutComponent,
    canActivate:[AuthGuard],
    children:[
      {
        path:"",
        canActivate:[AuthGuard],
        pathMatch:"full",
        loadChildren: () => import("./pages/users/users.module").then(m => m.UsersModule)
      },
      {
        path:"edit/:id",
        canActivate:[AuthGuard],
        pathMatch:"full",
        loadChildren: () => import("./pages/create-edit-user-page/create-edit-user-page.module").then(m => m.CreateEditUserPageModule)
      },
      {
        path:"new",
        canActivate:[AuthGuard],
        pathMatch:"full",
        loadChildren: () => import("./pages/create-edit-user-page/create-edit-user-page.module").then(m => m.CreateEditUserPageModule)
      },
      {
        path:"list",
        canActivate:[AuthGuard],
        pathMatch:"full",
        loadChildren: () => import("./pages/doctors-list/doctors-list.module").then(m => m.DoctorsListModule)
      },
    ]
  },
  {
    path:"dashboard",
    component:LayoutComponent,
    canActivate:[AuthGuard],
    children:[
      {
        path:"",
        loadChildren: () => import("./pages/dashboard/dashboard-statistics.module").then(m => m.DashboardStatisticsModule)
      }, 
      {
        path: 'list/:filter',
        loadChildren: () => import('./pages/dashboard-treatments-list/dashboard-treatments-list.module').then(m => m.DashboardTreatmentsListModule)
      }
      // {
      //   path: 'apps/chat',
      //   loadChildren: () => import('./pages/chat/chat.module').then(m => m.ChatModule)
      // },
      // {
      //   path: 'components',
      //   loadChildren: () => import('./pages/components/components.module').then(m => m.ComponentsModule)
      // },
      // {
      //   path: 'forms',
      //   loadChildren: () => import('./pages/forms/forms.module').then(m => m.FormModule)
      // },
      
      // {
      //   path: 'apps/inbox',
      //   loadChildren: () => import('./pages/inbox/inbox.module').then(m => m.InboxModule)
      // },
      // {
      //   path: 'pages/profile',
      //   loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule)
      // },
      // {
      //   path: 'tables/simple-table',
      //   loadChildren: () => import('./pages/tables/simple-table/simple-table.module').then(m => m.SimpleTableModule)
      // },
      // {
      //   path: 'tables/table-pagination',
      //   loadChildren: () => import('./pages/tables/table-pagination/table-pagination.module').then(m => m.TablePaginationModule)
      // },
      // {
      //   path: 'tables/table-sorting',
      //   loadChildren: () => import('./pages/tables/table-sorting/table-sorting.module').then(m => m.TableSortingModule)
      // },
      // {
      //   path: 'tables/table-filtering',
      //   loadChildren: () => import('./pages/tables/table-filtering/table-filtering.module').then(m => m.TableFilteringModule)
      // },
      // {
      //   path: 'tables/datatable',
      //   loadChildren: () => import('./pages/tables/datatable/datatable.module').then(m => m.DatatableModule)
      // },
      // {
      //   path: 'tables/all-in-one-table',
      //   loadChildren: () => import('./pages/tables/all-in-one-table/all-in-one-table.module').then(m => m.AllInOneTableModule)
      // },
      // {
      //   path: 'maps/google-maps',
      //   loadChildren: () => import('./pages/google-maps/google-maps.module').then(m => m.GoogleMapsModule)
      // },
      // {
      //   path: 'pages/projects',
      //   loadChildren: () => import('./pages/projects/projects.module').then(m => m.ProjectsModule)
      // },
      // {
      //   path: 'pages/project-details',
      //   loadChildren: () => import('./pages/project-details/project-details.module').then(m => m.ProjectDetailsModule)
      // },
      // {
      //   path: 'material-icons',
      //   loadChildren: () => import('./pages/icon/icon.module').then(m => m.IconModule)
      // },
      // {
      //   path: 'editor',
      //   loadChildren: () => import('./pages/editor/editor.module').then(m => m.EditorModule)
      // },
      // {
      //   path: 'drag-and-drop',
      //   loadChildren: () => import('./pages/drag-and-drop/drag-and-drop.module').then(m => m.DragAndDropModule)
      // }
    ]
  },
  {
    path:"contents",
    component:LayoutComponent,
    canActivate:[AuthGuard],
    children:[
      {
        path:"",
        loadChildren:() => import('./pages/contents/contents.module').then(m=> m.ContentsModule)
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // initialNavigation: 'enabled',
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
