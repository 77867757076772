import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from '../../../../../environments/environment';
import { httpHeaders } from '../../../../../environments/httpHeaders';

const apiURL = environment.apiUrl;
const headers = httpHeaders.headers

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(
    private http:HttpClient
  ) { }

  public getMessages(treatmentId, counter, chatType){
    let endPoint='';
    if (chatType == 'Q'){
      endPoint = `/api/quote/chat/${treatmentId}/${counter}`
    }else if (chatType == 'T'){
      endPoint = `/api/treatment/chat/${treatmentId}/${counter}`
    }else if (chatType == 'I'){
      endPoint = `/api/treatment/incomplete/chat/${treatmentId}/${counter}`
    }
    
    return this.http.get(`${apiURL}${endPoint}`, {headers:headers});
  }
  
  public sendMessage(id, message,chatType){
    let endPoint='';
    let request;
    if (chatType == 'Q'){
      endPoint = `/api/quote/chat/`;
      request = {
        "message": message,
        "idQuote": id
      }
    }else if (chatType == 'T'){
      endPoint = `/api/treatment/chat/`;
      request = {
        "message": message,
        "idTreatment": id
      }
    }else if (chatType == 'I'){
      endPoint = `/api/treatment/incomplete/chat/`;
      request = {
        "message": message,
        "idTreatment": id
      }
    }
    return this.http.post(`${apiURL}${endPoint}`, request, {headers:headers});
  }
}
