import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { ChangeDetectorRef, Component, ComponentRef, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MatSnackBar, MatSnackBarConfig, MatTabChangeEvent } from '@angular/material';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { User } from '../../../modules/security/models/user.model';
import { UserService } from '../../../modules/security/services/user.service';
import { ContentsService } from '../../contents/services/contents.service';
import { ConvertService } from '../../shared/services/convert.service';
import { UsersService } from '../../users/users.service';
import { PatientService } from '../patient.service';
import * as FileSaver from 'file-saver';
import { TreatmentsService } from '../../treatments/treatments.service';
import { Observable, Observer, Subject, Subscription } from 'rxjs';
import { TreatmentAdditionalFunctionalService } from '../treatment-additional-functional/treatment-additional-functional.service';
import { debounceTime, delay, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { DragAndDropFileComponent } from '../../components/drag-and-drop-file/drag-and-drop-file.component';
import { TreatmentModalComponent } from '../treatment-modal/treatment-modal.component';
import { ChatService } from '../treatment-additional-functional/treatment-chat/chat.service';
import { MessagingServiceService } from '../../shared/services/messagingServiceService';
import * as moment from 'moment';

@Component({
  selector: 'nuvolaweb-new-patient',
  templateUrl: './new-patient.component.html',
  styleUrls: ['./new-patient.component.scss']
})
export class NewPatientComponent implements OnInit, OnDestroy {
  @ViewChild('notFoundModal', { static: true }) notFoundModal: MatDialogRef<any>;
  @ViewChild('additionalModal', { static: true }) additionalModal: MatDialogRef<any>;
  @ViewChild('incompleteModal', { static: true }) incompleteModal: MatDialogRef<any>;
  @ViewChild('schedulePickUp', { static: true }) schedulePickUp: MatDialogRef<any>;
  @ViewChild('imagePreview', { static: true }) imagePreview: MatDialogRef<any>;
  @ViewChild('confirmModal', { static: true }) confirmModal: MatDialogRef<any>;
  @ViewChild('draftModal', { static: true }) draftModal: MatDialogRef<any>;
  @ViewChild('DoctorAdditionalInfo', { static: true }) DoctorAdditionalInfo: MatDialogRef<any>;
  @ViewChild('PatientAdditionalInfo', { static: true }) PatientAdditionalInfo: MatDialogRef<any>;
  @ViewChild('updateStatusModal', { static: true }) updateStatusModal: MatDialogRef<any>;
  @ViewChild('infoModal', { static: true }) infoModal: MatDialogRef<any>;

  @ViewChild('stlFileUpper', { static: false }) stlFileUpper: DragAndDropFileComponent;
  @ViewChild('stlFileLower', { static: false }) stlFileLower: DragAndDropFileComponent;
  @ViewChild('fileLoadProgressModal', { static: true }) fileLoadProgressModal: MatDialogRef<any>;

  @ViewChild('WebViewerFileSelector', { static: true }) WebViewerFileSelector: MatDialogRef<any>;
  @ViewChild('stepNotValidModal', { static: true }) stepNotValidModal: MatDialogRef<any>;

  //refreshIncompleteChat: Subject<boolean> = new Subject();

  selectedIndex = 0;
  selectedIndexOld = 0;
  lastIndex = 5;

  tabOneForm: FormGroup;
  tabThreeForm: FormGroup;
  tabTwoForm: FormGroup;
  tabFourForm: FormGroup;
  tabFiveForm: FormGroup;
  tabSixForm: FormGroup;

  step1Valid: boolean = false;
  step2Valid: boolean = false;
  step3Valid: boolean = false;
  step4Valid: boolean = false;
  step5Valid: boolean = false;
  step6Valid: boolean = false;

  selectedReturnAddress: string = '';

  scheduleForm: FormGroup;
  availableScheduleTime: any[] = [];

  patientType: string = "";
  doctors: User[];
  currentUser: User;
  public isMobile: boolean = false;
  public treatmentId: any;
  public allowUseTabs: boolean = false;
  isLoading: boolean;
  selectedTreatment: any;
  returns: any[];
  shippings: any[];
  invoices: any[];
  typesOfElastic: any[];
  public upperTeeth: any[];
  public lowerTeeth: any[];
  additionalModalText: any;
  selectedModalType: string;
  reasonFormControl: FormControl;
  otherReasonFormControl: FormControl;
  draftReason: FormControl;
  fileToBeUploaded: any[] = [];
  fileToBeUploadedMerged: any[] = [];
  otherReasonList: any[];
  disabled: boolean;
  disabledSTL: boolean;
  disabledOptionalImages: boolean;
  allowDeleteOptionalImages: boolean = true;
  EmptyFile: File = null;
  isVerifyInProgres: boolean = false;
  public collapsed: boolean = false;

  private _subsctiptions: Subscription;
  fileScansToBeUploaded: any[] = [];
  selectedUpperTeeth: any[] = [];
  selectedLowerTeeth: any[] = [];

  imagesCollection = [];
  storageBaseUrl: string = environment.storageBaseUrl;

  modalMessage: any;

  public doctorAddInfoQuestion1: string;
  public doctorAddInfoQuestion11: string;
  // doctorAddInfoQuestion2: string;
  // doctorAddInfoQuestion3: string;
  public doctorAddInfoHowManyPatients: boolean = false;
  public doctorClinicPreferences: string;
  public doctorUserFullName: string;
  public doctorAgentFullName: string;
  public doctorInternalNotes: string;
  public doctorWhatsappNumber: string;
  public doctorShowNuvolaJunior: string;

  public waitingForSTL: boolean = true;
  public showImageMandatoryAsterisk: boolean = true;
  public showAllOtherImageMandatoryAsterisk: boolean = false;

  public progressUpload: number = 0;
  public progressUploadSaving: boolean = false;
  public singleFileProgressValue: number = 0;
  public fileUploadProgressCounter: number = 0;
  public currentFileIterator: number = 0;
  public totalFileCount: number = 0;
  private request: any;
  private reload = false;
  private isError = false;

  private isUploading = false;
  public speedUpload: number = 0;
  public unitUpload: string = "Mbps";

  public SaveAndSend: boolean = false;
  public updateStatusNewValue: string = "";
  public isUpdatieStillLoading: boolean = false
  uploadSub: Subscription;

  public webViewerItems = [];
  public webViewerItemSelected: string;
  checkModal: any;
  isMovingEvent: boolean = false;

  public clinicPreferencesUpdade: boolean = true;
  public patientInfoIconColor: string = "black";

  public videoLink: string = '';

  treatmentPlanAllMandatoryFieldsCompleted:boolean=false;

  constructor(
    private fb: FormBuilder,
    private _convertService: ConvertService,
    private _patientService: PatientService,
    private _userService: UserService,
    private _usersService: UsersService,
    private _breakpointObserver: BreakpointObserver,
    private _router: Router,
    private _activeRoute: ActivatedRoute,
    private _translocoService: TranslocoService,
    private modalServide: MatDialog,
    private snackBar: MatSnackBar,
    private cd: ChangeDetectorRef,
    private _contentService: ContentsService,
    private treatmentsService: TreatmentsService,
    private treatmentAdditionalFunctionalService: TreatmentAdditionalFunctionalService,
    private chatService: ChatService,
    private _messagingService: MessagingServiceService
  ) {
    this._breakpointObserver.observe([
      Breakpoints.Handset
    ]).subscribe(result => {
      this.isMobile = result.matches;
    });

    this._subsctiptions = this._router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (!event.url.startsWith("/treatments/")) {
          this.treatmentAdditionalFunctionalService.isPanelCollapsed = false;
        }
        if (!this.treatmentId || event.navigationTrigger != "popstate") {
          this.treatmentsService.reset();
        }
      }
    })
  }

  ngOnDestroy(): void {
    this._subsctiptions.unsubscribe();
  }

  async ngOnInit() {

    this.treatmentAdditionalFunctionalService.changePanelContentStatus(false);

    this.prepareTeeth();

    this.tabThreeForm = this.fb.group({
      archesType: ['null', [Validators.required, customNullValidator()]],
      patientAgeType: ['null', [Validators.required, customNullValidator()]],
      archesTypeHandle: ['null', [Validators.required, customNullValidator()]],
      patientType: ['null', [Validators.required, customNullValidator()]],
      scanLower: [null, Validators.required],
      scanUpper: [null, Validators.required],
      archesSentViaCommunicator: [false],
      clinicProject: [false],
    });

    this._subsctiptions.add(this.tabThreeForm.controls.archesSentViaCommunicator.valueChanges.pipe(debounceTime(25)).subscribe(value => {
      if (value) {
        this.tabThreeForm.controls.scanLower.clearValidators();
        this.tabThreeForm.controls.scanLower.setValue(this.tabThreeForm.controls.scanLower.value)
        this.tabThreeForm.controls.scanUpper.clearValidators();
        this.tabThreeForm.controls.scanUpper.setValue(this.tabThreeForm.controls.scanUpper.value)
      } else {
        this.tabThreeForm.controls.scanLower.setValidators(Validators.required);
        this.tabThreeForm.controls.scanLower.setValue(this.tabThreeForm.controls.scanLower.value)
        this.tabThreeForm.controls.scanUpper.setValidators(Validators.required);
        this.tabThreeForm.controls.scanUpper.setValue(this.tabThreeForm.controls.scanUpper.value)
      }
      this.cd.detectChanges();
    }));

    this.scheduleForm = this.fb.group({
      pickupDate: [null, Validators.required],
      pickupNote: ['']
    });

    this.tabTwoForm = this.fb.group({
      return: ['null', [Validators.required, customNullValidator()]],
      ship: ['null', [Validators.required, customNullValidator()]],
      invoice: ['null', [Validators.required, customNullValidator()]]
    });

    this.tabFourForm = this.fb.group({
      leftSide: [null],
      frontSide: [null],
      rightSide: [null],
      upperArch: [null, Validators.required],
      rsOrthopanoramics: [null],
      telecranio: [null],
      other: [null],
      bottomArch: [null, Validators.required],
      leftOcclusal: [null, Validators.required],
      frontOcclusal: [null, Validators.required],
      rightOcclusal: [null, Validators.required],
      imageType: ["S", Validators.required],
      imageCollage: [null, Validators.required],
    });

    this.tabFourForm.controls.imageType.valueChanges.pipe(debounceTime(10)).subscribe(value => {

      if (this.selectedTreatment && (this.selectedTreatment.phase != '1' || this.selectedTreatment.phaseType == 'R')
        && this.selectedTreatment.status == 'INCOMPLETE') {
        //all is optional
        this.tabFourForm.controls.upperArch.clearValidators();
        this.tabFourForm.controls.upperArch.reset();
        this.tabFourForm.controls.bottomArch.clearValidators();
        this.tabFourForm.controls.bottomArch.reset();
        this.tabFourForm.controls.leftOcclusal.clearValidators();
        this.tabFourForm.controls.leftOcclusal.reset();
        this.tabFourForm.controls.frontOcclusal.clearValidators();
        this.tabFourForm.controls.frontOcclusal.reset();
        this.tabFourForm.controls.rightOcclusal.clearValidators();
        this.tabFourForm.controls.rightOcclusal.reset();
        this.tabFourForm.controls.imageCollage.clearValidators();
        this.tabFourForm.controls.imageCollage.reset();
      }
      else {
        if (value == "S") {
          this.tabFourForm.controls.upperArch.setValidators(Validators.required);
          this.tabFourForm.controls.upperArch.setValue(this.tabFourForm.controls.upperArch.value);
          this.tabFourForm.controls.bottomArch.setValidators(Validators.required);
          this.tabFourForm.controls.bottomArch.setValue(this.tabFourForm.controls.bottomArch.value);
          this.tabFourForm.controls.leftOcclusal.setValidators(Validators.required);
          this.tabFourForm.controls.leftOcclusal.setValue(this.tabFourForm.controls.leftOcclusal.value);
          this.tabFourForm.controls.frontOcclusal.setValidators(Validators.required);
          this.tabFourForm.controls.frontOcclusal.setValue(this.tabFourForm.controls.frontOcclusal.value);
          this.tabFourForm.controls.rightOcclusal.setValidators(Validators.required);
          this.tabFourForm.controls.rightOcclusal.setValue(this.tabFourForm.controls.rightOcclusal.value);
          this.tabFourForm.controls.imageCollage.clearValidators();
          this.tabFourForm.controls.imageCollage.reset();
        } else {
          this.tabFourForm.controls.upperArch.clearValidators();
          this.tabFourForm.controls.upperArch.reset();
          this.tabFourForm.controls.bottomArch.clearValidators();
          this.tabFourForm.controls.bottomArch.reset();
          this.tabFourForm.controls.leftOcclusal.clearValidators();
          this.tabFourForm.controls.leftOcclusal.reset();
          this.tabFourForm.controls.frontOcclusal.clearValidators();
          this.tabFourForm.controls.frontOcclusal.reset();
          this.tabFourForm.controls.rightOcclusal.clearValidators();
          this.tabFourForm.controls.rightOcclusal.reset();
          this.tabFourForm.controls.imageCollage.setValidators(Validators.required);
          this.tabFourForm.controls.imageCollage.setValue(this.tabFourForm.controls.imageCollage.value);
        }
      }

      this.onTreatmentTypeChange(this.tabThreeForm.controls.patientType.value);

    })

    // this.tabFiveForm = this.fb.group({
    //   noExcludeTeeth: [false, customNullValidator()],
    //   //objectiveRadioButton: [null, Validators.required],  
    //   typeOfElastic: [null],
    //   biteRamp: ["null", [Validators.required, customNullValidator()]],
    //   crowdingUpperArch: ["null", [customNullValidator()]],
    //   crowdingLowerArch: ["null", [customNullValidator()]],
    //   // crowdingUpperArchWorkflow: ["null", [Validators.required, customNullValidator()]],
    //   // crowdingLowerArchWorkflow: ["null", [Validators.required, customNullValidator()]],
    //   crowdingUpperArchWorkflow: ["null", [customNullValidator()]],
    //   crowdingLowerArchWorkflow: ["null", [customNullValidator()]],
    //   upperArchDiastema: ["null", [customNullValidator()]],
    //   lowerArchDiastema: ["null", [customNullValidator()]],
    //   changeClass: [null],
    //   //anteriorTreatment: [false, [customFalseValidator()]],
    //   // overJet: [{ value: true, disabled: true }],
    //   // overByte: [{ value: true, disabled: true }],
    //   overJetType: [null, Validators.required],
    //   overByteType: [null, Validators.required],
    //   crossBiteType: [null, Validators.required]
    // });

    this.tabFiveForm = this.fb.group({
      noExcludeTeeth: [false, customNullValidator()],
      typeOfElastic: [null],
      biteRamp: ["null"],
      crowdingUpperArch: ["null"],
      crowdingLowerArch: ["null"],
      crowdingUpperArchWorkflow: ["null"],
      crowdingLowerArchWorkflow: ["null"],
      upperArchDiastema: ["null"],
      lowerArchDiastema: ["null"],
      changeClass: [null],
      overJetType: [null],
      overByteType: [null],
      crossBiteType: [null]
    });

    this.tabFiveForm.controls.changeClass.valueChanges.subscribe(resp => {
      if (this.tabFiveForm.controls.changeClass.value) {
        //this.tabFiveForm.controls.anteriorTreatment.clearValidators();
        this.selectedTreatment.elasticType == 'YS' ? this.tabFiveForm.controls.typeOfElastic.setValue('YS') : this.selectedTreatment.elasticType == 'NO' ? this.tabFiveForm.controls.typeOfElastic.setValue('NO') : this.tabFiveForm.controls.typeOfElastic.setValue(null)
      } else {
        this.tabFiveForm.controls.typeOfElastic.setValue("N")

      }
    });



    this.tabFiveForm.controls.noExcludeTeeth.valueChanges.subscribe(resp => {
      if (!this.tabFiveForm.controls.noExcludeTeeth.value && (this.selectedTreatment.teethToExclude.length == 0)) {
        this.tabFiveForm.controls.noExcludeTeeth.setErrors({ 'required': true })
      } else {
        this.tabFiveForm.controls.noExcludeTeeth.setErrors(null)

      }
    })


    this.tabSixForm = this.fb.group({
      noSpecialInstruction: [false],
      specialInstruction: [null, [Validators.required]],
      videoRequested: [false],
    });

    this.currentUser = this._userService.readUserFromLocalStorage();

    if (this.currentUser.role == "DO") {
      this.tabOneForm = this.fb.group({
        patientType: ['gdpr', Validators.required],
        patientFirstName: [null],
        patientLastName: [null],
        registrationDate: [{ value: new Date(this._convertService.dateConvert(new Date(Date.now()).toUTCString(), "YYYY-MM-DD")), disabled: true }, Validators.required],
        patientGDPR: [""],
        changeCheckBox: [false],
        plannedShippingDate: null,
        idDoctor: [{ value: this.currentUser.idAdmin, disabled: true }, [Validators.required, customNullValidator()]],
      });
      this.doctors = [this.currentUser];
    } else {
      this.tabOneForm = this.fb.group({
        patientType: ['gdpr', Validators.required],
        patientFirstName: [""],
        patientLastName: [""],
        registrationDate: [{ value: new Date(this._convertService.dateConvert(new Date(Date.now()).toUTCString(), "YYYY-MM-DD")), disabled: true }, Validators.required],
        patientGDPR: [""],
        changeCheckBox: [false],
        plannedShippingDate: null,
        idDoctor: ["null", [Validators.required, customNullValidator()]],
      });
    }

    this.isLoading = true;
    this._patientService.getDoctors().subscribe(resp => {
      this.doctors = resp;
      this.isLoading = false;
      this.cd.detectChanges();
    });

    this._activeRoute.params.subscribe(param => {
      this.treatmentId = param["id"];
      if (this.treatmentId) {
        this.prepareTreatmentCall();
      }
    });

    this._subsctiptions.add(this.tabThreeForm.controls.archesTypeHandle.valueChanges.pipe(debounceTime(25)).subscribe(value => {
      //console.log('archesTypeHandle', value);
      if (this.tabThreeForm.controls.archesType.value == "T" || this.tabThreeForm.controls.archesType.value == "null") {
        // return;
      }

      switch (value) {
        case "U":
          // this.tabThreeForm.controls.scanLower.clearValidators();
          // this.tabThreeForm.controls.scanUpper.setValidators(Validators.required);
          // this.tabThreeForm.controls.scanLower.reset();
          // this.selectedTreatment.scanLower = null;

          this.tabFiveForm.controls.crowdingLowerArch.setValue(false);
          this.tabFiveForm.controls.crowdingLowerArch.setErrors(null);
          this.tabFiveForm.controls.crowdingLowerArchWorkflow.setValue([]);
          this.tabFiveForm.controls.crowdingLowerArchWorkflow.clearValidators()
          this.tabFiveForm.controls.crowdingLowerArchWorkflow.updateValueAndValidity();

          // if ((this.tabFiveForm.controls.upperArchDiastema.value == "N") || (this.tabFiveForm.controls.upperArchDiastema.value == null)) {
          //   this.tabFiveForm.controls.upperArchDiastema.setErrors({ 'required': true });
          // }

          this.tabFiveForm.controls.lowerArchDiastema.setErrors(null);
          this.tabFiveForm.controls.lowerArchDiastema.setValue("N");
          this.tabFiveForm.controls.lowerArchDiastema.updateValueAndValidity();

          break;
        case "L":
          // this.tabThreeForm.controls.scanUpper.clearValidators();
          // this.tabThreeForm.controls.scanUpper.reset();
          // this.selectedTreatment.scanUpper = null;

          this.tabFiveForm.controls.crowdingUpperArch.setValue(false);
          this.tabFiveForm.controls.crowdingUpperArch.setErrors(null);


          this.tabFiveForm.controls.crowdingUpperArchWorkflow.setErrors(null);
          this.tabFiveForm.controls.crowdingUpperArchWorkflow.setValue([]);
          this.tabFiveForm.controls.crowdingUpperArchWorkflow.clearValidators();
          this.tabFiveForm.controls.crowdingUpperArchWorkflow.updateValueAndValidity();
          this.tabFiveForm.controls.upperArchDiastema.setValue("N");
          this.tabFiveForm.controls.upperArchDiastema.clearValidators();
          this.tabFiveForm.controls.upperArchDiastema.updateValueAndValidity();

          // if (this.tabFiveForm.controls.lowerArchDiastema.value == "N" || (this.tabFiveForm.controls.lowerArchDiastema.value == null)) {
          //   this.tabFiveForm.controls.lowerArchDiastema.setErrors({ 'required': true });
          // }
          break;
        case "B":
        case "null":
          if (this.selectedTreatment.crowdingLowerArchWorkflow == ([])) {
            this.tabFiveForm.controls.crowdingLowerArch.setValue(false);
            this.tabFiveForm.controls.crowdingLowerArch.setErrors(null);
            this.tabFiveForm.controls.crowdingLowerArchWorkflow.setValue([])
          }

          if (this.selectedTreatment.crowdingUpperArchWorkflow == ([])) {
            this.tabFiveForm.controls.crowdingUpperArch.setValue(false);
            this.tabFiveForm.controls.crowdingUpperArch.setErrors(null);
            this.tabFiveForm.controls.crowdingUpperArchWorkflow.setValue([]);
          }

          this.tabFiveForm.controls.lowerArchDiastema.updateValueAndValidity();
          this.tabFiveForm.controls.upperArchDiastema.updateValueAndValidity();
          this.tabFiveForm.controls.noExcludeTeeth.updateValueAndValidity();


          // let lowerArchDiastema = this.tabFiveForm.controls.lowerArchDiastema.value;
          // let upperArchDiastema = this.tabFiveForm.controls.upperArchDiastema.value;

          // if (((lowerArchDiastema != "N") && (upperArchDiastema == "N")) || ((lowerArchDiastema == "N") && (upperArchDiastema != "N"))) {
          //   this.tabFiveForm.controls.lowerArchDiastema.setValue(null);
          //   this.tabFiveForm.controls.lowerArchDiastema.setErrors({ 'required': true });
          //   this.tabFiveForm.controls.upperArchDiastema.setValue(null);
          //   this.tabFiveForm.controls.upperArchDiastema.setErrors({ 'required': true });
          // }

          // if ((this.tabFiveForm.controls.upperArchDiastema.value == "N") || (this.tabFiveForm.controls.upperArchDiastema.value == null)) {
          //   this.tabFiveForm.controls.upperArchDiastema.setErrors({ 'required': true });
          // }
          // if (this.tabFiveForm.controls.lowerArchDiastema.value == "N" || (this.tabFiveForm.controls.lowerArchDiastema.value == null)) {
          //   this.tabFiveForm.controls.lowerArchDiastema.setErrors({ 'required': true });
          // }

          break;
        default:
          // this.tabThreeForm.controls.scanUpper.setValidators(Validators.required);
          // this.tabThreeForm.controls.scanLower.setValidators(Validators.required);
          let upperValue = this.tabThreeForm.controls.scanUpper.value;
          let lowerValue = this.tabThreeForm.controls.scanLower.value;
          this.tabThreeForm.controls.scanUpper.reset();
          this.tabThreeForm.controls.scanLower.reset();
          this.tabThreeForm.controls.scanUpper.setValue(upperValue);
          this.tabThreeForm.controls.scanLower.setValue(lowerValue);
          break;
      }
      this.cd.detectChanges();
    }));

    this._activeRoute.queryParams.subscribe(param => {
      var step = param["step"];
      if (step && (+step > 0 && +step < 6)) {
        this.selectedIndex = (+step) - 1;
      }
    });
    this.collapsed = this.treatmentAdditionalFunctionalService.isPanelCollapsed;

    this.cd.detectChanges();
  }

  prepareTeeth() {

    this.upperTeeth = [];
    this.lowerTeeth = [];
    this.upperTeeth.push(
      { id: "1,8", src: "../../../../assets/img/teeth/1,8.png", style: "1%", isSelected: false },
      { id: "1,7", src: "../../../../assets/img/teeth/1,7.png", style: "1%", isSelected: false },
      { id: "1,6", src: "../../../../assets/img/teeth/1,6.png", style: "1%", isSelected: false },
      { id: "1,5", src: "../../../../assets/img/teeth/1,5.png", style: "0.8%", isSelected: false },
      { id: "1,4", src: "../../../../assets/img/teeth/1,4.png", style: "0.8%", isSelected: false },
      { id: "1,3", src: "../../../../assets/img/teeth/1,3.png", style: "0.5%", isSelected: false },
      { id: "1,2", src: "../../../../assets/img/teeth/1,2.png", style: "0.4%", isSelected: false },
      { id: "1,1", src: "../../../../assets/img/teeth/1,1.png", style: "0.6%", isSelected: false },
      { id: "2,1", src: "../../../../assets/img/teeth/2,1.png", style: "0.7%", isSelected: false },
      { id: "2,2", src: "../../../../assets/img/teeth/2,2.png", style: "0.4%", isSelected: false },
      { id: "2,3", src: "../../../../assets/img/teeth/2,3.png", style: "0.5%", isSelected: false },
      { id: "2,4", src: "../../../../assets/img/teeth/2,4.png", style: "0.7%", isSelected: false },
      { id: "2,5", src: "../../../../assets/img/teeth/2,5.png", style: "0.8%", isSelected: false },
      { id: "2,6", src: "../../../../assets/img/teeth/2,6.png", style: "1%", isSelected: false },
      { id: "2,7", src: "../../../../assets/img/teeth/2,7.png", style: "1%", isSelected: false },
      { id: "2,8", src: "../../../../assets/img/teeth/2,8.png", style: "1%", isSelected: false },
    );

    this.lowerTeeth.push(
      { id: "4,8", src: "../../../../assets/img/teeth/4,8.png", style: "1.2%", isSelected: false },
      { id: "4,7", src: "../../../../assets/img/teeth/4,7.png", style: "1.3%", isSelected: false },
      { id: "4,6", src: "../../../../assets/img/teeth/4,6.png", style: "1.2%", isSelected: false },
      { id: "4,5", src: "../../../../assets/img/teeth/4,5.png", style: "0.7%", isSelected: false },
      { id: "4,4", src: "../../../../assets/img/teeth/4,4.png", style: "0.7%", isSelected: false },
      { id: "4,3", src: "../../../../assets/img/teeth/4,3.png", style: "0.5%", isSelected: false },
      { id: "4,2", src: "../../../../assets/img/teeth/4,2.png", style: "0.15%", isSelected: false },
      { id: "4,1", src: "../../../../assets/img/teeth/4,1.png", style: "0.3%", isSelected: false },
      { id: "3,1", src: "../../../../assets/img/teeth/3,1.png", style: "0.2%", isSelected: false },
      { id: "3,2", src: "../../../../assets/img/teeth/3,2.png", style: "0.2%", isSelected: false },
      { id: "3,3", src: "../../../../assets/img/teeth/3,3.png", style: "0.5%", isSelected: false },
      { id: "3,4", src: "../../../../assets/img/teeth/3,4.png", style: "0.6%", isSelected: false },
      { id: "3,5", src: "../../../../assets/img/teeth/3,5.png", style: "0.7%", isSelected: false },
      { id: "3,6", src: "../../../../assets/img/teeth/3,6.png", style: "1.2%", isSelected: false },
      { id: "3,7", src: "../../../../assets/img/teeth/3,7.png", style: "1.2%", isSelected: false },
      { id: "3,8", src: "../../../../assets/img/teeth/3,8.png", style: "1.2%", isSelected: false },
    )
  }

  selectUpperTooth(upperTeeth) {

    //console.log('selectUpperTooth');

    if (this.disabled) {
      return;
    }

    this.selectedUpperTeeth = [];
    this.upperTeeth.forEach(x => {
      if (upperTeeth.id == x.id) {

        x.isSelected = !x.isSelected;
        this.selectedUpperTeeth.push(...this.upperTeeth.filter(x => x.isSelected).map(x => x.id))

        if (x.isSelected) {
          this.tabFiveForm.controls.noExcludeTeeth.setErrors(null)
        } else if (!x.isSelected && (this.selectedUpperTeeth.length == 0) && (this.selectedLowerTeeth.length == 0)) {
          this.tabFiveForm.controls.noExcludeTeeth.setErrors({ 'invalid': true })

        }
      }
    })
  }

  selectLowerTooth(lowerTeeth) {
    if (this.disabled) {
      return;
    }

    this.selectedLowerTeeth = [];
    this.lowerTeeth.forEach(x => {
      if (lowerTeeth.id == x.id) {

        x.isSelected = !x.isSelected;
        this.selectedLowerTeeth.push(...this.lowerTeeth.filter(x => x.isSelected).map(x => x.id))

        if (x.isSelected) {
          this.tabFiveForm.controls.noExcludeTeeth.setErrors(null)
        } else if (!x.isSelected && (this.selectedUpperTeeth.length == 0) && (this.selectedLowerTeeth.length == 0)) {
          this.tabFiveForm.controls.noExcludeTeeth.setErrors({ 'invalid': true })

        }
      }
    })
  }

  // updateTreatmentMandatoryFieldsCall(event){
  //   console.log('updateTreatmentMandatoryFieldsCall', event.value);    
  //   this.treatmentPlanAllMandatoryFieldsCompleted = event.value;
  // }

  prepareTreatmentCall() {
    console.log('prepareTreatmentCall');

    this._patientService.getTreatment(this.treatmentId).subscribe(async (resp: any) => {
      this.selectedTreatment = resp;

      if (this.currentUser.userGroup == 'PJ')
        this.getClinicPreferencesFlag();

      this.updateStatusNewValue = this.selectedTreatment.status;

      if (this.selectedTreatment.reasonIncompleteOptions.length > 0) {
        try {
          var reasons = await this._usersService.getOptions("IR").toPromise() as any[];
          this.selectedTreatment.reasonOption = reasons.filter(x => this.selectedTreatment.reasonIncompleteOptions.includes(x.optionCode)).map(x => this.getCurrentOptTittle(x)).join(", ");
        } catch {
        }
      }

      this.prepareTabOne();
      this.prepareTabTwo();
      this.prepareTabThree();
      this.prepareTabFour();
      this.prepareTabFive();
      this.prepareTabSix();

      //this.setStepsValidationFlags();

      //if ((this.currentUser.role == "DO" || this.currentUser.role == "SA" || this.currentUser.role == "DI" || this.currentUser.role == "AG")
      if (this.currentUser.role == "DO" && (this.selectedTreatment.status == "DRAFT" || this.selectedTreatment.status == "INCOMPLETE")) {
      } else if (this.currentUser.role == "US" && this.currentUser.userGroup == "ST" && this.selectedTreatment.status == "DRAFT") {
        //START USER (DRAFT) => enable only step3
        this.tabOneForm.disable();
        this.tabTwoForm.disable();
        this.tabFourForm.disable();
        this.tabFiveForm.disable();
        this.tabSixForm.disable();
        this.disabled = true;
      } else if (this.currentUser.role == "US" && this.currentUser.userGroup == "ED" &&
        this.selectedTreatment.status != "PRODUCTION" && this.selectedTreatment.status != "SHIPPED") {
        //EDITOR USER => enable steps 2,3,4,5,6
        this.tabOneForm.disable();
      } else if (this.currentUser.role == "US" && this.currentUser.userGroup == "ST" &&
        this.selectedTreatment.status == "MIGRATED") {
        //EDITOR USER => enable steps 2,3,4,5,6
        this.tabOneForm.disable();
      } else {
        this.tabOneForm.disable();
        this.tabTwoForm.disable();
        this.tabThreeForm.disable();
        this.tabFourForm.disable();
        this.tabFiveForm.disable();
        this.tabSixForm.disable();
        this.disabled = true;
      }
      this.disabledOptionalImages = this.disabled;
      if (this.currentUser.role == 'DO' && (this.selectedTreatment.status == 'INPROJECT' || this.selectedTreatment.status == 'INREVIEW')) {
        //DOCTOR CAN EDIT SHIPPING AND INVOICE ADDRESSES
        this.tabTwoForm.controls.ship.enable();
        this.tabTwoForm.controls.invoice.enable();
        //DOCTOT CAN UPLOAD OPTIONAL IMAGES ON STEP 4
        //this.tabFourForm.controls.leftSide.enable();
        this.disabledOptionalImages = false;
        this.allowDeleteOptionalImages = false;
      }

      //force change patient data checkbox
      if (this.currentUser && this.currentUser.role == 'EX' && this.currentUser.executiveArea == 'A') {
        this.tabOneForm.controls.changeCheckBox.enable();
      }

      //force planned shipping date for START for 2nd Phases/Revisions
      if (this.selectedTreatment && this.currentUser && this.currentUser.role == 'US' &&
        (this.selectedTreatment.phase != '1' || this.selectedTreatment.phaseType == 'R')) {
        if (this.currentUser.userGroup == 'ST')
          this.tabOneForm.controls.plannedShippingDate.enable();
      }

      //highlight mandatory fields
      if (this.currentUser.role == 'DO' && (this.selectedTreatment.status == 'DRAFT' || this.selectedTreatment.status == 'INCOMPLETE')) {
        this.tabTwoForm.markAllAsTouched();
        this.tabThreeForm.markAllAsTouched();
        this.tabFiveForm.markAllAsTouched();
        this.tabSixForm.markAllAsTouched();
      }

      this._usersService.getOptions("ET").subscribe((resp: any[]) => {
        this.typesOfElastic = resp.sort(x => x.optionTitle_EN && x.optionTitle_IT);
      });

      //patient info color
      if (this.selectedTreatment.patientAdditionalNotes != null && this.selectedTreatment.patientAdditionalNotes != '')
        this.patientInfoIconColor = 'red';

      //disable fields for Phase 2 or Revisions
      if (this.selectedTreatment && (this.selectedTreatment.phase != '1' || this.selectedTreatment.phaseType == 'R')
        && this.selectedTreatment.status == 'INCOMPLETE') {
        console.log('FASE 2 O REVISIONE - NON CONFORME!!!');

        this.showImageMandatoryAsterisk = false;

        this.tabThreeForm.controls['patientAgeType'].disable();
        this.tabThreeForm.controls['archesTypeHandle'].disable();
        this.tabThreeForm.controls['patientType'].disable();
        this.tabThreeForm.controls['clinicProject'].disable();

        setTimeout(() => {
          this.tabFourForm.controls.upperArch.clearValidators();
          this.tabFourForm.controls.upperArch.reset();
          this.tabFourForm.controls.bottomArch.clearValidators();
          this.tabFourForm.controls.bottomArch.reset();
          this.tabFourForm.controls.leftOcclusal.clearValidators();
          this.tabFourForm.controls.leftOcclusal.reset();
          this.tabFourForm.controls.frontOcclusal.clearValidators();
          this.tabFourForm.controls.frontOcclusal.reset();
          this.tabFourForm.controls.rightOcclusal.clearValidators();
          this.tabFourForm.controls.rightOcclusal.reset();
          this.tabFourForm.controls.imageCollage.clearValidators();
          this.tabFourForm.controls.imageCollage.reset();
          this.cd.detectChanges();
        }, 500);

        this.tabFiveForm.controls['noExcludeTeeth'].disable();
        this.tabFiveForm.controls['changeClass'].disable();
        this.tabFiveForm.controls['overByteType'].disable();
        this.tabFiveForm.controls['overJetType'].disable();
        this.tabFiveForm.controls['crossBiteType'].disable();
        this.tabFiveForm.controls['crowdingUpperArch'].disable();
        this.tabFiveForm.controls['crowdingUpperArchWorkflow'].disable();
        this.tabFiveForm.controls['crowdingLowerArch'].disable();
        this.tabFiveForm.controls['crowdingLowerArchWorkflow'].disable();
        this.tabFiveForm.controls['upperArchDiastema'].disable();
        this.tabFiveForm.controls['lowerArchDiastema'].disable();
        this.tabFiveForm.updateValueAndValidity();
      }
      this.cd.detectChanges();
    }, error => {
      this.snackBar.open(this._translocoService.translate("patient.patientNotFoundException"), "X", {
        duration: 3000,
        panelClass: "warning",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
      this._router.navigate(["/dashboard"], { replaceUrl: true });
    });
    this.cd.detectChanges();
  }

  savePlannedShippingDate() {
    this.isLoading = true;
    let momentVariable = moment(this.tabOneForm.controls.plannedShippingDate.value, 'DD/MM/YYYY');
    let stringvalue = momentVariable.format('YYYY-MM-DD');

    let request = {
      idTreatment: + this.selectedTreatment.idTreatment,
      shippingDate: stringvalue
    }

    this.treatmentsService.updatePlannedShippingDate(request).subscribe(resp => {
      this.isLoading = false;
      this.snackBar.open(this._translocoService.translate("patient.ADDITIONAL.UpdatePlannedShippingDateMessage"), "X", {
        duration: 3000,
        panelClass: "success",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
      this.cd.detectChanges();
    }, error => {
      //error while saving new planning
      this.isLoading = false;
      this.snackBar.open(this._translocoService.translate("COMMON.ErrorMessage"), "X", {
        duration: 3000,
        panelClass: "warning",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
      this.cd.detectChanges();
    });
  }
  setStepsValidationFlags() {
    //debugger;
    //set flags for 6 Steps (enabled/disabled)
    // if (this.selectedTreatment.status == "DRAFT" || this.selectedTreatment.status == "INCOMPLETE") {
    //   this.step1Valid = this.tabOneForm.valid;
    //   this.step2Valid = this.tabTwoForm.valid;
    //   this.step3Valid = this.tabThreeForm.valid;
    //   this.step4Valid = this.tabFourForm.valid;
    //   this.step5Valid = this.tabFiveForm.valid;
    //   this.step6Valid = this.tabSixForm.valid;
    // }
    // else {
    //   //if status is ahead of DRAFT or INCOMPLETE => all 6 steps are VALID
    //   this.step1Valid = true;
    //   this.step2Valid = true;
    //   this.step3Valid = true;
    //   this.step4Valid = true;
    //   this.step5Valid = true;
    //   this.step6Valid = true;
    // }

    // console.log('selectedTreatment', this.selectedTreatment);    
    // console.log('step2Valid: ' , this.step2Valid);
    // console.log('step4Valid: ' , this.step4Valid);
    // console.log('step5Valid: ' , this.step5Valid);
    // console.log('step6Valid: ' , this.step6Valid);

    this.tabFiveForm.updateValueAndValidity();
    this.tabFiveForm.valueChanges.subscribe(x => {
      console.log('form value changed', x);
    })

  }

  prepareTabSix() {
    setTimeout(() => {
      this.tabSixForm.controls.noSpecialInstruction.setValue(this.selectedTreatment.noSpecialInstruction);
      this.tabSixForm.controls.specialInstruction.setValue(this.selectedTreatment.specialInstruction);
      this.tabSixForm.controls.videoRequested.setValue(this.selectedTreatment.videoRequested);
    }, 0);
    this.onSpecialInstructioCheckBox(this.selectedTreatment.noSpecialInstruction);
  }

  prepareTabFive() {
    this.tabFiveForm.controls.noExcludeTeeth.setValue(this.selectedTreatment.noTeethToExclude);
    //this.tabFiveForm.controls.objectiveRadioButton.setValue(this.selectedTreatment.treatmentAction);
    this.tabFiveForm.controls.typeOfElastic.setValue(this.selectedTreatment.elasticType == "" ? null : this.selectedTreatment.elasticType);
    this.tabFiveForm.controls.biteRamp.setValue(this.selectedTreatment.byteRamp);
    this.tabFiveForm.controls.crowdingUpperArch.setValue(this.selectedTreatment.crowdingUpperArch);
    this.tabFiveForm.controls.crowdingLowerArch.setValue(this.selectedTreatment.crowdingLowerArch);
    // this.tabFiveForm.controls.crowdingUpperArchWorkflow.setValue(this.selectedTreatment.crowdingUpperArchType == "N" ? 'null' : this.selectedTreatment.crowdingUpperArchType);
    // this.tabFiveForm.controls.crowdingLowerArchWorkflow.setValue(this.selectedTreatment.crowdingLowerArchType == "N" ? 'null' : this.selectedTreatment.crowdingLowerArchType);
    this.tabFiveForm.controls.crowdingUpperArchWorkflow.setValue(this.selectedTreatment.crowdingUpperArchType.length == 0 ? null : this.selectedTreatment.crowdingUpperArchType);
    this.tabFiveForm.controls.crowdingLowerArchWorkflow.setValue(this.selectedTreatment.crowdingLowerArchType.length == 0 ? null : this.selectedTreatment.crowdingLowerArchType);
    this.tabFiveForm.controls.upperArchDiastema.setValue(this.selectedTreatment.upperArchDiastema == "N" ? null : this.selectedTreatment.upperArchDiastema);
    this.tabFiveForm.controls.lowerArchDiastema.setValue(this.selectedTreatment.lowerArchDiastema == "N" ? null : this.selectedTreatment.lowerArchDiastema);

    this.tabFiveForm.controls.changeClass.setValue(this.selectedTreatment.changeClass);
    //this.tabFiveForm.controls.anteriorTreatment.setValue(this.selectedTreatment.anteriorTreatment);

    this.tabFiveForm.controls.overJetType.setValue(this.selectedTreatment.overJetType == "N" ? null : this.selectedTreatment.overJetType);
    this.tabFiveForm.controls.overByteType.setValue(this.selectedTreatment.overByteType == "N" ? null : this.selectedTreatment.overByteType);

    this.tabFiveForm.controls.crossBiteType.setValue(this.selectedTreatment.crossBiteType == "N" ? null : this.selectedTreatment.crossBiteType);

    this.lowerTeeth.forEach(x => {
      if ((this.selectedTreatment.teethToExclude as any[]).includes(x.id)) {
        x.isSelected = true;
      }
    })

    this.upperTeeth.forEach(x => {
      if ((this.selectedTreatment.teethToExclude as any[]).includes(x.id)) {
        x.isSelected = true;
      }
    })

    this.onCrowdingLowerSelect(this.selectedTreatment.crowdingLowerArch);
    this.onCrowdingUpperSelect(this.selectedTreatment.crowdingUpperArch);
  }

  prepareTabFour() {
    this.tabFourForm.controls.leftSide.setValue(this.selectedTreatment.imageLS);
    this.tabFourForm.controls.frontSide.setValue(this.selectedTreatment.imageFS);
    this.tabFourForm.controls.rightSide.setValue(this.selectedTreatment.imageRS);
    this.tabFourForm.controls.upperArch.setValue(this.selectedTreatment.imageUA);
    this.tabFourForm.controls.rsOrthopanoramics.setValue(this.selectedTreatment.imageOrtho);
    this.tabFourForm.controls.telecranio.setValue(this.selectedTreatment.imageTelecranio);
    this.tabFourForm.controls.other.setValue(this.selectedTreatment.imageOther);
    this.tabFourForm.controls.bottomArch.setValue(this.selectedTreatment.imageBA);
    this.tabFourForm.controls.leftOcclusal.setValue(this.selectedTreatment.imageLO);
    this.tabFourForm.controls.frontOcclusal.setValue(this.selectedTreatment.imageFO);
    this.tabFourForm.controls.rightOcclusal.setValue(this.selectedTreatment.imageRO);
    this.tabFourForm.controls.imageType.setValue(this.selectedTreatment.imageType);
    this.tabFourForm.controls.imageCollage.setValue(this.selectedTreatment.imageCollage);
  }

  prepareTabThree() {

    this.waitingForSTL = this.selectedTreatment.waitingForSTL;

    if (this.selectedTreatment.archesType == "D" || this.isAllowToViewScanDnD()) {
      this.tabThreeForm.controls.scanLower.setValue(this.selectedTreatment.scanLower ? this.selectedTreatment.scanLower : null);
      this.tabThreeForm.controls.scanUpper.setValue(this.selectedTreatment.scanUpper ? this.selectedTreatment.scanUpper : null);
      this.tabThreeForm.controls.archesSentViaCommunicator.setValue(this.selectedTreatment.archesSentViaCommunicator);
    } else {
      this.tabThreeForm.controls.scanLower.clearValidators();
      this.tabThreeForm.controls.scanUpper.clearValidators();
      this.tabThreeForm.controls.scanUpper.reset();
      this.tabThreeForm.controls.scanLower.reset();
    }
    if (this.selectedTreatment.archesType == "N") {
      this.tabThreeForm.controls.archesType.setValue(null);
    } else {
      this.tabThreeForm.controls.archesType.setValue(this.selectedTreatment.archesType);

    }

    this.tabThreeForm.controls.patientAgeType.setValue(this.selectedTreatment.patientType == "N" ? "null" : this.selectedTreatment.patientType);
    this.tabThreeForm.controls.archesTypeHandle.setValue(this.selectedTreatment.archesHandled == "N" ? "null" : this.selectedTreatment.archesHandled);
    this.tabThreeForm.controls.patientType.setValue(this.selectedTreatment.treatmentType == "NO" ? "null" : this.selectedTreatment.treatmentType);
    this.tabThreeForm.controls.clinicProject.setValue(this.selectedTreatment.clinicProject);
  }

  prepareTabTwo() {
    if (this.selectedTreatment.idAddressReturn == -1) {
      this.tabTwoForm.controls.return.setValue(this.selectedTreatment.idAddressReturn == 0 ? "null" : this.selectedTreatment.idAddressReturn.toString());
    } else {
      this.tabTwoForm.controls.return.setValue(this.selectedTreatment.idAddressReturn == 0 ? "null" : this.selectedTreatment.idAddressReturn);
    }
    this.tabTwoForm.controls.ship.setValue(this.selectedTreatment.idAddressShipping == 0 ? "null" : this.selectedTreatment.idAddressShipping);
    this.tabTwoForm.controls.invoice.setValue(this.selectedTreatment.idAddressInvoice == 0 ? "null" : this.selectedTreatment.idAddressInvoice);
  }

  prepareTabOne() {
    this.tabOneForm.controls.patientFirstName.setValue(this.selectedTreatment.patientFirstName);
    this.tabOneForm.controls.patientLastName.setValue(this.selectedTreatment.patientLastName);
    this.tabOneForm.controls.registrationDate.setValue(this.selectedTreatment.registrationDate);
    this.tabOneForm.controls.patientGDPR.setValue(this.selectedTreatment.patientGDPR);
    this.tabOneForm.controls.idDoctor.setValue(this.selectedTreatment.idDoctor);

    this.tabOneForm.controls.plannedShippingDate.setValue(this.selectedTreatment.plannedShippingDate);

    this.onRadioButtonChange(this.tabOneForm.controls.patientGDPR.value
      ? "gdpr"
      : "patient");
    this.tabOneForm.controls.patientFirstName.disable({ onlySelf: true });
    this.tabOneForm.controls.patientType.disable({ onlySelf: true });
    this.tabOneForm.controls.patientLastName.disable({ onlySelf: true });
    this.tabOneForm.controls.registrationDate.disable({ onlySelf: true });
    this.tabOneForm.controls.patientGDPR.disable({ onlySelf: true });
    this.tabOneForm.controls.idDoctor.disable({ onlySelf: true });
    this.allowUseTabs = true;
    this._usersService.getAddressById(this.tabOneForm.controls.idDoctor.value, "R").subscribe((resp: any[]) => {
      this.returns = resp;
    });

    this._usersService.getAddressById(this.tabOneForm.controls.idDoctor.value, "S").subscribe((resp: any[]) => {
      this.shippings = resp;
    });

    this._usersService.getAddressById(this.tabOneForm.controls.idDoctor.value, "I").subscribe((resp: any[]) => {
      this.invoices = resp;
    });
  }

  previousPage() {
    this.selectedIndex -= 1;
  }

  nextPage(templateRef = null) {
    if (!this.checkModal) {
      this.checkModal = templateRef;
    }
    this.selectedIndex += 1;
  }

  changeTab(tab: any, index, templateRef) {
    //it works only if logged-in user is a DOCTOR
    if (this.selectedTreatment != undefined) {
      if (this.currentUser.role == 'DO' && this.selectedTreatment.status == 'DRAFT' && this.currentUser.showStepsWarning) {
        this.checkModal = templateRef;
        if (!this.isStepsAreValidToMove() && !this.isMovingEvent) {
          this.stepNotValidModal = this.modalServide.open(this.checkModal, { closeOnNavigation: true })
        } else {
          this.selectedIndex = tab.selectedIndex;
          this.selectedIndexOld = tab.selectedIndex;
          this.isMovingEvent = false;
        }
      }
    } else {
      this.selectedIndex = tab.selectedIndex;
      this.selectedIndexOld = tab.selectedIndex;
      this.isMovingEvent = false;
    }
  }

  closeValidationNotificationModal() {
    this.stepNotValidModal.close();
    this.selectedIndex = this.selectedIndexOld;
    this.isMovingEvent = true;
  }

  isStepsAreValidToMove() {
    switch (this.selectedIndexOld) {
      case 0:
        return this.tabOneForm.valid;
      case 1:
        return this.tabTwoForm.valid;
      case 2:
        return this.tabThreeForm.valid;
      case 3:
        return this.tabFourForm.valid;
      case 4:
        return this.tabFiveForm.valid;
      case 5:
        return this.tabSixForm.valid;
    }
  }

  public acceptValidationNotificationModal() {
    this.stepNotValidModal.close();
    this.selectedIndexOld = this.selectedIndex;
  }

  public currentClassTabOne() {
    //return this.showHidePrimaryDangerColor(1);
    return {
      "primary-color": this.tabOneForm.status == "DISABLED"
        ? !this.tabOneForm.valid
        : this.tabOneForm.valid,
      "danger-color": this.tabOneForm.status == "DISABLED"
        ? this.tabOneForm.valid
        : !this.tabOneForm.valid
    }
  }

  public currentClassTabTwo() {
    if (this.currentUser.role != 'DO') {
      //NOT A DOCTOR
      if (this.currentUser && this.selectedTreatment) {
        return {
          "primary-color": this.selectedTreatment.step2Complete,
          "danger-color": !this.selectedTreatment.step2Complete
        }
      }
    }
    else {
      //DOCTOR
      return {
        "primary-color": this.tabTwoForm.status == "DISABLED"
          ? !this.tabTwoForm.valid
          : this.tabTwoForm.valid,
        "danger-color": this.tabTwoForm.status == "DISABLED"
          ? this.tabTwoForm.valid
          : !this.tabTwoForm.valid
      }
    }
  }

  public currentClassTabThree() {
    if (this.currentUser.role == 'US' && this.currentUser.userGroup == 'ST') {
      if (this.selectedTreatment && this.selectedTreatment.status == 'DRAFT') {
        return {
          "primary-color": this.tabThreeForm.status == "DISABLED"
            ? !this.tabThreeForm.valid
            : this.tabThreeForm.valid,
          "danger-color": this.tabThreeForm.status == "DISABLED"
            ? this.tabThreeForm.valid
            : !this.tabThreeForm.valid
        }
      }
      else if (this.currentUser && this.selectedTreatment) {
        return {
          "primary-color": this.selectedTreatment.step3Complete,
          "danger-color": !this.selectedTreatment.step3Complete
        }
      }
    }
    if (this.currentUser.role != 'DO') {
      //NOT A DOCTOR
      if (this.currentUser && this.selectedTreatment) {
        return {
          "primary-color": this.selectedTreatment.step3Complete,
          "danger-color": !this.selectedTreatment.step3Complete
        }
      }
    }
    else {
      //DOCTOR
      return {
        "primary-color": this.tabThreeForm.status == "DISABLED"
          ? !this.tabThreeForm.valid
          : this.tabThreeForm.valid,
        "danger-color": this.tabThreeForm.status == "DISABLED"
          ? this.tabThreeForm.valid
          : !this.tabThreeForm.valid
      }
    }
  }

  showHidePrimaryDangerColor(stepNumber: number) {
    let primaryC = false;

    if (this.selectedTreatment != undefined) {
      //console.log('this.selectedTreatment', this.selectedTreatment.status);
      //console.log('tabOneForm invalid: ', this.tabOneForm.invalid);      
      //primaryC = this.selectedTreatment.status == "DRAFT" ? formObject.valid : true;
      let res = false;
      switch (stepNumber) {
        case 1:
          res = this.step1Valid;
          break;
        case 2:
          res = this.step2Valid;
          break;
        case 3:
          res = this.step3Valid;
          break;
        case 4:
          res = this.step4Valid;
          break;
        case 5:
          res = this.step5Valid;
          break;
        case 6:
          res = this.step6Valid;
          break;
      }
      primaryC = res;
    }
    return {
      "primary-color": primaryC,
      "danger-color": !primaryC
    }
  }
  public currentClassTabFour() {
    //return this.showHidePrimaryDangerColor(4);
    if (this.currentUser.role != 'DO') {
      //NOT A DOCTOR
      if (this.currentUser && this.selectedTreatment) {
        return {
          "primary-color": this.selectedTreatment.step4Complete,
          "danger-color": !this.selectedTreatment.step4Complete
        }
      }
    }
    else {
      //DOCTOR
      return {
        "primary-color": this.tabFourForm.status == "DISABLED"
          ? !this.tabFourForm.valid
          : this.tabFourForm.valid,
        "danger-color": this.tabFourForm.status == "DISABLED"
          ? this.tabFourForm.valid
          : !this.tabFourForm.valid
      }
    }
  }

  public currentClassTabFive() {

    if (this._router.url == '/newpatient') {
      return {
        "primary-color": false,
        "danger-color": true
      }
    }

    if (this.currentUser.role != 'DO') {
      //NOT A DOCTOR
      if (this.currentUser && this.selectedTreatment) {
        return {
          "primary-color": this.selectedTreatment.step5Complete,
          "danger-color": !this.selectedTreatment.step5Complete
        }
      }
    }
    else {
      //DOCTOR
      return {
        "primary-color": this.tabFiveForm.status == "DISABLED"
          ? !this.tabFiveForm.valid
          : this.tabFiveForm.valid,
        "danger-color": this.tabFiveForm.status == "DISABLED"
          ? this.tabFiveForm.valid
          : !this.tabFiveForm.valid
      }
    }
  }

  public currentClassTabSix() {
    if (this.currentUser.role != 'DO') {
      //NOT A DOCTOR
      if (this.currentUser && this.selectedTreatment) {
        return {
          "primary-color": this.selectedTreatment.step6Complete,
          "danger-color": !this.selectedTreatment.step6Complete
        }
      }
    }
    else {
      //DOCTOR
      return {
        "primary-color": this.tabSixForm.status == "DISABLED"
          ? !this.tabSixForm.valid
          : this.tabSixForm.valid,
        "danger-color": this.tabSixForm.status == "DISABLED"
          ? this.tabSixForm.valid
          : !this.tabSixForm.valid
      }
    }
  }

  public dropFileHandle(side, entity: { formData: FormData, fileName: string }) {
    this.tabFourForm.controls[side].setValue(entity.fileName);

    if (!this.fileToBeUploaded.includes(x => x.fileName == entity.fileName)) {
      let propName = entity.fileName.split('.')[0];
      propName = `${propName.charAt(0).toLocaleLowerCase()}${propName.slice(1)}`;
      this.fileToBeUploaded.push({ fileName: entity.fileName, formData: entity.formData, fieldName: propName });
    } else {
      for (let file of this.fileToBeUploaded) {
        if (file.fileName == entity.fileName) {
          file.formData = entity.formData;
        }
      }
    }
    this.tabFourForm.touched;
  }

  dropScanFileHandle(side, entity: { formData: FormData, fileName: string }) {
    this.tabThreeForm.controls[side].setValue(entity.fileName);

    if (!this.fileScansToBeUploaded.includes(x => x.fileName == entity.fileName)) {
      this.fileScansToBeUploaded.push({ fileName: entity.fileName, formData: entity.formData, fieldName: side });
    } else {
      for (let file of this.fileScansToBeUploaded) {
        if (file.fileName == entity.fileName) {
          file.formData = entity.formData;
        }
      }
    }
    this.tabThreeForm.touched;
  }

  onArchesTypeChange(event) {
    switch (event) {
      case "D":
        this.tabThreeForm.controls.scanLower.setValidators(Validators.required);
        this.tabThreeForm.controls.scanUpper.setValidators(Validators.required);
        this.tabThreeForm.controls.scanLower.setValue(this.selectedTreatment.scanLower);
        this.tabThreeForm.controls.scanUpper.setValue(this.selectedTreatment.scanUpper);
        this.tabTwoForm.controls.return.setValue('-1');
        break;
      case "T":
        this.tabThreeForm.controls.scanLower.clearValidators();
        this.tabThreeForm.controls.scanUpper.clearValidators();
        this.tabThreeForm.controls.scanLower.reset();
        this.tabThreeForm.controls.scanUpper.reset();
        if (this.tabTwoForm.controls.return.value == '-1')
          this.tabTwoForm.controls.return.setValue('null');
        break;
    }
    this.tabThreeForm.controls.archesType.setValue(event);
  }

  assign(obj, prop, value) {
    if (typeof prop === 'string')
      prop = prop.split('.');

    if (prop.length > 1) {
      var e = prop.shift();
      this.assign(obj[e] =
        Object.prototype.toString.call(obj[e]) === '[object Object]'
          ? obj[e]
          : {},
        prop,
        value);
    } else
      obj[prop[0]] = value;
  }

  clearScanFileHandle(side, fileName) {
    //if (!this.fileScansToBeUploaded.find(x => x.fileName.split('.')[0] == fileName)) {

    if (!this.fileScansToBeUploaded.find(x => x.fileName == fileName)) {
      this.assign(this.selectedTreatment, side, null);
      //this.selectedTreatment.scanLower = null;

      this.tabThreeForm.controls[side].setValue(null);
      this.tabThreeForm.touched;

      //this.save(true);
      return;
    }

    //this.fileScansToBeUploaded = this.fileScansToBeUploaded.filter(x => x.fileName.split('.')[0] !== fileName);
    this.fileScansToBeUploaded = this.fileScansToBeUploaded.filter(x => x.fileName !== fileName);
    this.tabThreeForm.controls[side].setValue(null);
    this.tabThreeForm.touched;
    this.cd.detectChanges();
  }

  public clearFileHandle(side, fileName) {
    if (!this.fileToBeUploaded.find(x => x.fileName == fileName)) {

      let sideDecoded = '';
      switch (side) {
        case 'upperArch':
          sideDecoded = 'imageUA';
          break;
        case 'bottomArch':
          sideDecoded = 'imageBA';
          break;
        case 'leftOcclusal':
          sideDecoded = 'imageLO';
          break;
        case 'frontOcclusal':
          sideDecoded = 'imageFO';
          break;
        case 'rightOcclusal':
          sideDecoded = 'imageRO';
          break;
        case 'imageCollage':
          sideDecoded = 'imageCollage';
          break;
        case 'leftSide':
          sideDecoded = 'imageLS';
          break;
        case 'frontSide':
          sideDecoded = 'imageFS';
          break;
        case 'rightSide':
          sideDecoded = 'imageRS';
          break;
        case 'rsOrthopanoramics':
          sideDecoded = 'imageOrtho';
          break;
        case 'telecranio':
          sideDecoded = 'imageTelecranio';
          break;
        case 'other':
          sideDecoded = 'imageOther';
          break;
      }
      this.assign(this.selectedTreatment, sideDecoded, null);

      console.log(this.selectedTreatment);

      this.tabFourForm.controls[side].setValue(null);
      this.tabFourForm.touched;
      //this.save(true);
      return;
    }
    this.fileToBeUploaded = this.fileToBeUploaded.filter(x => x.fileName !== fileName);
    this.tabFourForm.controls[side].setValue(null);
    this.tabFourForm.touched;
  }

  downloadFileHandle(fileName) {
    this._contentService.getContentFile(`t-${this.treatmentId}`, fileName).subscribe((resp: any) => {
      let fileNameToSave = fileName;
      console.log('fileNameToSave', fileNameToSave);

      if (fileName == 'upper_arch_scan.stl')
        fileNameToSave = this.selectedTreatment.patientFullName.replace(/[^a-z0-9]/gi, '-') + '-Upper.stl';
      if (fileName == 'lower_arch_scan.stl')
        fileNameToSave = this.selectedTreatment.patientFullName.replace(/[^a-z0-9]/gi, '-') + '-Lower.stl';

      FileSaver(resp, fileNameToSave);
      if (fileName == 'upper_arch_scan.stl') {
        this.stlFileUpper.isDownloadingFile = false;
        this.cd.detectChanges();
      }
      if (fileName == 'lower_arch_scan.stl') {
        this.stlFileLower.isDownloadingFile = false;
        this.cd.detectChanges();
      }
    }, error => {
      this.snackBar.open(this._translocoService.translate("COMMON.errorDownloadFileMessage"), "X", {
        duration: 3000,
        panelClass: "warning",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
    });
  }

  async openFileHandle(fileName) {
    let resp: any = await this._contentService.getContentLink(`t-${this.treatmentId}`, fileName).toPromise();
    let image_window = window.open(resp.fullUrl, resp.fullUrl);
    image_window.document.write(`
     <html>
       <head>
       </head>
       <body>
         <img src="${resp.fullUrl}" width="50%">
       </body>
     </html>`);
  }

  public onRadioButtonChange(event) {
    switch (event) {
      case "patient":
        this.tabOneForm.controls.patientFirstName.setValidators(Validators.required);
        this.tabOneForm.controls.patientLastName.setValidators(Validators.required);
        this.tabOneForm.controls.patientGDPR.clearValidators();
        if (this.selectedTreatment) {
          this.tabOneForm.controls.patientGDPR.reset();
          this.tabOneForm.controls.patientFirstName.setValue(this.selectedTreatment.patientFirstName);
          this.tabOneForm.controls.patientLastName.setValue(this.selectedTreatment.patientLastName);
        } else {
          this.tabOneForm.controls.patientGDPR.setValue(null);
        }
        break;
      case "gdpr":
        this.tabOneForm.controls.patientFirstName.clearValidators();
        this.tabOneForm.controls.patientLastName.clearValidators();
        this.tabOneForm.controls.patientGDPR.setValidators(Validators.required);
        if (this.selectedTreatment) {
          this.tabOneForm.controls.patientGDPR.setValue(this.selectedTreatment.patientGDPR);
          this.tabOneForm.controls.patientFirstName.reset();
          this.tabOneForm.controls.patientLastName.reset();
        } else {
          this.tabOneForm.controls.patientFirstName.setValue(null);
          this.tabOneForm.controls.patientLastName.setValue(null);
        }
        break;
    }
    this.tabOneForm.controls.patientType.setValue(event);
  }

  onCheckBoxChange(checked) {
    if (checked) {
      this.upperTeeth = [];
      this.lowerTeeth = [];
    } else {

      this.prepareTeeth();
    }
  }

  // onChangeRelationShip(checked) {
  //   if (checked) {
  //     this.tabFiveForm.controls.anteriorTreatment.clearValidators();
  //     this.tabFiveForm.controls.anteriorTreatment.setErrors(null);

  //     this.tabFiveForm.controls.anteriorTreatment.setValue(this.tabFiveForm.controls.anteriorTreatment.value);
  //     return;
  //   }
  //   this.tabFiveForm.controls.anteriorTreatment.setValidators([customFalseValidator()]);
  //   this.tabFiveForm.controls.anteriorTreatment.setValue(this.tabFiveForm.controls.anteriorTreatment.value);
  // }

  onChangeAestheticTreatment(checked: boolean) {
    if (checked) {
      this.tabFiveForm.controls.changeClass.clearValidators();
      this.tabFiveForm.controls.changeClass.setValue(this.tabFiveForm.controls.changeClass.value);
      return;
    } else if (!checked && !this.tabFiveForm.controls.changeClass.value) {
      //this.tabFiveForm.controls.anteriorTreatment.setErrors({ 'required': true })
    }
    //  this.tabFiveForm.controls.changeClass.setValidators([customFalseValidator()]);
    // this.tabFiveForm.controls.changeClass.setValue(null);
  }

  onSpecialInstructioCheckBox(checked) {
    if (checked) {
      this.tabSixForm.controls.specialInstruction.clearValidators();
      this.tabSixForm.controls.specialInstruction.disable({ onlySelf: true });
    } else {
      this.tabSixForm.controls.specialInstruction.setValidators([Validators.required]);
      this.tabSixForm.controls.specialInstruction.enable({ onlySelf: true });
    }
    this.tabSixForm.controls.specialInstruction.reset();
  }

  relationshipTrigger(event) {
    if (event == "changeClass") {
      this.tabFiveForm.controls.typeOfElastic.setValidators([Validators.required]);
    } else {
      this.tabFiveForm.controls.typeOfElastic.clearValidators();
    }
    this.tabFiveForm.controls.typeOfElastic.setValue(this.tabFiveForm.controls.typeOfElastic.value);
  }

  onCrowdingUpperSelect(event) {
    // if (event) {
    //   this.tabFiveForm.controls.crowdingUpperArchWorkflow.setValidators([Validators.required]);
    // } else {
    //   this.tabFiveForm.controls.crowdingUpperArchWorkflow.setErrors(null);
    //   this.tabFiveForm.controls.crowdingUpperArchWorkflow.clearValidators();
    //   this.tabFiveForm.controls.crowdingUpperArchWorkflow.setValue([]);
    // }
    // this.tabFiveForm.controls.crowdingUpperArchWorkflow.updateValueAndValidity();
  }

  compareFn(c1: any, c2: any) {
    return c1 == c2;
  }

  onCrowdingLowerSelect(event) {
    // if (event) {
    //   this.tabFiveForm.controls.crowdingLowerArchWorkflow.setValidators([Validators.required]);
    // } else {
    //   this.tabFiveForm.controls.crowdingLowerArchWorkflow.setErrors(null)
    //   this.tabFiveForm.controls.crowdingLowerArchWorkflow.clearValidators();
    //   this.tabFiveForm.controls.crowdingLowerArchWorkflow.setValue([]);
    // }
    // this.tabFiveForm.controls.crowdingLowerArchWorkflow.updateValueAndValidity();
  }

  isAllStepsValid() {
    return this.tabOneForm.valid && this.tabThreeForm.valid && this.tabFourForm.valid && this.tabFiveForm.valid && this.tabSixForm.valid;
  }

  isSendButtonEnabled() {
    return this.selectedTreatment && (this.selectedTreatment.status == 'DRAFT' || this.selectedTreatment.status == 'INCOMPLETE') && this.isAllStepsValid();
  }

  async createTreatment(tempalteRef) {
    if (!this.tabOneForm.valid) {
      this.tabOneForm.markAllAsTouched();
      return;
    }

    this.isLoading = true;

    let request = {
      ...this.tabOneForm.value,
    }

    if (!request.registrationDate) {
      request.registrationDate = this.tabOneForm.controls.registrationDate.value;
    }

    if (!request.idDoctor && this.currentUser.role == "DO") {
      request.idDoctor = this.currentUser.idAdmin;
    }

    if (!request.patientFirstName || !request.patientLastName) {
      request.patientFirstName = "";
      request.patientLastName = "";
    }

    if (this.currentUser.role == 'US' && this.currentUser.userGroup == 'ST') {
      //STRAT USER => upload also STL files (if found)
      request.scanLower = this.tabThreeForm.controls.scanLower.value;
      request.scanUpper = this.tabThreeForm.controls.scanUpper.value
    }

    this._patientService.createTreatment(request).subscribe((resp: any) => {
      if (resp && this.currentUser.role == "DO") {
        this.isLoading = false;
        this._router.navigate(["/treatments", resp.idTreatment], { queryParams: { step: 2 } });
        return;
      }

      if (this.currentUser.role == 'US' && this.currentUser.userGroup == 'ST') {
        //STRAT USER => upload also STL files (if found)

        //upload files if found
        let isError = false;
        if (this.fileScansToBeUploaded.length > 0) {
          for (let file of this.fileScansToBeUploaded) {
            try {
              this._contentService.uploadContentBlob(file.formData, `t-${resp.idTreatment}`, true).toPromise();
            } catch {
              isError = true;
              continue;
            }
          }

          if (isError) {
            this.snackBar.open(this._translocoService.translate("COMMON.ErrorMessage"), "X", {
              duration: 3000,
              panelClass: "warning",
              horizontalPosition: "right",
              verticalPosition: "top",
            } as MatSnackBarConfig);
            return;
          }

        }
      }

      this.isLoading = false;

      this.snackBar.open(this._translocoService.translate("patient.createdSuccessful"), "X", {
        duration: 3000,
        panelClass: "success",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
      this._router.navigate(["/treatments"]);

    }, (error: HttpErrorResponse) => {
      if (error.status == 409) {
        this.isLoading = false;
        this.notFoundModal = this.modalServide.open(tempalteRef, { closeOnNavigation: true });
        return;
      }

      this.snackBar.open(this._translocoService.translate("COMMON.ErrorMessage"), "X", {
        duration: 3000,
        panelClass: "warning",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
    })

  }

  closeNotFoundModal() {
    this.notFoundModal.close();
  }

  closeAdditionButtonModal() {
    this.additionalModal.close();
  }

  closeIncompleteModal() {
    this.incompleteModal.close();
  }

  onChangePatientDataBoxChange(val) {
    if (val) {
      this.tabOneForm.controls.patientFirstName.enable({ onlySelf: true });
      this.tabOneForm.controls.patientType.enable({ onlySelf: true });
      this.tabOneForm.controls.patientLastName.enable({ onlySelf: true });
      this.tabOneForm.controls.patientGDPR.enable({ onlySelf: true });
      return;
    }
    this.tabOneForm.controls.patientFirstName.setValue(this.tabOneForm.controls.patientFirstName.value);
    this.tabOneForm.controls.patientLastName.setValue(this.tabOneForm.controls.patientLastName.value);
    this.tabOneForm.controls.patientGDPR.setValue(this.selectedTreatment.patientGDPR);
    this.tabOneForm.controls.patientFirstName.disable({ onlySelf: true });
    this.tabOneForm.controls.patientType.disable({ onlySelf: true });
    this.tabOneForm.controls.patientLastName.disable({ onlySelf: true });
    this.tabOneForm.controls.patientGDPR.disable({ onlySelf: true });
  }

  updatePatientData() {
    if (!this.tabOneForm.valid) {
      this.tabOneForm.controls.patientFirstName.markAsTouched({ onlySelf: true });
      this.tabOneForm.controls.patientLastName.markAsTouched({ onlySelf: true });
      this.tabOneForm.controls.patientGDPR.markAsTouched({ onlySelf: true });
      return;
    }
    var request = {
      patientFirstName: this.tabOneForm.controls.patientFirstName.value,
      patientLastName: this.tabOneForm.controls.patientLastName.value,
      patientGDPR: this.tabOneForm.controls.patientGDPR.value,
      idTreatment: +this.treatmentId
    }

    this._patientService.updatePatientData(request).subscribe(resp => {
      if (resp) {
        this.prepareTreatmentCall();
        this.tabOneForm.controls.changeCheckBox.setValue(false);
        this.snackBar.open(this._translocoService.translate("patient.updatedSuccessful"), "X", {
          duration: 3000,
          panelClass: "success",
          horizontalPosition: "right",
          verticalPosition: "top",
        } as MatSnackBarConfig);
      }
    });
  }

  getCurrentOptTittle(opt) {
    switch (this._translocoService.getActiveLang()) {
      case "en":
        return opt.optionTitle_EN;
      case "it":
        return opt.optionTitle_IT;
    }
  }

  save(reload?: boolean, ref?) {
    this.isLoading = reload;
    this.request = {
      "idTreatment": +this.treatmentId,
      "archesHandled": this.tabThreeForm.controls.archesTypeHandle.value == 'null' ? 'N' : this.tabThreeForm.controls.archesTypeHandle.value,
      "archesType": this.tabThreeForm.controls.archesType.value == null ? 'N' : this.tabThreeForm.controls.archesType.value,
      "byteRamp": this.tabFiveForm.controls.biteRamp.value == 'null' ? false : this.tabFiveForm.controls.biteRamp.value,
      "crowdingLowerArch": this.tabFiveForm.controls.crowdingLowerArch.value == 'null' ? false : this.tabFiveForm.controls.crowdingLowerArch.value,
      //"crowdingLowerArchType": this.tabFiveForm.controls.crowdingLowerArchWorkflow.value == 'null' ? "N" : this.tabFiveForm.controls.crowdingLowerArchWorkflow.value,
      "crowdingLowerArchType": (this.tabFiveForm.controls.crowdingLowerArchWorkflow.value == false || this.tabFiveForm.controls.crowdingLowerArchWorkflow.value == null) ? [] : this.tabFiveForm.controls.crowdingLowerArchWorkflow.value,
      "crowdingUpperArch": this.tabFiveForm.controls.crowdingUpperArch.value == 'null' ? false : this.tabFiveForm.controls.crowdingUpperArch.value,
      //"crowdingUpperArchType": this.tabFiveForm.controls.crowdingUpperArchWorkflow.value == 'null' ? "N" : this.tabFiveForm.controls.crowdingUpperArchWorkflow.value,
      "crowdingUpperArchType": (this.tabFiveForm.controls.crowdingUpperArchWorkflow.value == false || this.tabFiveForm.controls.crowdingUpperArchWorkflow.value == null) ? [] : this.tabFiveForm.controls.crowdingUpperArchWorkflow.value,
      "elasticType": this.tabFiveForm.controls.typeOfElastic.value,
      "idAddressInvoice": this.tabTwoForm.controls.invoice.value == "null" ? 0 : +this.tabTwoForm.controls.invoice.value,
      "idAddressReturn": this.tabTwoForm.controls.return.value == "null" ? 0 : +this.tabTwoForm.controls.return.value,
      "idAddressShipping": this.tabTwoForm.controls.ship.value == "null" ? 0 : +this.tabTwoForm.controls.ship.value,
      "imageLS": this.tabFourForm.controls.leftSide.value,
      "imageFS": this.tabFourForm.controls.frontSide.value,
      "imageRS": this.tabFourForm.controls.rightSide.value,
      "imageBA": this.tabFourForm.controls.bottomArch.value,
      "imageFO": this.tabFourForm.controls.frontOcclusal.value,
      "imageLO": this.tabFourForm.controls.leftOcclusal.value,
      "imageOrtho": this.tabFourForm.controls.rsOrthopanoramics.value,
      "imageTelecranio": this.tabFourForm.controls.telecranio.value,
      "imageOther": this.tabFourForm.controls.other.value,
      "imageRO": this.tabFourForm.controls.rightOcclusal.value,
      "imageUA": this.tabFourForm.controls.upperArch.value,
      "imageType": this.tabFourForm.controls.imageType.value,
      "imageCollage": this.tabFourForm.controls.imageCollage.value,
      "lowerArchDiastema": this.tabFiveForm.controls.lowerArchDiastema.value ? this.tabFiveForm.controls.lowerArchDiastema.value : "N",
      "noSpecialInstruction": this.tabSixForm.controls.noSpecialInstruction.value,
      "noTeethToExclude": this.tabFiveForm.controls.noExcludeTeeth.value,
      "changeClass": this.tabFiveForm.controls.changeClass.value,
      //"anteriorTreatment": this.tabFiveForm.controls.anteriorTreatment.value,
      // "overJet": this.tabFiveForm.controls.overJet.value,
      // "overByte": this.tabFiveForm.controls.overByte.value,
      "overJetType": this.tabFiveForm.controls.overJetType.value == null ? 'N' : this.tabFiveForm.controls.overJetType.value,
      "overByteType": this.tabFiveForm.controls.overByteType.value == null ? 'N' : this.tabFiveForm.controls.overByteType.value,
      "crossBiteType": this.tabFiveForm.controls.crossBiteType.value == null ? 'N' : this.tabFiveForm.controls.crossBiteType.value,
      "patientType": this.tabThreeForm.controls.patientAgeType.value == 'null' ? 'N' : this.tabThreeForm.controls.patientAgeType.value,
      "archesSentViaCommunicator": this.tabThreeForm.controls.archesSentViaCommunicator.value,
      "specialInstruction": this.tabSixForm.controls.specialInstruction.value,
      "teethToExclude": [
        ...this.upperTeeth.filter(x => x.isSelected).map(x => x.id),
        ...this.lowerTeeth.filter(x => x.isSelected).map(x => x.id)
      ],
      //"treatmentAction": this.tabFiveForm.controls.objectiveRadioButton.value ? this.tabFiveForm.controls.objectiveRadioButton.value : "N",
      "treatmentType": this.tabThreeForm.controls.patientType.value == 'null' ? 'NO' : this.tabThreeForm.controls.patientType.value,
      "clinicProject": this.tabThreeForm.controls.clinicProject.value,
      "upperArchDiastema": this.tabFiveForm.controls.upperArchDiastema.value ? this.tabFiveForm.controls.upperArchDiastema.value : "N",
      "scanLower": this.tabThreeForm.controls.scanLower.value,
      "scanUpper": this.tabThreeForm.controls.scanUpper.value,
      "videoRequested": this.tabSixForm.controls.videoRequested.value,
      "phase": this.selectedTreatment.phase,
      "phaseType": this.selectedTreatment.phaseType
    };

    // for (let file of this.fileToBeUploaded) {
    //   try {
    //     this.reload = true;
    //     //this.isLoading = true;
    //     //await this._contentService.uploadContentBlob(file.formData, `t-${this.treatmentId}`, true).toPromise();

    //     this.progressUpload = 0;
    //     this._contentService.uploadContentBlob(file.formData, `t-${this.treatmentId}`, true).subscribe((event: HttpEvent<any>) => {
    //       switch (event.type) {
    //         case HttpEventType.Sent:
    //           console.log('Request has been made!');
    //           break;
    //         case HttpEventType.ResponseHeader:
    //           console.log('Response header has been received!');
    //           break;
    //         case HttpEventType.UploadProgress:
    //           this.progressUpload = Math.round(event.loaded / event.total * 100);
    //           console.log(`Uploaded! ${this.progressUpload}%`);
    //           break;
    //         case HttpEventType.Response:
    //           console.log('File successfully created!', event.body);
    //       }
    //       this.cd.detectChanges();
    //     });

    //     this.request = this.updateRequest(this.request, this.tabFourForm.controls[file.fieldName].value, file.fieldName);
    //   } catch {
    //     this.request = this.updateRequest(this.request, "", file.fieldName);
    //     this.isError = true;
    //     continue;
    //   }
    // }

    this.fileToBeUploadedMerged = [...this.fileToBeUploaded, ...this.fileScansToBeUploaded];

    if (this.fileToBeUploadedMerged.length != 0 && ref != null) {
      this.fileLoadProgressModal = this.modalServide.open(ref, { closeOnNavigation: true, disableClose: true, panelClass: "lg-modal" });
      this.uploadFileScanc(this.fileToBeUploadedMerged[0]);
    }

    if (this.fileToBeUploadedMerged.length == 0) {
      this.updateTreatmentAfterFileUpload(this.request, this.isError, this.reload)
    }
  }

  round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  sendChatMessageAndSave(reload?: boolean, ref?) {
    var message = this._translocoService.translate("patient.GENERAL_INFO.chatMessageForOptionalImages");
    this.chatService.sendMessage(+this.treatmentId, message, 'T').subscribe(resp => {
      //send message to update the chat
      this._messagingService.sendMessage('refreshTreatmentChatMessages');
      this.save(reload, ref);
    });
  }

  uploadFileScanc(file: any) {
    this.progressUploadSaving = false;
    this.progressUpload = 0;
    ++this.currentFileIterator;
    this.reload = true;
    this.isUploading = true;

    let percentDone: number;
    let currTime: any;
    let prevTime: any;
    let bytesReceied: number = 0;
    let oldbytes: number = 0;

    //setting start time
    this.uploadSub = this._contentService.uploadContentBlob(file.formData, `t-${this.treatmentId}`, true).subscribe({
      next: (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:

            //upload speed 
            percentDone = Math.round((100 * event.loaded) / event.total);
            currTime = new Date().getTime();
            //tracking how much data is received
            bytesReceied = event.loaded / 1000000;
            //calculating download speed per percent data received
            this.speedUpload = (bytesReceied - oldbytes) / ((currTime - prevTime) / 1000);
            if (this.speedUpload < 1) {
              this.unitUpload = "Kbps";
              this.speedUpload *= 1000;
            } else this.unitUpload = "Mbps";
            //updating previous values
            prevTime = currTime;
            oldbytes = bytesReceied;
            this.speedUpload = Math.round(this.speedUpload);
            console.log('speed: ' + this.speedUpload + ' ' + this.unitUpload);
            // if (percentDone > 1) {
            //   endTime = new Date().getTime();
            //   let duration = (endTime - startTime) / 1000;
            //   let mbps = event.total / duration / 1000000;
            //   if (mbps < 1) {
            //     speed = event.total / duration / 1000;
            //     unit = "Kbps";
            //   } else {
            //     speed = mbps;
            //     unit = "Mbps";
            //   }
            //   console.log('speed: ' + speed + ' ' + unit);
            // }

            //this.progressUpload = Math.round(event.loaded / event.total * 100);
            this.progressUpload = this.round(event.loaded / event.total * 100, 1);
            //console.log(`Uploaded! ${this.progressUpload}%`);
            this.progressUploadSaving = this.progressUpload == 100;
            break;
          case HttpEventType.Response:
            console.log('File successfully created!', event.body);
            this.progressUploadSaving = false;
            break;
        }
      }, error: () => {
        this.request[file.fieldName] = "";
        this.isError = true;
        this.isUploading = false;
      }, complete: () => {
        this.isUploading = false;

        if (file.fieldName == 'scanLower' || file.fieldName == 'scanUpper') {
          //step 3
          this.request[file.fieldName] = this.tabThreeForm.controls[file.fieldName].value;
        } else {
          //step 4
          this.request[file.fieldName] = this.tabFourForm.controls[file.fieldName].value;
        }

        if (this.fileToBeUploadedMerged.indexOf(file) != this.fileToBeUploadedMerged.length - 1) {
          this.uploadFileScanc(this.fileToBeUploadedMerged[this.fileToBeUploadedMerged.indexOf(file) + 1])
          return;
        }

        this.updateTreatmentAfterFileUpload(this.request, this.isError, this.reload);
      }
    });
  }

  awaitBeforeUploaded() {
    if (this.isUploading) {
      this.awaitBeforeUploaded();
    }
  }

  updateTreatmentAfterFileUpload(request, isError, reload) {
    this.fileToBeUploaded = [];
    this.fileScansToBeUploaded = [];
    this.fileToBeUploadedMerged = [];
    this.currentFileIterator = 0;

    this._patientService.saveTreatment(request).subscribe((resp: any) => {
      if (isError) {
        this.snackBar.open(this._translocoService.translate("COMMON.ErrorMessage"), "X", {
          duration: 3000,
          panelClass: "warning",
          horizontalPosition: "right",
          verticalPosition: "top",
        } as MatSnackBarConfig);
      } else {
        this.snackBar.open(this._translocoService.translate("patient.saveTreatmentSuccessful"), "X", {
          duration: 3000,
          panelClass: "success",
          horizontalPosition: "right",
          verticalPosition: "top",
        } as MatSnackBarConfig);
      }

      this.selectedTreatment.steps = resp.steps;
      this.selectedTreatment.step1Complete = resp.step1Complete;
      this.selectedTreatment.step2Complete = resp.step2Complete;
      this.selectedTreatment.step3Complete = resp.step3Complete;
      this.selectedTreatment.step4Complete = resp.step4Complete;
      this.selectedTreatment.step5Complete = resp.step5Complete;
      this.selectedTreatment.step6Complete = resp.step6Complete;

      if (this.SaveAndSend) {
        this.SaveAndSend = false;
        //update status to EVALUATION
        this._patientService.changeStatus(null, this.treatmentId, "evaluation").subscribe(resp => {
          // this.snackBar.open(this._translocoService.translate("patient.statusChangedSuccessfull"), "X", {
          //   duration: 3000,
          //   panelClass: "success",
          //   horizontalPosition: "right",
          //   verticalPosition: "top",
          // } as MatSnackBarConfig);
          this.closeAdditionButtonModal();
          //update status => in this wasy prevent the modal of exit without SEND
          this.selectedTreatment.status = 'EVALUATION';
          //redirect to dashboard
          this._router.navigate(["/dashboard"]);
        }, error => {
          this.closeAdditionButtonModal();
          this.snackBar.open(this._translocoService.translate("COMMON.ErrorMessage"), "X", {
            duration: 3000,
            panelClass: "warning",
            horizontalPosition: "right",
            verticalPosition: "top",
          } as MatSnackBarConfig);
        });
      }

      if (this.currentUser.role == 'DO' && (this.selectedTreatment.status == 'INPROJECT' || this.selectedTreatment.status == 'INREVIEW')) {
        //this.selectedTreatment.imageTelecranio = 'telecranio.png';
        this.EmptyFile = null;
      }

      // //IF IT'S A DOCTOR, ON STEP 4 => SEND CHAT MESSAGE
      // if ((this.selectedTreatment.status == 'INPROJECT' || this.selectedTreatment.status == 'INREVIEW') && this.currentUser.role == 'DO' && this.selectedIndex == 3) {
      //   var message = this._translocoService.translate("patient.GENERAL_INFO.chatMessageForOptionalImages");
      //   let chatMessage = this.chatService.sendMessage(+this.treatmentId, message, false).subscribe();
      // }

      this.isLoading = false;
      if (reload) {
        this._router.navigate(["/treatments", this.treatmentId]);
        // setTimeout(() => {
        //   this.prepareTreatmentCall();
        // }, 0);
      }
    }, error => {
      this.snackBar.open(this._translocoService.translate("COMMON.ErrorMessage"), "X", {
        duration: 3000,
        panelClass: "warning",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
    });

    if (this.fileLoadProgressModal.close != null) {
      this.fileLoadProgressModal.close();
    }

    setTimeout(() => {
      this.cd.detectChanges();
    }, 100);
  }

  updateRequest(request, fileName, fieldName) {
    switch (fieldName) {
      case "leftSide":
        request.imageLS = fileName;
        break;
      case "frontSide":
        request.imageFS = fileName;
        break;
      case "rightSide":
        request.imageRS = fileName;
        break;
      case "bottomArch":
        request.imageBA = fileName;
        break;
      case "frontOcclusal":
        request.imageFO = fileName;
        break;
      case "leftOcclusal":
        request.imageLO = fileName;
        break;
      case "rsOrthopanoramics":
        request.imageOrtho = fileName;
        break;
      case "telecranio":
        request.imageTelecranio = fileName;
        break;
      case "other":
        request.imageOther = fileName;
        break;
      case "rightOcclusal":
        request.imageRO = fileName;
        break;
      case "upperArch":
        request.imageUA = fileName;
        break;
    }

    return request;
  }

  openRecoverFromIncompleteModal(type: string, ref, refInfo = null) {
    this.selectedModalType = type;
    switch (type) {
      case "recoverTreatment":
        this.modalMessage = "patient.ADDITIONAL.recoverMessage"
        this.confirmModal = this.modalServide.open(ref, { closeOnNavigation: true });
        break;
      case "draft":
        this.draftReason = new FormControl(null);
        this.modalMessage = "patient.InReviewReasonDoctor"
        this.confirmModal = this.modalServide.open(ref, { panelClass: "lg-modal", closeOnNavigation: true });
        break;
      case 'deleteVideo':
        this.modalMessage = "patient.ADDITIONAL.deleteVideoMessage"
        this.confirmModal = this.modalServide.open(ref, { closeOnNavigation: true });
        break;
      case 'generateTreatmentCode':
        if (this.tabThreeForm.controls.archesType.value == null) {
          this.modalMessage = "patient.GENERAL_INFO.generateTreatmentCodeWarningMessage"
          this.infoModal = this.modalServide.open(refInfo, { closeOnNavigation: true });
        } else {
          this.modalMessage = "patient.GENERAL_INFO.generateTreatmentCodeMessage"
          this.confirmModal = this.modalServide.open(ref, { closeOnNavigation: true });
        }
        break;
      default:
        return;
    }
  }

  showUpdateStatusModal(ref) {
    this.updateStatusModal = this.modalServide.open(ref, { closeOnNavigation: true });
  }

  closeUpdateStatusModal() {
    this.updateStatusModal.close();
  }

  updateTreatmentStatus() {
    //update treatment status
    this.isUpdatieStillLoading = true;
    this._patientService.changeStatus("", this.treatmentId, this.updateStatusNewValue).subscribe(resp => {
      this.snackBar.open(this._translocoService.translate("patient.statusChangedSuccessfull"), "X", {
        duration: 3000,
        panelClass: "success",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
      this.isUpdatieStillLoading = false;
      this.closeUpdateStatusModal();
      setTimeout(() => {
        this.prepareTreatmentCall();
      }, 0);
    })
  }

  closeRecoverFromIncompleteModal() {
    this.confirmModal.close();
  }

  closeImagesPreviewModal() {
    this.imagePreview.close();
  }

  closeDoctorAdditionalInfoModal() {
    this.DoctorAdditionalInfo.close();
  }

  closePatientAdditionalInfoModal() {
    this.PatientAdditionalInfo.close();
  }

  savePatientAdditionalInfoModal(newValue: string) {
    this.isLoading = true;
    this.closePatientAdditionalInfoModal();

    this.treatmentsService.updatePatientAdditionalNotes(this.selectedTreatment.idTreatment, this.selectedTreatment.treatmentCode, newValue).subscribe(resp => {

      this.isLoading = false;

      this.snackBar.open(this._translocoService.translate('patient.PATIENT.additionalInfoConfirm'), "X", {
        duration: 3000,
        panelClass: "success",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);

      this.selectedTreatment.patientAdditionalNotes = newValue;
      this.patientInfoIconColor = newValue == '' ? 'black' : 'red';
      this.cd.detectChanges();

    }, error => {
      this.snackBar.open(this._translocoService.translate("COMMON.ErrorMessage"), "X", {
        duration: 3000,
        panelClass: "warning",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
      this.isLoading = false;
      this.cd.detectChanges();
    });
  }

  getClinicPreferencesFlag() {
    let res = true;
    this._usersService.getUserById(this.selectedTreatment.idDoctor).subscribe((doctor: User) => {
      this.clinicPreferencesUpdade = !doctor.clinicPreferencesUpdated;
      this.cd.detectChanges();
    });
  }

  setClinicPreferenceAsRead() {
    this.isLoading = true;
    this.closeDoctorAdditionalInfoModal();

    this.treatmentsService.updateClinicPreferenceFlag(+this.selectedTreatment.idDoctor).subscribe(resp => {

      this.isLoading = false;

      this.snackBar.open(this._translocoService.translate('patient.PATIENT.clinicPreferencesUpdated'), "X", {
        duration: 3000,
        panelClass: "success",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);

      //refresh current treatment
      setTimeout(() => {
        this.prepareTreatmentCall();
      }, 0);

    }, error => {
      this.snackBar.open(this._translocoService.translate("COMMON.ErrorMessage"), "X", {
        duration: 3000,
        panelClass: "warning",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
      this.isLoading = false;
      this.cd.detectChanges();
    });
  }

  closeInfoModal() {
    this.infoModal.close();
  }

  confirmRecoverFromIncompleteModal() {

    this.isLoading = true;
    switch (this.selectedModalType) {
      case "recoverTreatment":
        this.treatmentsService.changeStatus(null, null, "EVALUATION",
          {
            idTreatment: + this.treatmentId
          }).subscribe(resp => {

            this.isLoading = false;
            this.closeRecoverFromIncompleteModal();

            this.snackBar.open(this._translocoService.translate('patient.ADDITIONAL.recoverMessageConfirm'), "X", {
              duration: 3000,
              panelClass: "success",
              horizontalPosition: "right",
              verticalPosition: "top",
            } as MatSnackBarConfig);

            //refresh current treatment
            setTimeout(() => {
              this.prepareTreatmentCall();
            }, 0);

          });
        break;
      case "draft":
        this.treatmentsService.changeStatus(null, null, "draft",
          {
            idTreatment: + this.treatmentId,
            reason: this.draftReason.value == null ? '' : this.draftReason.value
          }).subscribe(resp => {

            this.isLoading = false;
            this.closeRecoverFromIncompleteModal();

            this.snackBar.open(this._translocoService.translate('patient.ADDITIONAL.sendToDoctorMessageConfirm'), "X", {
              duration: 3000,
              panelClass: "success",
              horizontalPosition: "right",
              verticalPosition: "top",
            } as MatSnackBarConfig);

            //refresh current treatment
            setTimeout(() => {
              this.prepareTreatmentCall();
            }, 0);

          }, error => {
            //error while saving treatment
            this.isLoading = false;
            this.snackBar.open(this._translocoService.translate("COMMON.ErrorMessage"), "X", {
              duration: 3000,
              panelClass: "warning",
              horizontalPosition: "right",
              verticalPosition: "top",
            } as MatSnackBarConfig);
          });

        break;

      case 'deleteVideo':
        this.closeRecoverFromIncompleteModal();

        this.treatmentsService.deleteWebViewer(+this.treatmentId).subscribe(resp => {

          this.isLoading = false;

          this.snackBar.open(this._translocoService.translate('patient.ADDITIONAL.deleteVideoMessageConfirm'), "X", {
            duration: 3000,
            panelClass: "success",
            horizontalPosition: "right",
            verticalPosition: "top",
          } as MatSnackBarConfig);

          //refresh current treatment
          setTimeout(() => {
            this.prepareTreatmentCall();
          }, 0);

        }, error => {
          this.snackBar.open(this._translocoService.translate("COMMON.ErrorMessage"), "X", {
            duration: 3000,
            panelClass: "warning",
            horizontalPosition: "right",
            verticalPosition: "top",
          } as MatSnackBarConfig);
          this.isLoading = false;
          this.cd.detectChanges();
        });
        break;
      case 'generateTreatmentCode':
        this.closeRecoverFromIncompleteModal();

        var archesType = this.tabThreeForm.controls.archesType.value;
        if (archesType == 'T')
          archesType = 'A';
        this.treatmentsService.generateTreatmentCode(+this.treatmentId, archesType).subscribe((resp: any) => {

          this.isLoading = false;
          this.selectedTreatment.treatmentCode = resp.treatmentCode;
          this.cd.detectChanges();

          this.snackBar.open(this._translocoService.translate('patient.GENERAL_INFO.generateTreatmentCodeConfirmMessage'), "X", {
            duration: 3000,
            panelClass: "success",
            horizontalPosition: "right",
            verticalPosition: "top",
          } as MatSnackBarConfig);

        }, error => {
          this.snackBar.open(this._translocoService.translate("COMMON.ErrorMessage"), "X", {
            duration: 3000,
            panelClass: "warning",
            horizontalPosition: "right",
            verticalPosition: "top",
          } as MatSnackBarConfig);
          this.isLoading = false;
          this.cd.detectChanges();
        });
        break;
    }
  }

  openTraditionalPDF() {
    //open pdf
    const link = document.createElement('a');
    const lang = this._translocoService.getActiveLang().toUpperCase();
    link.target = '_blank';
    link.href = this.storageBaseUrl + '/nuvola-support/DAI-' + lang + '.pdf';
    link.setAttribute('visibility', 'hidden');
    link.click();
  }

  openAdditionalButtonsModal(type: string, ref) {
    this.selectedModalType = type;
    this.reasonFormControl = null;
    this.otherReasonFormControl = null;
    switch (type) {
      case "send":
        this.additionalModalText = this._translocoService.translate("patient.sendModalText");
        this.additionalModal = this.modalServide.open(ref, { closeOnNavigation: true });
        break;
      case "reject":
        this.additionalModalText = this._translocoService.translate("patient.rejectModalText");
        this.reasonFormControl = new FormControl(null, Validators.required);
        this.additionalModal = this.modalServide.open(ref, { panelClass: "lg-modal", closeOnNavigation: true });
        break;
      case "evaluate":
        if (this.selectedTreatment.status == 'EVALUATION' && this.selectedTreatment.treatmentCode == '----------') {
          //block evaluation if treatment code is not generated yet
          this.modalMessage = "patient.GENERAL_INFO.generateTreatmentCodePreventMessage"
          this.infoModal = this.modalServide.open(ref, { closeOnNavigation: true });
        } else {
          this.additionalModalText = this._translocoService.translate("patient.evaluateModalText");
          this.additionalModal = this.modalServide.open(ref, { closeOnNavigation: true });
        }
        break;
      case "incomplete":
        this.additionalModalText = this._translocoService.translate("patient.incompleteModalText");
        this.reasonFormControl = new FormControl(null);
        this.otherReasonFormControl = new FormControl(null);
        this.incompleteModal = this.modalServide.open(ref, { panelClass: "lg-modal", closeOnNavigation: true });
        this._usersService.getOptions("IR").subscribe((resp: any[]) => {
          this.otherReasonList = resp.sort(x => x.optionTitle_EN && x.optionTitle_IT);
        });
        break;
      case "deleteTreatment":
        this.additionalModalText = this._translocoService.translate("patient.deleteModalText");
        this.additionalModal = this.modalServide.open(ref, { closeOnNavigation: true });
        break;
      case "archiveTreatment":
        this.additionalModalText = this._translocoService.translate("patient.archiveModalText");
        this.additionalModal = this.modalServide.open(ref, { closeOnNavigation: true });
        break;
      default:
        return;
    }
  }

  async send() {

    if (this.selectedModalType == "incomplete") {
      if (this.reasonFormControl && (this.reasonFormControl.value && this.reasonFormControl.value.length == 0)) {
        if (this.otherReasonFormControl && !this.otherReasonFormControl.value) {
          this.snackBar.open(this._translocoService.translate("COMMON.ValidationErrors"), "X", {
            direction: "rtl",
            duration: 3000,
            panelClass: "warning",
            horizontalPosition: "right",
            verticalPosition: "top",
          } as MatSnackBarConfig);
          return;
        }
      }
    }
    else {
      if (this.reasonFormControl && !this.reasonFormControl.valid) {
        this.reasonFormControl.markAsTouched({ onlySelf: true });
        return;
      }
    }

    this.isLoading = true;
    switch (this.selectedModalType) {
      case "send":
        //save as first then send
        this.SaveAndSend = true;
        this.save(false, this.fileLoadProgressModal);
        // console.log('dopo di save');
        // this._patientService.changeStatus(null, this.treatmentId, "evaluation").subscribe(resp => {
        //   this.snackBar.open(this._translocoService.translate("patient.statusChangedSuccessfull"), "X", {
        //     duration: 3000,
        //     panelClass: "success",
        //     horizontalPosition: "right",
        //     verticalPosition: "top",
        //   } as MatSnackBarConfig);
        //   this.closeAdditionButtonModal();
        //   setTimeout(() => {
        //     this.prepareTreatmentCall();
        //   }, 0);
        //   this.isLoading = false;
        // })
        break;
      case "reject":
        this._patientService.changeStatus(this.reasonFormControl.value, this.treatmentId, "rejected").subscribe(resp => {
          this.snackBar.open(this._translocoService.translate("patient.statusChangedSuccessfull"), "X", {
            duration: 3000,
            panelClass: "success",
            horizontalPosition: "right",
            verticalPosition: "top",
          } as MatSnackBarConfig);
          this.isLoading = false;
          this.closeAdditionButtonModal();
          setTimeout(() => {
            this.prepareTreatmentCall();
          }, 0);
        })
        break;
      case "evaluate":
        this._patientService.changeStatus(null, this.treatmentId, "inproject").subscribe(resp => {
          this.snackBar.open(this._translocoService.translate("patient.statusChangedSuccessfull"), "X", {
            duration: 3000,
            panelClass: "success",
            horizontalPosition: "right",
            verticalPosition: "top",
          } as MatSnackBarConfig);
          this.isLoading = false;
          this.closeAdditionButtonModal();
          //redirect to the dashboard
          this._router.navigate(["/dashboard"]);
          // setTimeout(() => {
          //   this.prepareTreatmentCall();
          // }, 0);
        })
        break;
      case "incomplete":
        var request = {
          "idTreatment": +this.treatmentId,
          "reason": this.otherReasonFormControl.value ? this.otherReasonFormControl.value : "",
          "reasonOptions": this.reasonFormControl.value ? [
            ...this.reasonFormControl.value
          ] : []
        }
        this._patientService.changeStatus(this.reasonFormControl.value, this.treatmentId, "incomplete", request).subscribe(resp => {
          this.snackBar.open(this._translocoService.translate("patient.statusChangedSuccessfull"), "X", {
            duration: 3000,
            panelClass: "success",
            horizontalPosition: "right",
            verticalPosition: "top",
          } as MatSnackBarConfig);
          this.isLoading = false;
          this.closeIncompleteModal();
          setTimeout(() => {
            this.prepareTreatmentCall();
            //refresh incomplete chat mesages
            this._messagingService.sendMessage('refreshIncompleteTreatmentChatMessages');
            //this.refreshIncompleteChat.next(true);
          }, 0);
        })
        break;
      case "deleteTreatment":
        this._patientService.deleteTreatment(this.treatmentId).subscribe(resp => {
          this.snackBar.open(this._translocoService.translate("patient.deleteSuccessfull"), "X", {
            duration: 3000,
            panelClass: "success",
            horizontalPosition: "right",
            verticalPosition: "top",
          } as MatSnackBarConfig);
          this.isLoading = false;
          this.closeAdditionButtonModal();
          setTimeout(() => {
            this._router.navigate(["/dashboard"], { replaceUrl: true });
          }, 0);
        },
          err => {
            this.snackBar.open(this._translocoService.translate("COMMON.ErrorMessage"), "X", {
              duration: 3000,
              panelClass: "warning",
              horizontalPosition: "right",
              verticalPosition: "top",
            } as MatSnackBarConfig);
            this.isLoading = false;
            this.closeAdditionButtonModal();
            setTimeout(() => {
              this.prepareTreatmentCall();
            }, 0);
          }
        )
        break;
      case "archiveTreatment":
        this._patientService.changeStatus(null, this.treatmentId, "archived").subscribe(resp => {
          this.snackBar.open(this._translocoService.translate("patient.statusChangedSuccessfull"), "X", {
            duration: 3000,
            panelClass: "success",
            horizontalPosition: "right",
            verticalPosition: "top",
          } as MatSnackBarConfig);
          this.isLoading = false;
          this.closeAdditionButtonModal();
          setTimeout(() => {
            this.prepareTreatmentCall();
          }, 0);
        },
          err => {
            this.snackBar.open(this._translocoService.translate("COMMON.ErrorMessage"), "X", {
              duration: 3000,
              panelClass: "warning",
              horizontalPosition: "right",
              verticalPosition: "top",
            } as MatSnackBarConfig);
            this.isLoading = false;
            this.closeAdditionButtonModal();
            setTimeout(() => {
              this.prepareTreatmentCall();
            }, 0);
          }
        )
        break;
      default:
        this.isLoading = false;
        return;
    }
  }

  copied(value) {
    if (value.isSuccess) {
      this.snackBar.open(this._translocoService.translate("patient.copiedSuccess"), "X", {
        direction: "rtl",
        duration: 3000,
        panelClass: "success",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
    }
  }

  addressCopied(value) {
    if (value.isSuccess) {
      this.snackBar.open(this._translocoService.translate("patient.addressCopiedSuccess"), "X", {
        direction: "rtl",
        duration: 3000,
        panelClass: "success",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
    }
  }

  verifyUploadedVideo(ref) {
    if (this.selectedTreatment.videoUploaded) {

      //if ((this.selectedTreatment.phase != '1' || this.selectedTreatment.phaseType == 'R') && this.selectedTreatment.videoFile != '') {
      if (this.selectedTreatment.videoFile != '' && this.selectedTreatment.videoType == 'OS') {
        //video file specified
        this._contentService.getWebViewerUrlFromFile(this.selectedTreatment.treatmentCode, this.selectedTreatment.idTreatment, this.selectedTreatment.videoFile).subscribe((resp: any) => {
          let a = document.createElement("a");
          a.id = "1navigateTag";
          a.target = resp.fullUrl;
          a.href = resp.fullUrl;
          a.setAttribute("onclick", resp.fullUrl);
          document.body.appendChild(a);
          let tag = document.getElementById("1navigateTag");
          tag.click();
          document.body.removeChild(a);
        });
        return;
      } else if (this.selectedTreatment.videoFile != '' && this.selectedTreatment.videoType != 'OS') {
        let a = document.createElement("a");
        a.id = "1navigateTag";
        a.target = this.selectedTreatment.videoFile;
        a.href = this.selectedTreatment.videoFile;
        a.setAttribute("onclick", this.selectedTreatment.videoFile);
        document.body.appendChild(a);
        let tag = document.getElementById("1navigateTag");
        tag.click();
        document.body.removeChild(a);
        return;
      } else {
        //no specified video file
        this._contentService.getWebViewerUrl(this.selectedTreatment.treatmentCode, this.selectedTreatment.idTreatment).subscribe((resp: any) => {
          let a = document.createElement("a");
          a.id = "1navigateTag";
          a.target = resp.fullUrl;
          a.href = resp.fullUrl;
          a.setAttribute("onclick", resp.fullUrl);
          document.body.appendChild(a);
          let tag = document.getElementById("1navigateTag");
          tag.click();
          document.body.removeChild(a);
        });
        return;
      }
    }

    //verify treatment phase
    //if (this.selectedTreatment.phase != '1' || this.selectedTreatment.phaseType == 'R') {
    //get the list of files into the treatment of phase 1
    this._contentService.getWebViewerFiles(this.selectedTreatment.treatmentCode).subscribe((resp: any) => {

      //show modal
      this.webViewerItems = resp;
      this.WebViewerFileSelector = this.modalServide.open(ref, { closeOnNavigation: true, panelClass: "lg-modal" });

      // if (resp.length > 0) {
      //   //show modal
      //   this.webViewerItems = resp;
      //   this.WebViewerFileSelector = this.modalServide.open(ref, { closeOnNavigation: true, panelClass: "lg-modal" });
      // } else {
      //   //no files found
      //   this.snackBar.open(this._translocoService.translate("patient.verifiedError"), "X", {
      //     duration: 3000,
      //     panelClass: "warning",
      //     horizontalPosition: "right",
      //     verticalPosition: "top",
      //   } as MatSnackBarConfig);
      //   this.isVerifyInProgres = false;
      //   this.cd.detectChanges();
      // }
    }, error => {
      this.snackBar.open(this._translocoService.translate("patient.verifiedError"), "X", {
        duration: 3000,
        panelClass: "warning",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
      this.isVerifyInProgres = false;
      this.cd.detectChanges();
    });
    // }
    // else {
    //   //phase 1
    //   this.isVerifyInProgres = true;
    //   this._contentService.verifyWebViewerUrl(this.selectedTreatment.treatmentCode).subscribe((resp: any) => {
    //     if (resp) {
    //       this.prepareTreatmentCall();
    //       this.snackBar.open(this._translocoService.translate("patient.verifiedSuccess"), "X", {
    //         duration: 3000,
    //         panelClass: "success",
    //         horizontalPosition: "right",
    //         verticalPosition: "top",
    //       } as MatSnackBarConfig);
    //     }
    //     this.isVerifyInProgres = false;
    //     this.cd.detectChanges();
    //   }, error => {
    //     this.snackBar.open(this._translocoService.translate("patient.verifiedError"), "X", {
    //       duration: 3000,
    //       panelClass: "warning",
    //       horizontalPosition: "right",
    //       verticalPosition: "top",
    //     } as MatSnackBarConfig);
    //     this.isVerifyInProgres = false;
    //     this.cd.detectChanges();
    //   });

    // }
  }

  videoChoice(type: string) {
    this.selectedTreatment.videoType = type;
  }

  closeWebViewerFileSelectorModal() {
    this.WebViewerFileSelector.close();
  }

  updateWebViewFile() {

    let filename: string = '';
    switch (this.selectedTreatment.videoType) {
      case 'OS':
        filename = this.webViewerItemSelected;
        break;
      case 'OL':
        //comcat the current idtreatment
        let tmplink = this.videoLink.substring(0, this.videoLink.indexOf("&"));
        tmplink += '&fg=7bb&bg=eee&o=2&p=nuvola&t=' + this.selectedTreatment.idTreatment;
        filename = tmplink;
        break;
      case 'LK':
        filename = this.videoLink;
        break;
    }

    let request = {
      "idTreatment": this.selectedTreatment.idTreatment,
      "fileName": filename,
      "videoType": this.selectedTreatment.videoType
    };

    if (filename == undefined || filename == '') {
      this.snackBar.open(this._translocoService.translate("COMMON.ErrorMessage"), "X", {
        duration: 3000,
        panelClass: "warning",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
      return;
    }

    this.treatmentsService.updateWebViewerFile(request).subscribe(resp => {
      this.WebViewerFileSelector.close();

      this.prepareTreatmentCall();
      this.snackBar.open(this._translocoService.translate("patient.verifiedSuccess"), "X", {
        duration: 3000,
        panelClass: "success",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
      this.cd.detectChanges();

    }, error => {
      this.snackBar.open(this._translocoService.translate("COMMON.ErrorMessage"), "X", {
        duration: 3000,
        panelClass: "warning",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
      this.isVerifyInProgres = false;
      this.cd.detectChanges();
    });

  }

  collapseContent(value) {
    this.collapsed = value;
    this.treatmentAdditionalFunctionalService.isPanelCollapsed = value;
  }

  isAllowToViewScanDnD() {
    if (
      (
        this.selectedTreatment && this.currentUser.role == "US" && this.currentUser.userGroup == "ST" &&
        (this.selectedTreatment.status == "EVALUATION" || this.selectedTreatment.status == "DRAFT" ||
          this.selectedTreatment.status == "INCOMPLETE" || this.selectedTreatment.status == "REJECTED" ||
          this.selectedTreatment.status == "INPROJECT" || this.selectedTreatment.status == "INREVIEW"
        )
      )
    ) {
      return true;
    } else if (
      this.selectedTreatment && (this.currentUser.role == "US" && this.currentUser.userGroup == "PJ")
      && (this.selectedTreatment.status == "INPROJECT")
      // && this.selectedTreatment.archesType == "D" && this.selectedTreatment.archesSentViaCommunicator)
    ) {
      return true;
    }
    else if (this.selectedTreatment && this.currentUser.role == "DO" && (this.selectedTreatment.scanUpper || this.selectedTreatment.scanLower)
      &&
      (this.selectedTreatment.status == "INPROJECT" || this.selectedTreatment.status == "INREVIEW" ||
        this.selectedTreatment.status == "APPROVED" || this.selectedTreatment.status == "PRODUCTION" ||
        this.selectedTreatment.status == "SHIPPED")
    ) {
      this.disabledSTL = true;
      return true;
    }
    return false;
  }

  openScheduleModal(ref) {
    this.treatmentsService.getScheduleTimes(this._convertService.dateConvert(new Date().toUTCString(), "YYYY-MM-DDTHH:MM:SS")).subscribe((resp: string[]) => {
      this.availableScheduleTime = [];
      for (let date of resp) {
        let iteratorDate = new Date(date);

        if (this.availableScheduleTime.find(x => x.every(t => new Date(t).getDate() == iteratorDate.getDate())) != null && this.availableScheduleTime.length != 0) {
          this.availableScheduleTime.find(x => x.every(t => new Date(t).getDate() == iteratorDate.getDate())).push(date);
          continue;
        }
        let arrayOfDate = [];
        arrayOfDate.push(date);
        this.availableScheduleTime.push(arrayOfDate);
      }
    });
    this.schedulePickUp = this.modalServide.open(ref, { panelClass: "lg-modal", closeOnNavigation: true });
  }

  getAvailableScheduleDate(pickupDate, pickupDate2) {
    let date = new Date(pickupDate);
    let date2 = new Date(pickupDate2);
    let prefix = this._translocoService.translate("patient.GENERAL_INFO.plannedPickupOf");
    let res = `${date.getHours()}:00-${date.getHours() + 4}:00 ${prefix} ${this._convertService.dateConvert(pickupDate, "DD/MM/YYYY")}`;
    if (pickupDate2 != null)
      res += ` - ${date2.getHours()}:00-${date2.getHours() + 4}:00 ${prefix} ${this._convertService.dateConvert(pickupDate2, "DD/MM/YYYY")}`;
    return res;
  }

  getConvertedScheduledDate() {
    return this.getAvailableScheduleDate(this.selectedTreatment.pickupDate, this.selectedTreatment.pickupDate2)
    // return this._convertService.dateConvert(this.selectedTreatment.pickupDate, "DD/MM/YYYY HH:MM")  
  }
  closeScheduleModal() {
    this.schedulePickUp.close();
  }

  scheduleButtonAllowToRender() {
    if (!this.selectedTreatment) {
      return false;
    }

    if (this.tabThreeForm.controls.archesType.value == "D" || this.tabThreeForm.controls.archesType.value == null) {
      return false;
    }

    if ((this.selectedTreatment.status == "DRAFT" || this.selectedTreatment.status == "INCOMPLETE") && !this.selectedTreatment.pickupDate && this.currentUser.role == "DO") {
      return true;
    }

    return false;
  }

  addressSelection(value: any) {
    if (value == 'new') {
      //redirect to myprofile page
      this._router.navigate(["/myprofile"]);
    }
  }

  getSelectedAddress(items: any[], id: number) {
    if (items == null)
      return;
    let ret = items.filter(x => x.idAddress == id)[0];
    if (ret != null) {
      return (ret.activityName != '' ? ret.activityName + ' - ' : '') + ret.address + ' - ' + ret.city + ' - ' + ret.zipCode + ' - ' + ret.countryName;
    }
    else {
      return '';
    }
  }

  scheduledTipeAllowToRender() {
    if (this.tabThreeForm.controls.archesType.value == "T" && this.selectedTreatment.pickupDate) {
      return true;
    }

    return false;
  }

  confirmSchedule() {

    let selectedDate: any[] = this.scheduleForm.controls.pickupDate.value;

    if (!this.scheduleForm.valid || selectedDate.length == 0) {
      this.snackBar.open(this._translocoService.translate("patient.GENERAL_INFO.scheduleTimeSelectError"), "X", {
        duration: 3000,
        panelClass: "warning",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
      return;
    }

    let request = this.scheduleForm.value;

    request = {
      //...request,
      "pickupDate": selectedDate[0],
      "pickupDate2": selectedDate.length == 2 ? selectedDate[1] : null,
      "pickupNote": this.scheduleForm.controls.pickupNote.value,
      "idTreatment": this.selectedTreatment.idTreatment,
      "IdAddressReturn": this.tabTwoForm.controls.return.value
    };

    this.treatmentsService.putScheduleTime(request).subscribe(resp => {
      if (resp) {
        this.snackBar.open(this._translocoService.translate("patient.GENERAL_INFO.scheduleTimeSuccess"), "X", {
          direction: "rtl",
          duration: 3000,
          panelClass: "success",
          horizontalPosition: "right",
          verticalPosition: "top",
        } as MatSnackBarConfig);

        setTimeout(() => {
          this.closeScheduleModal();
          this.prepareTreatmentCall();
        }, 0)
      }

    }, error => {
      this.snackBar.open(this._translocoService.translate("COMMON.ErrorMessage"), "X", {
        direction: "rtl",
        duration: 3000,
        panelClass: "warning",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
    })

  }

  addImageToCollection(imageName: string) {
    if (imageName != '') {
      this.imagesCollection.push({ path: this.storageBaseUrl + '/t-' + this.treatmentId + '/' + imageName });
    }
  }

  handleCarouselEvents(event: any) {
    console.log('event', event);
  }

  next() {
    //this.myCarousel.next();
    console.log('next');
  }

  openImagePreviewModal(imagePreview) {
    //prepare image preview
    this.imagesCollection = [];
    if (this.selectedTreatment) {
      if (this.selectedTreatment.imageCollage.match(/.(jpg|jpeg|png|gif)$/i)) {
        this.addImageToCollection(this.selectedTreatment.imageCollage);
      }
      this.addImageToCollection(this.selectedTreatment.imageUA);
      this.addImageToCollection(this.selectedTreatment.imageBA);
      this.addImageToCollection(this.selectedTreatment.imageLO);
      this.addImageToCollection(this.selectedTreatment.imageFO);
      this.addImageToCollection(this.selectedTreatment.imageRO);
      this.addImageToCollection(this.selectedTreatment.imageLS);
      this.addImageToCollection(this.selectedTreatment.imageFS);
      this.addImageToCollection(this.selectedTreatment.imageRS);
      this.addImageToCollection(this.selectedTreatment.imageOrtho);
      this.addImageToCollection(this.selectedTreatment.imageTelecranio);
      this.addImageToCollection(this.selectedTreatment.imageOther);
    }
    if (this.imagesCollection.length > 0)
      this.imagePreview = this.modalServide.open(imagePreview, { panelClass: "lg-modal-imagepreview", closeOnNavigation: false });
    else {
      this.snackBar.open(this._translocoService.translate("patient.GENERAL_INFO.imagePreviewNotFound"), "X", {
        duration: 3000,
        panelClass: "warning",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
    }
  }

  showPatientInfoModal(ref) {
    this.PatientAdditionalInfo = this.modalServide.open(ref, { closeOnNavigation: true, panelClass: "lg-modal-doctorAddInfo" });
  }

  showDoctorInfoModal(ref) {
    //get data from localstorage + get clinic preferences
    this.isLoading = true;
    this._usersService.getUserById(this.selectedTreatment.idDoctor).subscribe((doctor: User) => {

      if (doctor.whatsAppFlag) {
        this.doctorWhatsappNumber = doctor.whatsAppDialCode + doctor.whatsAppNumber;;
      } else {
        this.doctorWhatsappNumber = 'N/D';
      }

      this.doctorAddInfoQuestion1 = 'questionEmpty';
      switch (doctor.question1) {
        case 'N':
          this.doctorAddInfoQuestion1 = 'questionNo';
          break;
        case 'Y':
          this.doctorAddInfoQuestion1 = 'questionYes';
          this.doctorAddInfoHowManyPatients = true;
          break;
      }
      // this.doctorAddInfoQuestion2 = 'questionEmpty';
      // switch (doctor.question2) {
      //   case 'N':
      //     this.doctorAddInfoQuestion2 = 'questionNo';
      //     break;
      //   case 'Y':
      //     this.doctorAddInfoQuestion2 = 'questionYes';
      //     break;
      // }
      // this.doctorAddInfoQuestion3 = 'questionEmpty';
      // switch (doctor.question3) {
      //   case 'N':
      //     this.doctorAddInfoQuestion3 = 'questionNo';
      //     break;
      //   case 'Y':
      //     this.doctorAddInfoQuestion3 = 'questionYes';
      //     break;
      // }
      switch (doctor.question11) {
        case '1':
          this.doctorAddInfoQuestion11 = 'question1.1.1';
          break;
        case '2':
          this.doctorAddInfoQuestion11 = 'question1.1.2';
          break;
        case '3':
          this.doctorAddInfoQuestion11 = 'question1.1.3';
          break;
        case '4':
          this.doctorAddInfoQuestion11 = 'question1.1.4';
          break;
      }

      this._usersService.getClinicPreferences(this.selectedTreatment.idDoctor).subscribe((resp: any) => {
        this.isLoading = false;
        this.doctorClinicPreferences = resp.clinicPreferences;
        this.doctorInternalNotes = resp.internalNotes;
        if (this.doctorClinicPreferences == '')
          this.doctorClinicPreferences = "N/A";
        this.DoctorAdditionalInfo = this.modalServide.open(ref, { closeOnNavigation: true, panelClass: "lg-modal-doctorAddInfo" });

        this.doctorUserFullName = resp.doctorUserFullName == null ? '-' : resp.doctorUserFullName;
        this.doctorAgentFullName = resp.doctorAgentFullName == null ? '-' : resp.doctorAgentFullName;
        this.doctorShowNuvolaJunior = resp.showNuvolaJunior;
        
        this.cd.detectChanges();
      }, error => {
        this.isLoading = false;
        this.cd.detectChanges();
        this.snackBar.open(this._translocoService.translate("COMMON.ErrorMessage"), "X", {
          duration: 3000,
          panelClass: "warning",
          horizontalPosition: "right",
          verticalPosition: "top",
        } as MatSnackBarConfig);
      });

    }, error => {
      this.isLoading = false;
      this.cd.detectChanges();
      this.snackBar.open(this._translocoService.translate("COMMON.ErrorMessage"), "X", {
        duration: 3000,
        panelClass: "warning",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
    });
  }

  waitingForSTL_Change(event) {
    this.isLoading = true;
    this._patientService.updateWaitingForSTL(event.checked, this.selectedTreatment.idTreatment).subscribe((resp: any) => {
      this.waitingForSTL = event.checked;
      this.isLoading = false;
      this.cd.detectChanges();
      this.snackBar.open(this._translocoService.translate("patient.saveTreatmentSuccessful"), "X", {
        duration: 3000,
        panelClass: "success",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
    }, error => {
      this.isLoading = false;
      this.cd.detectChanges();
      this.snackBar.open(this._translocoService.translate("COMMON.ErrorMessage"), "X", {
        duration: 3000,
        panelClass: "warning",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
    })

  }

  onScheduleTimeChecked(event, row) {
    let selectedDate: any[] = this.scheduleForm.controls.pickupDate.value;

    if (!event.checked) {
      selectedDate = selectedDate.filter(x => x != row);
      this.scheduleForm.controls.pickupDate.setValue(selectedDate);
      return;
    }

    if (selectedDate == null || selectedDate.length == 0) {
      selectedDate = [];
      selectedDate.push(row);
      this.scheduleForm.controls.pickupDate.setValue(selectedDate);
      return;
    }

    if (selectedDate.length == 2) {
      event.source._checked = false;
      this.snackBar.open(this._translocoService.translate("COMMON.allowedSelectedMaximumLimit"), "X", {
        duration: 3000,
        panelClass: "warning",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
      return;
    }

    if (new Date(row).getDate() != new Date(selectedDate[0]).getDate()) {
      event.source._checked = false;
      this.snackBar.open(this._translocoService.translate("COMMON.couldBeSelectedOnlySameDay"), "X", {
        duration: 3000,
        panelClass: "warning",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
      return;
    }

    selectedDate.push(row);
    this.scheduleForm.controls.pickupDate.setValue(selectedDate);
  }

  openCheckSendToNuvolaModal(modalType:string) {
    return Observable.create((observer: Observer<boolean>) => {
      let dialogRef = this.modalServide.open(TreatmentModalComponent, {
        width: '500px',
        disableClose: true,
        data: { idTreatment: this.selectedTreatment.idTreatment, modalType: modalType}
      })
      dialogRef.afterClosed().subscribe(result => {
        console.log('res', result);
        observer.next(result);
        observer.complete();
      }, (error) => {
        observer.next(false);
        observer.complete();
      });
    });
  }

  showNuvolaJunior() {
    if (this.currentUser && this.currentUser.role == 'DO') {
      //logged in user is a DOCTOR
      return this.currentUser.showNuvolaJunior;
    } else {
      //logged in user is NOT a DOCTOR
      return true;
    }
  }

  onTreatmentTypeChange(treatmentType: string) {
    console.log('treatmentType', treatmentType);
    if (this.selectedTreatment && treatmentType == 'NJ') {
      //It's JUNIOR => All other images are mandatory
      this.showAllOtherImageMandatoryAsterisk = true;
      this.tabFourForm.controls.leftSide.setValidators(Validators.required);
      this.tabFourForm.controls.leftSide.setValue(this.tabFourForm.controls.leftSide.value);
      this.tabFourForm.controls.frontSide.setValidators(Validators.required);
      this.tabFourForm.controls.frontSide.setValue(this.tabFourForm.controls.frontSide.value);
      this.tabFourForm.controls.rightSide.setValidators(Validators.required);
      this.tabFourForm.controls.rightSide.setValue(this.tabFourForm.controls.rightSide.value);
      // this.tabFourForm.controls.rsOrthopanoramics.setValidators(Validators.required);
      // this.tabFourForm.controls.rsOrthopanoramics.setValue(this.tabFourForm.controls.rsOrthopanoramics.value);
      // this.tabFourForm.controls.telecranio.setValidators(Validators.required);
      // this.tabFourForm.controls.telecranio.setValue(this.tabFourForm.controls.telecranio.value);
    }
    else {
      this.showAllOtherImageMandatoryAsterisk = false;
      this.tabFourForm.controls.leftSide.clearValidators();
      //this.tabFourForm.controls.leftSide.reset();
      this.tabFourForm.controls.frontSide.clearValidators();
      //this.tabFourForm.controls.frontSide.reset();
      this.tabFourForm.controls.rightSide.clearValidators();
      //this.tabFourForm.controls.rightSide.reset();
      //this.tabFourForm.controls.rsOrthopanoramics.clearValidators();
      //this.tabFourForm.controls.rsOrthopanoramics.reset();
      //this.tabFourForm.controls.telecranio.clearValidators();
      //this.tabFourForm.controls.telecranio.reset();
    }
  }

  saveAddresses() {

    let request = {
      "idTreatment": +this.treatmentId,
      "idAddressShipping": this.tabTwoForm.controls.ship.value,
      "idAddressInvoice": this.tabTwoForm.controls.invoice.value
    };
    this.treatmentsService.updateAddresses(request).subscribe(resp => {

      this.isLoading = false;

      this.snackBar.open(this._translocoService.translate('patient.ADDITIONAL.updateAddressesMessageConfirm'), "X", {
        duration: 3000,
        panelClass: "success",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);

      //refresh current treatment
      setTimeout(() => {
        this.prepareTreatmentCall();
      }, 0);

    }, error => {
      this.snackBar.open(this._translocoService.translate("COMMON.ErrorMessage"), "X", {
        duration: 3000,
        panelClass: "warning",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
      this.isLoading = false;
      this.cd.detectChanges();
    });

  }

  getFullAddress(addressInfo: any) {
    let res: string = '';
    console.log('addressInfo', addressInfo);
    if (addressInfo != undefined && addressInfo != null) {
      if (addressInfo.addressInfo != '')
        res = addressInfo.addressInfo + ' - ';
      res += addressInfo.address + ' - ' + addressInfo.city + ' - ' + addressInfo.zipCode + ' - ' + addressInfo.countryName;
    }
    return res;
  }

  getColorNotes(fieldname: string) {
    switch (fieldname) {
      case 'specialInstructionsLabel':
        if (this.disabled) {
          //gray
          return 'rgba(0, 0, 0, 0.7)';
        } else {
          if (this.tabSixForm.valid)
            //black
            return 'rgb(0, 0, 0)';
          else
            //red
            return 'rgb(244, 67, 54)';
        }
        break;
      case 'archesType':
        if (this.disabled) {
          //gray
          return 'rgba(0, 0, 0, 0.7)';
        } else {
          if (this.tabThreeForm.controls.archesType.value == 'T' || this.tabThreeForm.controls.archesType.value == 'D')
            //black
            return 'rgb(0, 0, 0)';
          else
            //red
            return 'rgb(244, 67, 54)';
        }
        break;
      case 'noExcludeTeethLabel':
        if (this.disabled) {
          //gray
          return 'rgba(0, 0, 0, 0.7)';
        } else {
          if (this.tabFiveForm.valid)
            //black
            return 'rgb(0, 0, 0)';
          else
            //red
            return 'rgb(244, 67, 54)';
        }
        break;
      default:
        if (this.disabled) {
          //gray
          return 'rgba(0, 0, 0, 0.7)';
        } else {
          //black
          return 'rgb(0, 0, 0)';
        }
        break;
    }
  }
}

export function customNullValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return control.value == "null" ? { value: control.value } : null;
  }
}

export function customFalseValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return control.value == false ? { value: control.value } : null;
  }
}