import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { UserService } from 'src/app/modules/security/services/user.service';
import { ContentsService } from '../contents/services/contents.service';
import { PatientService } from '../new-patient/patient.service';
import { TreatmentAdditionalFunctionalService } from '../new-patient/treatment-additional-functional/treatment-additional-functional.service';
import { ConvertService } from '../shared/services/convert.service';
import { TreatmentsService } from '../treatments/treatments.service';
import { UsersService } from '../users/users.service';
import * as FileSaver from 'file-saver';
import { User } from 'src/app/modules/security/models/user.model';
import { Observable, Observer, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { TreatmentsNextPhaseModalComponent } from './treatments-next-phase-modal/treatments-next-phase-modal.component';

@Component({
  selector: 'nuvolaweb-treatments-next-phase',
  templateUrl: './treatments-next-phase.component.html',
  styleUrls: ['./treatments-next-phase.component.scss']
})
export class TreatmentsNextPhaseComponent implements OnInit {
  @ViewChild('confirmModal', { static: true }) confirmModal: MatDialogRef<any>;
  @ViewChild('schedulePickUp', { static: true }) schedulePickUp: MatDialogRef<any>;
  @ViewChild('fileLoadProgressModal', { static: true }) fileLoadProgressModal: MatDialogRef<any>;

  public progressUpload: number = 0;
  public progressUploadSaving: boolean = false;
  public singleFileProgressValue: number = 0;
  public fileUploadProgressCounter: number = 0;
  public currentFileIterator: number = 0;
  public totalFileCount: number = 0;
  private request: any;
  private reload = false;
  private isError = false;
  public waitingForSTL: boolean = true;

  private isUploading = false;
  public speedUpload: number = 0;
  public unitUpload: string = "Mbps";

  public SaveAndSend: boolean = false;
  public updateStatusNewValue: string = "";
  public isUpdatieStillLoading: boolean = false
  uploadSub: Subscription;

  public webViewerItems = [];
  public webViewerItemSelected: string;
  checkModal: any;
  isMovingEvent: boolean = false;
  public ignoreModal:boolean = false;

  constructor(
    private route: ActivatedRoute,
    private _usersService: UsersService,
    private treatmentAdditionalFunctionalService: TreatmentAdditionalFunctionalService,
    private _patientService: PatientService,
    private cd: ChangeDetectorRef,
    private _router: Router,
    private fb: FormBuilder,
    private treatmentsService: TreatmentsService,
    private _convertService: ConvertService,
    private modalServide: MatDialog,
    private _translocoService: TranslocoService,
    private snackBar: MatSnackBar,
    private _breakpointObserver: BreakpointObserver,
    private _contentService: ContentsService,
    private userService: UserService
  ) {
    this._breakpointObserver.observe([
      Breakpoints.Handset
    ]).subscribe(result => {
      this.isMobile = result.matches;
    });

    this._subsctiptions = this._router.events.subscribe((event) => {
    })
  }

  ngOnDestroy(): void {
    this._subsctiptions.unsubscribe();
  }

  private _subsctiptions: Subscription;

  currentUser: User;
  idTreatment: string;
  selectedTreatment: any;
  isLoading: boolean = true;
  public imageTabOpen: boolean = true;
  public VideoTabOpen: boolean = false;
  public phaseType: string;
  public imageTabDisabled: boolean = false;
  showAddress: boolean = true;
  public isMobile: boolean = false;
  modalMessage: any;

  fileToBeUploaded: any[] = [];
  nextPhaseForm: FormGroup;
  scheduleForm: FormGroup;
  returns: any;
  shippings: any;
  //availableScheduleTime: string[] = [];
  availableScheduleTime: any[] = [];

  public isStartUser: boolean = false;

  async ngOnInit() {
    this.idTreatment = this.route.snapshot.paramMap.get('id');
    this.currentUser = await this.userService.readUserFromLocalStorage();

    // if (this.currentUser.role == 'DO') {
    //   //DOCTOR
    //   this.nextPhaseForm = this.fb.group({
    //     //askForNuvolaView: [true],
    //     archesType: ['T'],
    //     archesSentViaCommunicator: [false],
    //     scanLower: [null],
    //     scanUpper: [null],
    //     return: ['null', [Validators.required, customNullValidator()]],
    //     ship: ['null', [Validators.required, customNullValidator()]],
    //     imageType: ['S', Validators.required],
    //     bottomArch: [null],
    //     upperArch: [null],
    //     leftOcclusal: [null],
    //     frontOcclusal: [null],
    //     rightOcclusal: [null],
    //     imageCollage: [null],
    //     noSpecialInstruction: [false],
    //     specialInstruction: [null, [Validators.required]]
    //   });
    // } else if (this.currentUser.role == 'US' && this.currentUser.userGroup == 'ST') {
    //   //START USER 
    //   this.nextPhaseForm = this.fb.group({
    //     archesType: ['D'],
    //     archesSentViaCommunicator: [false],
    //     scanLower: [null, Validators.required],
    //     scanUpper: [null, Validators.required],
    //     return: ['null'],
    //     ship: ['null'],
    //     imageType: ['S'],
    //     bottomArch: [null],
    //     upperArch: [null],
    //     leftOcclusal: [null],
    //     frontOcclusal: [null],
    //     rightOcclusal: [null],
    //     imageCollage: [null],
    //     noSpecialInstruction: [false],
    //     specialInstruction: [null]
    //   });
    // }

    this.nextPhaseForm = this.fb.group({
      //askForNuvolaView: [true],
      archesType: ['T'],
      archesSentViaCommunicator: [false],
      scanLower: [null],
      scanUpper: [null],
      return: ['null', [Validators.required, customNullValidator()]],
      ship: ['null', [Validators.required, customNullValidator()]],
      imageType: ['S', Validators.required],
      bottomArch: [null],
      upperArch: [null],
      leftOcclusal: [null],
      frontOcclusal: [null],
      rightOcclusal: [null],
      imageCollage: [null],
      noSpecialInstruction: [false],
      specialInstruction: [null, [Validators.required]]
    });

    this._subsctiptions.add(this.nextPhaseForm.controls.archesSentViaCommunicator.valueChanges.pipe(debounceTime(25)).subscribe(value => {
      this.setSTLValidation();
    }));

    this.scheduleForm = this.fb.group({
      pickupDate: [null, Validators.required],
      pickupNote: ['']
    });

    //get treatment plan info to detect if it's a new phase or revision
    await this.treatmentAdditionalFunctionalService.initService(this.idTreatment);

    this.selectedTreatment = await this._patientService.getTreatment(this.idTreatment).toPromise();

    this.returns = await this._usersService.getAddressById(this.selectedTreatment.idDoctor, "R").toPromise();
    this.shippings = await this._usersService.getAddressById(this.selectedTreatment.idDoctor, "S").toPromise();

    //check if idAddressReturn exists in list received
    let returnExists = this.returns.filter(x => x.idAddress == this.selectedTreatment.idAddressReturn);
    if (returnExists.length == 0)
      this.nextPhaseForm.controls.return.setValue(null);
    else
      this.nextPhaseForm.controls.return.setValue(this.selectedTreatment.idAddressReturn);

    //check if idAddressShipping exists in list received
    let shippingExists = this.shippings.filter(x => x.idAddress == this.selectedTreatment.idAddressShipping);
    if (shippingExists.length == 0)
      this.nextPhaseForm.controls.ship.setValue(null);
    else
      this.nextPhaseForm.controls.ship.setValue(this.selectedTreatment.idAddressShipping);

    //No special instruction as default
    this.nextPhaseForm.controls.noSpecialInstruction.setValue(true);
    this.onSpecialInstructioCheckBox(true);

    this.phaseType = this.treatmentAdditionalFunctionalService.getPlanField('goToNextPhase');    //P = Phase; R=Revision

    //if (this.currentUser.role == 'DO') {
      //DOCTOR
      //this.imageTabOpen = this.phaseType == 'R';
      this.imageTabDisabled = this.phaseType == 'R';
      this.nextPhaseForm.controls.archesType.setValue('T');
    // }
    // else if (this.currentUser.role == 'US' && this.currentUser.userGroup == 'ST') {
    //   //STRAT USER - allow only STL files
    //   this.isStartUser = true;
    //   //this.imageTabOpen = true;
    //   this.imageTabDisabled = true;
    //   this.nextPhaseForm.controls.archesType.setValue('D');
    //   this.nextPhaseForm.controls.archesType.disable();
    //   this.nextPhaseForm.controls.archesType.disable();
    //   this.showAddress = false;
    // }
    this.isLoading = false;
    
    this.cd.detectChanges();
  }

  setSTLValidation() {
    let archesType = this.nextPhaseForm.controls.archesType.value;
    let archesSentViaCommunicator = this.nextPhaseForm.controls.archesSentViaCommunicator.value;

    if (archesType == "T") {
      //traditional
      this.nextPhaseForm.controls.scanLower.clearValidators();
      this.nextPhaseForm.controls.scanUpper.clearValidators();
    } else if (archesType == "D") {
      //digital
      if (archesSentViaCommunicator) {
        this.nextPhaseForm.controls.scanLower.clearValidators();
        this.nextPhaseForm.controls.scanUpper.clearValidators();
      } else {
        this.nextPhaseForm.controls.scanLower.setValidators(Validators.required);
        this.nextPhaseForm.controls.scanUpper.setValidators(Validators.required);
      }
    }
    this.nextPhaseForm.controls.scanUpper.setValue(null);
    this.nextPhaseForm.controls.scanLower.setValue(null);
    this.cd.detectChanges();
  }

  goBack() {
    this._router.navigate([`/treatments/${this.idTreatment}`]);
  }

  openConfirmModal(ref) {
    if (this.nextPhaseForm.valid) {
      this.modalMessage = "patient.NEXTPHASE.confirmMessage"
      this.confirmModal = this.modalServide.open(ref, { closeOnNavigation: true });
    } else {
      this.snackBar.open(this._translocoService.translate("patient.NEXTPHASE.errorMessage"), "X", {
        duration: 3000,
        panelClass: "warning",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
      this.nextPhaseForm.markAllAsTouched();
    }
  }

  closeConfirmModal() {
    this.confirmModal.close();
  }

  async doCreateNextPhase(ref) {
    this.closeConfirmModal();
    this.isLoading = true;

    let selectedDate: any[] = this.scheduleForm.controls.pickupDate.value;
    if (this.nextPhaseForm.controls.archesType.value=='D'){
      selectedDate=null;
    }

    let request = {
      "idTreatment": +this.idTreatment,
      "sendImages": this.imageTabOpen,
      "sendVideo": this.VideoTabOpen,
      //"askNuvolaView": this.nextPhaseForm.controls.askForNuvolaView.value,
      "archesType": this.imageTabOpen ? this.nextPhaseForm.controls.archesType.value : this.selectedTreatment.archesType,
      "idAddressReturn": this.nextPhaseForm.controls.return.value == null ? -1 : this.nextPhaseForm.controls.return.value,
      "idAddressShipping": this.nextPhaseForm.controls.ship.value == null ? -1 : this.nextPhaseForm.controls.ship.value,
      "archesSentViaCommunicator": this.nextPhaseForm.controls.archesSentViaCommunicator.value,
      "imageType": this.nextPhaseForm.controls.imageType.value,
      "imageUA": this.nextPhaseForm.controls.upperArch.value,
      "imageBA": this.nextPhaseForm.controls.bottomArch.value,
      "imageLO": this.nextPhaseForm.controls.leftOcclusal.value,
      "imageFO": this.nextPhaseForm.controls.frontOcclusal.value,
      "imageRO": this.nextPhaseForm.controls.rightOcclusal.value,
      "imageCollage": this.nextPhaseForm.controls.imageCollage.value,
      "scanLower": this.nextPhaseForm.controls.scanLower.value,
      "scanUpper": this.nextPhaseForm.controls.scanUpper.value,
      "noSpecialInstruction": this.nextPhaseForm.controls.noSpecialInstruction.value,
      "specialInstruction": this.nextPhaseForm.controls.specialInstruction.value,
      "pickupDate": selectedDate == null ? null : selectedDate[0],    //this.scheduleForm.controls.pickupDate.value,
      "pickupDate2": selectedDate != null && selectedDate.length == 2 ? selectedDate[1] : null,
      "pickupNote": this.scheduleForm.controls.pickupNote.value,
      "isMigrated": this.selectedTreatment.isOld
    };

    this._patientService.gotoNextPhaseRevision(request).subscribe(async (resp: any) => {
      if (resp.result) {
       
        this.idTreatment = resp.idTreatment;

        if (this.fileToBeUploaded.length != 0 && ref != null) {
          this.fileLoadProgressModal = this.modalServide.open(ref, { closeOnNavigation: true, disableClose: true, panelClass: "lg-modal" });
          this.uploadFileScanc(this.fileToBeUploaded[0]);
        }else{
          this.ignoreModal =true;
          this.snackBar.open(this._translocoService.translate("patient.NEXTPHASE.savingSuccess"), "X", {
            duration: 3000,
            panelClass: "success",
            horizontalPosition: "right",
            verticalPosition: "top",
          } as MatSnackBarConfig);
          this._router.navigate(["/treatments", this.idTreatment]);
        }
      } else {
        this.snackBar.open(this._translocoService.translate("COMMON.ErrorMessage"), "X", {
          duration: 3000,
          panelClass: "warning",
          horizontalPosition: "right",
          verticalPosition: "top",
        } as MatSnackBarConfig);
      }
      this.isLoading = false;
      this.cd.detectChanges();
    }, error => {
      this.snackBar.open(this._translocoService.translate("COMMON.ErrorMessage"), "X", {
        duration: 3000,
        panelClass: "warning",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
      this.isLoading = false;
      this.cd.detectChanges();
    });
  }

  uploadFileScanc(file: any) {
    this.progressUploadSaving = false;
    this.progressUpload = 0;
    ++this.currentFileIterator;
    this.reload = true;
    this.isUploading = true;

    let percentDone: number;
    let currTime: any;
    let prevTime: any;
    let bytesReceied: number = 0;
    let oldbytes: number = 0;

    //setting start time
    this.uploadSub = this._contentService.uploadContentBlob(file.formData, `t-${this.idTreatment}`, true).subscribe({
      next: (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:

            //upload speed 
            percentDone = Math.round((100 * event.loaded) / event.total);
            currTime = new Date().getTime();
            //tracking how much data is received
            bytesReceied = event.loaded / 1000000;
            //calculating download speed per percent data received
            this.speedUpload = (bytesReceied - oldbytes) / ((currTime - prevTime) / 1000);
            if (this.speedUpload < 1) {
              this.unitUpload = "Kbps";
              this.speedUpload *= 1000;
            } else this.unitUpload = "Mbps";
            //updating previous values
            prevTime = currTime;
            oldbytes = bytesReceied;
            
            this.speedUpload = Math.round(this.speedUpload);
           
            console.log('speed: ' + this.speedUpload + ' ' + this.unitUpload);
           
            this.progressUpload = this.round(event.loaded / event.total * 100, 1);
            this.progressUploadSaving = this.progressUpload == 100;
            break;
          case HttpEventType.Response:
            console.log('File successfully created!', event.body);
            this.progressUploadSaving = false;
            break;
        }
      }, error: () => {
        this.request[file.fieldName] = "";
        this.isError = true;
        this.isUploading = false;

        this.snackBar.open(this._translocoService.translate("patient.NEXTPHASE.fileErrorMessage"), "X", {
          duration: 3000,
          panelClass: "warning",
          horizontalPosition: "right",
          verticalPosition: "top",
        } as MatSnackBarConfig);
          this._router.navigate(["/treatments", this.idTreatment]);
      }, complete: () => {
        this.isUploading = false;

        if (this.fileToBeUploaded.indexOf(file) != this.fileToBeUploaded.length - 1) {
          this.uploadFileScanc(this.fileToBeUploaded[this.fileToBeUploaded.indexOf(file) + 1])
          return;
        }else{
          this.ignoreModal =true;
          this.snackBar.open(this._translocoService.translate("patient.NEXTPHASE.savingSuccess"), "X", {
            duration: 3000,
            panelClass: "success",
            horizontalPosition: "right",
            verticalPosition: "top",
          } as MatSnackBarConfig);
          
          this.fileLoadProgressModal.close();
          this._router.navigate(["/treatments", this.idTreatment]);
        }
      }
    });
  }

  round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  onSpecialInstructioCheckBox(checked) {
    if (checked) {
      this.nextPhaseForm.controls.specialInstruction.disable({ onlySelf: true });
    } else {
      this.nextPhaseForm.controls.specialInstruction.enable({ onlySelf: true });
    }
    this.nextPhaseForm.controls.specialInstruction.reset();
  }

  showHideAddress(event) {
    this.setSTLValidation();
    switch (this.nextPhaseForm.controls.archesType.value) {
      case "D":
        this.showAddress = false;
        this.nextPhaseForm.controls.return.clearValidators();
        this.nextPhaseForm.controls.return.reset();
        this.nextPhaseForm.controls.ship.clearValidators();
        this.nextPhaseForm.controls.ship.reset();
        break;
      case "T":
        this.showAddress = true;
        this.nextPhaseForm.controls.return.setValidators(Validators.required);
        this.nextPhaseForm.controls.ship.setValidators(Validators.required);
        break;
    }
  }

  getAvailableScheduleDate(row) {
    let date = new Date(row);
    let prefix = this._translocoService.translate("patient.GENERAL_INFO.plannedPickupOf");
    return `${date.getHours()}:00-${date.getHours() + 4}:00 ${prefix} ${this._convertService.dateConvert(row, "DD/MM/YYYY")}`;
  }

  openScheduleModal(ref) {
    this.treatmentsService.getScheduleTimes(this._convertService.dateConvert(new Date().toUTCString(), "YYYY-MM-DDTHH:MM:SS")).subscribe((resp: string[]) => {
      //this.availableScheduleTime = resp;
      this.availableScheduleTime = [];
      for (let date of resp) {
        let iteratorDate = new Date(date);

        if (this.availableScheduleTime.find(x => x.every(t => new Date(t).getDate() == iteratorDate.getDate())) != null && this.availableScheduleTime.length != 0) {
          this.availableScheduleTime.find(x => x.every(t => new Date(t).getDate() == iteratorDate.getDate())).push(date);
          continue;
        }
        let arrayOfDate = [];
        arrayOfDate.push(date);
        this.availableScheduleTime.push(arrayOfDate);
      }
    });
    this.schedulePickUp = this.modalServide.open(ref, { panelClass: "lg-modal", closeOnNavigation: true });
  }

  closeScheduleModal() {
    this.schedulePickUp.close();
  }

  confirmSchedule() {

    let selectedDate: any[] = this.scheduleForm.controls.pickupDate.value;

    if (!this.scheduleForm.valid || selectedDate.length == 0) {
      this.snackBar.open(this._translocoService.translate("patient.GENERAL_INFO.scheduleTimeSelectError"), "X", {
        duration: 3000,
        panelClass: "warning",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
      return;
    }
    else {
      this.snackBar.open(this._translocoService.translate("patient.GENERAL_INFO.scheduleTimeSuccess"), "X", {
        duration: 3000,
        panelClass: "success",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
      this.closeScheduleModal();
    }

  }

  public clearFileHandle(side, fileName) {
    if (!this.fileToBeUploaded.find(x => x.fileName == fileName)) {
      this.nextPhaseForm.controls[side].setValue(null);
      this.nextPhaseForm.touched;
      //this.save(true);
      return;
    }
    this.fileToBeUploaded = this.fileToBeUploaded.filter(x => x.fileName !== fileName);
    this.nextPhaseForm.controls[side].setValue(null);
    this.nextPhaseForm.touched;
  }

  downloadFileHandle(fileName) {
    this._contentService.getContentFile(`t-${this.idTreatment}`, fileName).subscribe((resp: any) => {
      FileSaver(resp, fileName);
      this.cd.detectChanges();
    }, error => {
      this.snackBar.open(this._translocoService.translate("COMMON.errorDownloadFileMessage"), "X", {
        duration: 3000,
        panelClass: "warning",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
    });
  }

  async openFileHandle(fileName) {
    let resp: any = await this._contentService.getContentLink(`t-${this.idTreatment}`, fileName).toPromise();
    let image_window = window.open(resp.fullUrl, resp.fullUrl);
    image_window.document.write(`
     <html>
       <head>
       </head>
       <body>
         <img src="${resp.fullUrl}" width="50%">
       </body>
     </html>`);
  }

  public dropFileHandle(side, entity: { formData: FormData, fileName: string }) {
    this.nextPhaseForm.controls[side].setValue(entity.fileName);

    if (!this.fileToBeUploaded.includes(x => x.fileName == entity.fileName)) {
      let propName = entity.fileName.split('.')[0];
      propName = `${propName.charAt(0).toLocaleLowerCase()}${propName.slice(1)}`;
      this.fileToBeUploaded.push({ fileName: entity.fileName, formData: entity.formData, fieldName: propName });
    } else {
      for (let file of this.fileToBeUploaded) {
        if (file.fileName == entity.fileName) {
          file.formData = entity.formData;
        }
      }
    }
    this.nextPhaseForm.touched;
  }

  copied(value) {
    if (value.isSuccess) {
      this.snackBar.open(this._translocoService.translate("patient.copiedSuccess"), "X", {
        duration: 3000,
        panelClass: "success",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
    }
  }

  onScheduleTimeChecked(event, row) {
    let selectedDate: any[] = this.scheduleForm.controls.pickupDate.value;

    if (!event.checked) {
      selectedDate = selectedDate.filter(x => x != row);
      this.scheduleForm.controls.pickupDate.setValue(selectedDate);
      return;
    }

    if (selectedDate == null || selectedDate.length == 0) {
      selectedDate = [];
      selectedDate.push(row);
      this.scheduleForm.controls.pickupDate.setValue(selectedDate);
      return;
    }

    if (selectedDate.length == 2) {
      event.source._checked = false;
      this.snackBar.open(this._translocoService.translate("COMMON.allowedSelectedMaximumLimit"), "X", {
        duration: 3000,
        panelClass: "warning",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
      return;
    }

    if (new Date(row).getDate() != new Date(selectedDate[0]).getDate()) {
      event.source._checked = false;
      this.snackBar.open(this._translocoService.translate("COMMON.couldBeSelectedOnlySameDay"), "X", {
        duration: 3000,
        panelClass: "warning",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
      return;
    }

    selectedDate.push(row);
    this.scheduleForm.controls.pickupDate.setValue(selectedDate);
  }

  openExitWithoutSaveModal() {
    return Observable.create((observer: Observer<boolean>) => {
      let dialogRef = this.modalServide.open(TreatmentsNextPhaseModalComponent, {
        width: '500px',
        disableClose: true,
        data: { idTreatment: this.selectedTreatment.idTreatment }
      })
      dialogRef.afterClosed().subscribe(result => {
        console.log('res', result);
        observer.next(result);
        observer.complete();
      }, (error) => {
        observer.next(false);
        observer.complete();
      });
    });
  }

}

export function customNullValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return control.value == "null" ? { value: control.value } : null;
  }
}