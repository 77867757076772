import { HttpClient } from '@angular/common/http';
import { httpHeaders } from '../../../../environments/httpHeaders';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../modules/security/services/user.service";
const apiURL = environment.apiUrl;
const headers = httpHeaders.headers;
const fileHeader = httpHeaders.fileHeaders;
export class ContentsService {
    constructor(_http, _userService) {
        this._http = _http;
        this._userService = _userService;
    }
    getNewsContent(onlyActive = false) {
        const endPoint = "/api/news/" + onlyActive;
        return this._http.get(apiURL + endPoint, { headers: headers }).pipe(map((response) => {
            return response;
        }));
    }
    getNewsById(id) {
        const endPoint = "/api/news/id/" + id;
        return this._http.get(apiURL + endPoint, { headers: headers }).pipe(map((response) => {
            return response;
        }));
    }
    getShoppingContent(onlyActive = false) {
        const endPoint = "/api/shopping/" + onlyActive;
        return this._http.get(apiURL + endPoint, { headers: headers }).pipe(map((response) => {
            return response;
        }));
    }
    getShoppingById(id) {
        const endPoint = "/api/shopping/id/" + id;
        return this._http.get(apiURL + endPoint, { headers: headers }).pipe(map((response) => {
            return response;
        }));
    }
    deleteShoppingContent(idShopping) {
        const endPoint = "/api/shopping/" + idShopping;
        return this._http.delete(apiURL + endPoint, { headers: headers });
    }
    updateShoppingContent(shopping) {
        const endPoint = "/api/shopping/";
        return this._http.post(apiURL + endPoint, shopping, { headers: headers });
    }
    createShoppingContent(shopping) {
        const endPoint = "/api/shopping/";
        return this._http.put(apiURL + endPoint, shopping, { headers: headers }).pipe(map((resp) => {
            return resp;
        }));
    }
    createNewsContent(news) {
        const endPoint = "/api/news/";
        return this._http.put(apiURL + endPoint, news, { headers: headers }).pipe(map((resp) => {
            return resp;
        }));
    }
    deleteNewsContent(idNews) {
        const endPoint = "/api/news/" + idNews;
        return this._http.delete(apiURL + endPoint, { headers: headers });
    }
    updateNewsContent(news) {
        const endPoint = "/api/news/";
        return this._http.post(apiURL + endPoint, news, { headers: headers });
    }
    uploadContentFile(file, containerName) {
        const endPoint = "/api/storage";
        var formData = new FormData();
        formData.append("fileObject", file, file.name);
        formData.append("containerName", containerName);
        formData.append("createContainerIfNotExists", "false");
        return this._http.put(apiURL + endPoint, formData).pipe(map((resp) => {
            return resp;
        }));
    }
    uploadContentBlob(formData, containerName, createContainer = false) {
        const endPoint = "/api/storage";
        formData.append("createContainerIfNotExists", `${createContainer}`);
        formData.append("containerName", containerName);
        return this._http.put(apiURL + endPoint, formData, {
            reportProgress: true,
            observe: 'events',
        });
    }
    getContentFile(containerName, fileName) {
        const endPoint = "/api/storage/";
        var reques = {
            containerName: containerName ? containerName : "",
            fileName: fileName
        };
        return this._http.get(apiURL + endPoint, { params: reques,
            responseType: 'blob',
            observe: 'response',
            reportProgress: true }).pipe(map(resp => {
            return resp.body;
        }));
    }
    deleteContentFile(containerName, fileName) {
        const endPoint = "/api/storage/";
        var reques = {
            containerName: containerName ? containerName : "",
            fileName: fileName
        };
        return this._http.delete(apiURL + endPoint, { headers: headers, params: reques });
    }
    getPopupsForUser(id) {
        var endPoint = `/api/news/popups/${id}`;
        return this._http.get(`${apiURL}${endPoint}`, { headers: headers });
    }
    markAsRead(idNews, idAdmin) {
        var endPoint = `/api/news/popups/markasread/${idNews}/${idAdmin}`;
        return this._http.put(`${apiURL}${endPoint}`, null, { headers: headers });
    }
    getSupportClinicContent() {
        var endPoint = "/api/support/clinic";
        return this._http.get(`${apiURL}${endPoint}`, { headers: headers });
    }
    deleteClinicSupport(idClinicSupport) {
        var endPoint = `/api/support/clinic/${idClinicSupport}`;
        return this._http.delete(apiURL + endPoint, { headers: headers });
    }
    getClinicContentById(idClinicSupport) {
        var endPoint = `/api/support/clinic/${idClinicSupport}`;
        return this._http.get(apiURL + endPoint, { headers: headers });
    }
    createClinicContent(request) {
        var endPoint = `/api/support/clinic/`;
        return this._http.put(apiURL + endPoint, request, { headers: headers });
    }
    updateClinicContent(request) {
        var endPoint = `/api/support/clinic/`;
        return this._http.post(apiURL + endPoint, request, { headers: headers });
    }
    getContentLink(containerName, fileName) {
        const endPoint = "/api/storage/url";
        var reques = {
            containerName: containerName ? containerName : "",
            fileName: fileName
        };
        return this._http.get(apiURL + endPoint, { headers: headers, params: reques });
    }
    getWebViewerUrl(treatmentCode, idTreatment) {
        const endPoint = `/api/storage/getwebviewerurl/${treatmentCode}/${idTreatment}`;
        return this._http.get(`${apiURL}${endPoint}`, { headers: headers });
    }
    getWebViewerUrlFromFile(treatmentCode, idTreatment, videoFile) {
        const endPoint = `/api/storage/getwebviewerurlfromfile/${treatmentCode}/${idTreatment}/${videoFile}`;
        return this._http.get(`${apiURL}${endPoint}`, { headers: headers });
    }
    getWebViewerFiles(treatmentCode) {
        const endPoint = `/api/storage/getwebviewerfiles/${treatmentCode}`;
        return this._http.get(`${apiURL}${endPoint}`, { headers: headers });
    }
    verifyWebViewerUrl(treatmentCode) {
        const endPoint = `/api/storage/verifywebviewer/${treatmentCode}`;
        return this._http.get(`${apiURL}${endPoint}`, { headers: headers });
    }
    buy(request) {
        const endPoint = `/api/shopping/buy`;
        return this._http.post(`${apiURL}${endPoint}`, request, { headers: headers });
    }
    setContentHolderCountValue(container, value) {
        let currentUser = this._userService.readUserFromLocalStorage();
        currentUser[container] = value;
        this._userService.setUserToLocalStorage(currentUser);
    }
    getContentHolderCountValue(container) {
        let currentUser = this._userService.readUserFromLocalStorage();
        if (!currentUser) {
            return;
        }
        if (!currentUser[container]) {
            return 0;
        }
        if (currentUser[container] != undefined) {
            return currentUser[container];
        }
        return 0;
    }
}
ContentsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ContentsService_Factory() { return new ContentsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.UserService)); }, token: ContentsService, providedIn: "root" });
