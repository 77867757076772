import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { Router } from '@angular/router';
import { UsersService } from '../../../pages/users/users.service';
import { User } from '../../../modules/security/models/user.model';
import { UserService } from '../../../modules/security/services/user.service';
import { AuthenticationService } from '../../../pages/auth/authentication.service';
import { FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounce, debounceTime } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'elastic-toolbar-user-button',
  templateUrl: './toolbar-user-button.component.html',
  styleUrls: ['./toolbar-user-button.component.scss']
})
export class ToolbarUserButtonComponent implements OnInit, AfterViewInit, OnDestroy {

  isOpen: boolean;
  currentUser:User;
  dialogRef: MatDialogRef<LogOutConfirmModal>;
  @ViewChild('edtiCreateModal', {static:true}) edtiCreateModal:MatDialogRef<any>;
  @ViewChild('edtiCreatePhotoModal', {static:true}) edtiCreatePhotoModal:MatDialogRef<any>;
  @ViewChild('settingsModal', {static:true}) settingsModal:MatDialogRef<any>;
  @ViewChild('clinicalPreferences', {static:true}) clinicalPreferences:MatDialogRef<any>;  

  private _sub = new Subscription();

  public alignersPerDay:FormControl;
  public clinicPreferencesInput:FormControl;

  isLoading: boolean;
  showMyUsersMenuItem:boolean=false;

  constructor(
    private usersService:UsersService,
    private userService:UserService,
    private matDialog:MatDialog,
    private cd:ChangeDetectorRef,
    private router:Router,
    private snackBar: MatSnackBar,
    private _translocoService: TranslocoService,
    private route:Router
  ) { }
  
  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  ngOnInit() {
    this.currentUser = this.userService.readUserFromLocalStorage();
    this.showMyUsersMenuItem = this.currentUser.role == 'US' && 
                                  this.currentUser.userGroup != 'AD' &&
                                  this.currentUser.userGroup != 'PJ' &&
                                  this.currentUser.userGroup != 'ST';

    this.alignersPerDay = new FormControl();
    this.clinicPreferencesInput = new FormControl();

    this._sub = this.alignersPerDay.valueChanges.pipe(debounceTime(25)).subscribe(value => {
        value = value.toString().replace(/[^0-9]/g, "");
        this.alignersPerDay.setValue(value);
    })
    this.updateUserData();
  }

  ngAfterViewInit() {
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  onClickOutside() {
    this.isOpen = false;
  }

  openConfirmModal (){
    this.dialogRef = this.matDialog.open(LogOutConfirmModal, {
      closeOnNavigation:true
    });
  }

  openEditCreateModeal(templateRef){
    // this.edtiCreateModal = this.matDialog.open(templateRef, {
    //   closeOnNavigation:true, panelClass:"lg-modal"
    // });
    this.route.navigate(['/myprofile']);
  }

  openEditCreatePhotoModeal(templateRef){
    this.edtiCreatePhotoModal = this.matDialog.open(templateRef, {
      closeOnNavigation:true, panelClass:"lg-modal"
    });
  }

  openEditClinicalPreferences(templateRef){

    //get clinical Preferences for current Doctor
    this.isLoading = true;
    this.clinicPreferencesInput.setValue('');

    this.usersService.getClinicPreferences(this.currentUser.idAdmin).subscribe((resp:any) => {
      this.isLoading = false;
      this.clinicPreferencesInput.setValue(resp.clinicPreferences);

      this.clinicalPreferences = this.matDialog.open(templateRef, {
        closeOnNavigation:true, panelClass:"lg-modal", width: '600px', height: ''
      });

    }, error => {
      this.snackBar.open(this._translocoService.translate("COMMON.ErrorMessage"), "X", {
        duration: 3000,
        panelClass: "warning",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
    });

  }

  openSettingsModal(templateRef){
    this.isLoading = true;
    this.usersService.getSettings().subscribe((resp:any) => {
      this.alignersPerDay.setValue(resp.maxAlignersPerDay);
      this.isLoading = false;
    });

    this.settingsModal = this.matDialog.open(templateRef, {
      closeOnNavigation:true, panelClass:"sd-modal"
    });
  }

  closeSettings(){
    this.settingsModal.close();
  }

  closeClinicalPreferences(){
    this.clinicalPreferences.close();
  }

  saveClinicalPreferences(){
    let request = {
      "clinicPreferences": this.clinicPreferencesInput.value,
      "idDoctor": this.currentUser.idAdmin
    }
    this.isLoading = true;
    this.usersService.saveClinicPreferences(request).subscribe(resp => {
      this.isLoading = false;
      this.snackBar.open(this._translocoService.translate("toolbar.updateSuccessMessage"), "X", {
        duration: 3000,
        panelClass: "success",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
      this.closeClinicalPreferences();
    }, error => {
      this.isLoading = false;
      this.closeClinicalPreferences();
      this.snackBar.open(this._translocoService.translate("COMMON.ErrorMessage"), "X", {
        duration: 3000,
        panelClass: "warning",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
    });
  }

  saveDays(){
    let request = {
      "maxAlignersPerDay": +this.alignersPerDay.value
    }
    this.isLoading = true;
    this.usersService.saveSettings(request).subscribe(resp => {
      this.isLoading = false;
      this.snackBar.open(this._translocoService.translate("toolbar.updateSuccessMessage"), "X", {
        direction: "rtl",
        duration: 3000,
        panelClass: "success",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
      this.closeSettings();
    }, error => {
      this.isLoading = false;
      this.closeSettings();
      this.snackBar.open(this._translocoService.translate("COMMON.ErrorMessage"), "X", {
        direction: "rtl",
        duration: 3000,
        panelClass: "warning",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
    });
  }
  
  createUpdateHandler(){
    this.edtiCreateModal.close();
  }

  createUpdatePhotoHandler(value:{"updated":boolean, "fileName":string, "closeModal":boolean}){
    if(value.closeModal){
      this.edtiCreatePhotoModal.close();
    }

    if(value.updated){
      this.updateUserData(true);
    }
    
    this.updateUserData();
  }

  updateUserData(updateStorage:boolean = false) {
    
    this.usersService.getUserById(this.userService.readUserFromLocalStorage().idAdmin).subscribe((resp:User) => {
      this.currentUser = new User(resp);
      if(updateStorage){
        this.userService.setUserToLocalStorage(this.currentUser);
      }
      this.cd.detectChanges();
    })
  }

  redirectTo(link, params?:any){
    if(params){
      this.router.navigate([link], {queryParams:params});
      return;
    }
    this.router.navigate([link]);
  }
  
}


@Component({
  selector:"logout-confirm-modal",
  template:`
  <mat-card-content fxLayout="column" fxLayoutAlign="center center">
    <span>
      {{'toolbar.USERCOMPONENT.confirmMessage' | transloco}}
    </span>
  </mat-card-content>
  <mat-card-actions >
    <div fxLayout="row" style="padding-top:25px;">
      <button mat-raised-button class="btn-default" (click)="logOut()">
        {{'toolbar.USERCOMPONENT.confirmButton' | transloco}}
      </button>
      <span fxFlex></span>
      <button mat-raised-button color="warn" (click)="dialogRef.close()">
        {{'toolbar.USERCOMPONENT.declineButton' | transloco}}
      </button>
    </div>
  </mat-card-actions>
  `
})
export class LogOutConfirmModal{
  constructor(public dialogRef:MatDialogRef<LogOutConfirmModal>,
    private authService:AuthenticationService,
    private router:Router
    ){}

  async logOut(){
    await this.authService.logOutUser();
    this.dialogRef.close();
    this.router.navigate(["/"]);
  }
}