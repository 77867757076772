import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'drag-and-drop-file',
  templateUrl: './drag-and-drop-file.component.html',
  styleUrls: ['./drag-and-drop-file.component.scss']
})
export class DragAndDropFileComponent implements OnInit  {
  @ViewChild('deleteImageConfirmation', {static:true}) deleteImageConfirmation:MatDialogRef<any>;

  file:File;

  @Input() tagId:string;
  @Input() required:boolean;
  @Input() displayName:string;
  @Input() imgUrl:string;
  @Input() fileName:string
  @Input() acceptedFileType:string;
  @Input() fileArePresent:boolean = false;
  @Input() disabled:boolean;
  @Input() maxFileSize:number;
  @Input() allowDelete:boolean = true;

  @Output() fileDropped = new EventEmitter<any>();
  @Output() fileDeleted = new EventEmitter<any>();
  @Output() downloadFileTrigger = new EventEmitter<string>();
  @Output() openFileTrigger = new EventEmitter<string>();
  
  @Input() idTreatment:number;
  @Input() storageContainerFolder:string = 't';

  storageBaseUrl: string = environment.storageBaseUrl;

  public isMobile:boolean = false;
  fileBlob: any;
  extention: string;
  fileExtension: string;
  fullImageUrl:string;
  fullImageUrlPreview:string;

  public isDownloadingFile: boolean = false;

  constructor(
    private _breakpointObserver:BreakpointObserver,
    private sanitizer:DomSanitizer,
    private cd: ChangeDetectorRef,
    private modalServide:MatDialog,
    private snackBar:MatSnackBar,
    private _translocoService:TranslocoService
  ){
    this._breakpointObserver.observe([
      Breakpoints.Handset
    ]).subscribe(result => {
      this.isMobile = result.matches;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    //update file preview after uploading image
    if(this.fileName){
      this.fileExtension = "";
      if (this.idTreatment!=undefined && this.acceptedFileType != '.stl' && this.acceptedFileType != '.zip' && this.acceptedFileType != '.pdf')
      {
        this.fullImageUrl = this.storageBaseUrl + '/' + this.storageContainerFolder + '-' + this.idTreatment + '/' + this.fileName;
        this.fullImageUrlPreview = this.fullImageUrl + '?ts=' + Math.floor(Date.now() / 1000);
      }
      else{
        this.fullImageUrl ='';
        this.fullImageUrlPreview ='';
      }        
    }
  }

  ngOnInit(): void {
    if(!this.acceptedFileType){
      this.acceptedFileType = "image/*";
    }

    this.fileExtension = this.acceptedFileType;

    if(this.fileName){
      this.fileExtension = "";
      if (this.idTreatment!=undefined && this.acceptedFileType != '.stl' && this.acceptedFileType != '.zip'&& this.acceptedFileType != '.pdf'){
        this.fullImageUrl = this.storageBaseUrl + '/' + this.storageContainerFolder + '-' + this.idTreatment + '/' + this.fileName;
        this.fullImageUrlPreview = this.fullImageUrl + '?ts=' + Math.floor(Date.now() / 1000);
      }        
      else{
        this.fullImageUrl ='';
        this.fullImageUrlPreview = '';
      }        
    }
  }
  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  downloadFile(){
    if (this.fileName=='lower_arch_scan.stl' || this.fileName=='upper_arch_scan.stl')
      this.isDownloadingFile=true;
    this.downloadFileTrigger.emit(this.fileName);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   */
  deleteFile() {
    this.file = null;
    this.fileDeleted.emit(this.fileArePresent ? this.fileName : `${this.fileName}${this.fileExtension}`);
    this.closeDeleteModal();
  }

  /**
   * Delete file from files list without update the DB
   */
   deleteFileNotUploadedYet() {
    this.file = null;
    this.fileDeleted.emit(this.fileArePresent ? this.fileName : `${this.fileName}${this.fileExtension}`);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    let customFile;
    for (const item of files) {
      if(this.maxFileSize && this.maxFileSize < item.size){
        this.snackBar.open(this._translocoService.translate("COMMON.maxSizeOverlapError"), "X", {
          direction:"rtl",
          duration:3000,
          panelClass:"warning",
          horizontalPosition:"right",
          verticalPosition:"top",
        } as MatSnackBarConfig);
        return;
      }
  
      item.progress = 0;
      this.file = item;
      customFile = this.file;
      this.extention = this.file.name.split(".").pop();
    }
    if(!this.file){
      return;
    }
   
    const reader = new FileReader();
    let filePath = null;
    reader.onload = () => {
      filePath = reader.result as string;
      this.notifyParent(filePath, customFile);
    }

    reader.readAsDataURL(files[0]);

    
    // this.prepareBlob(this.file, (res) => {
    //   this.fileBlob = res;
    //   this.cd.detectChanges();
    // })
  }

  notifyParent(filePath:string, file) {
    var formData = new FormData();

    formData.append("fileObject", file, `${this.fileName}.${this.extention}`);
    var entity = {
      formData: formData,
      fileName: `${this.fileName}.${this.extention}`,
      photoUrl: filePath
    }
    this.fileDropped.emit(entity);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 0) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    let result =  parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    return result;
  }

  prepareBlob(file, cb:Function){
    this.convertBlobToBase64(file).then((base64:string) => {
      var imageBlob = this.sanitizer.bypassSecurityTrustUrl(base64);
      cb(imageBlob);
    })
  }

  convertBlobToBase64 = blob => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    }
    reader.readAsDataURL(blob);
  });

  deleteFileModal(tempalteRef){
    this.deleteImageConfirmation = this.modalServide.open(tempalteRef, {closeOnNavigation:true})
  }

  closeDeleteModal(){
    if(this.deleteImageConfirmation.close){
      this.deleteImageConfirmation.close();
    }
  }

  isAllowToRenderOpenImage(){
    //return this.fileArePresent && (this.acceptedFileType && this.acceptedFileType.startsWith("image"));
    return false;
  }

  openImage(){
    this.openFileTrigger.emit(this.fileName);
  }

}
