import * as tslib_1 from "tslib";
import { Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { httpHeaders } from '../../../../environments/httpHeaders';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const apiUrl = environment.apiUrl;
const headers = httpHeaders.headers;
export class TreatmentAdditionalFunctionalService {
    constructor(_http) {
        this._http = _http;
        this.areValueWasChanged = true;
        this.isLowerAbleToShow = true;
        this.isUpperAbleToShow = true;
        this.isTabsContentDisabled$ = new Subject();
        this.isTabsContentDisabled = this.isTabsContentDisabled$.asObservable();
    }
    archesToHandleChangeHandler(value) {
        if (value == "N" || value == "B") {
            this.isUpperAbleToShow = true;
            this.isLowerAbleToShow = true;
            return;
        }
        if (value == "L") {
            this.isLowerAbleToShow = true;
            this.isUpperAbleToShow = false;
        }
        else {
            this.isLowerAbleToShow = false;
            this.isUpperAbleToShow = true;
        }
    }
    initService(treatmentId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.treatmentId = treatmentId;
            this.treatmentPlan = yield this._http.get(`${apiUrl}/api/treatmentplan/${treatmentId}`, { headers: headers }).toPromise();
            this.archesToHandleChangeHandler(this.treatmentPlan.treatmentPlan.archesHandled);
        });
    }
    getPlanField(fieldName) {
        return this.treatmentPlan ? this.treatmentPlan[fieldName] : null;
    }
    setpAlignersOnPhase(upperA, lowerA) {
        if (upperA > 0) {
            this.treatmentPlan.phase.alignersUpperArch = upperA > 12 ? 12 : upperA;
        }
        if (lowerA > 0) {
            this.treatmentPlan.phase.alignersLowerArch = lowerA > 12 ? 12 : lowerA;
            ;
        }
    }
    setPlanField(fieldName, field) {
        this.treatmentPlan[fieldName] = field;
    }
    updateShowPlanDelay(newvalue) {
        this.treatmentPlan.treatmentPlan.showPlanDelay = newvalue;
    }
    updateShowPlanComplex(newvalue) {
        this.treatmentPlan.treatmentPlan.showPlanComplex = newvalue;
    }
    updateShowPlanCategories(newvalue) {
        this.treatmentPlan.treatmentPlan.showPlanCategories = newvalue;
    }
    getTreatmentPlan() {
        return this.treatmentPlan;
    }
    changePanelContentStatus(value) {
        this.isTabContentDisabledValue = value;
        this.isTabsContentDisabled$.next(value);
    }
    getIsTabContentDisabledValue() {
        return this.isTabContentDisabledValue;
    }
    phaseValueChanged(arg0) {
        this.areValueWasChanged = arg0;
    }
}
TreatmentAdditionalFunctionalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TreatmentAdditionalFunctionalService_Factory() { return new TreatmentAdditionalFunctionalService(i0.ɵɵinject(i1.HttpClient)); }, token: TreatmentAdditionalFunctionalService, providedIn: "root" });
