<div class="page-chat" fxLayout="column">
    <div class="card" fxFlex fxLayout="column">
        <div class="chat-inner-container" fxLayout="column">
            <div class="chat-content" fxFlex fxLayout="column" fxLayoutAlign="start stretch">
                <elastic-scrollbar name="chat" #scroll fxFlex>
                    <div class="messages-container">
                        <div class="chat-message" *ngFor="let message of messages" fxLayout="column"
                            fxLayoutAlign="end start">
                            <div class="partner" *ngIf="message.whoId != currentUser.idAdmin" fxFlexAlign="start"
                                fxLayout="column" fxLayoutAlign="start start">
                                <small class="fullName leftSideMessage">{{message.whoFullName}}</small>
                                <div fxLayout="row">
                                    <img class="avatar" [src]="showProfileAvatr(message.whoPhoto)">
                                    <span class="message mat-elevation-z1">
                                        <!-- <div style="margin-bottom: 20px;">{{message.message}}</div> -->
                                        <div [innerHTML]="message.message" style="margin-bottom: 20px;"></div>
                                        <div
                                            style=" text-align:right; position: absolute; bottom: 5px; right: 5px; font-size: 70%; color: gray;">
                                            {{message.creationDate | date:'dd/MM/yy HH:mm'}}
                                        </div>
                                    </span>
                                </div>

                            </div>
                            <div class="me" *ngIf="message.whoId == currentUser.idAdmin" fxFlexAlign="end"
                                fxLayout="column" fxLayoutAlign="end start">

                                <div fxFlexAlign="end" fxLayout="row">
                                    <small fxFlexAlign="end" fxLayoutAlign="end"
                                        class="fullName rightSideMessage">{{message.whoFullName}}</small>
                                </div>
                                <div fxLayout="row">
                                    <span class="message mat-elevation-z1">
                                        <!-- <div style="margin-bottom: 20px;">{{message.message}}</div> -->
                                        <div [innerHTML]="message.message" style="margin-bottom: 20px;"></div>
                                        <div
                                            style=" text-align:right; position: absolute; bottom: 5px; right: 5px; font-size: 70%; color: gray;">
                                            {{message.creationDate | date:'dd/MM/yy HH:mm'}}
                                        </div>
                                    </span>
                                    <img class="avatar" [src]="showProfileAvatr(message.whoPhoto)">
                                </div>

                            </div>
                        </div>
                    </div>
                    <div #scrollToBottomElem></div>
                </elastic-scrollbar>

                <div *ngIf="!forceDisabeNewChatMessages">
                    <div class="chat-respond" fxLayout="row" fxLayoutAlign="start center" *ngIf="isAbleToSend()">
                        <mat-form-field fxFlex>
                            <!-- <input matInput (keyup.enter)="send()" [(ngModel)]="newMessage" maxlength="500"
                                placeholder="{{'patient.ADDITIONAL.chatPlaceHolder' | transloco}}"> -->
                            <textarea [(ngModel)]="newMessage" maxlength="500" rows="4" matInput
                                placeholder="{{'patient.ADDITIONAL.chatPlaceHolder' | transloco}}"></textarea>
                        </mat-form-field>
                        <div *ngIf="allowUploadImages">
                            <input type="file" accept=".png, .jpg, .jpeg" #chatImageUpload style="display: none;"
                                (change)="fileBrowseHandler($event.target.files)">
                            <button (click)="chatImageUpload.click()" mat-fab color="secondary">
                                <mat-icon>attach_file</mat-icon>
                            </button>
                        </div>
                        <button (click)="send()" mat-fab color="primary">
                            <mat-icon>send</mat-icon>
                        </button>
                        <button (click)="showChatSentences(chatSentencesModal)" mat-fab color="accent"
                            *ngIf="chatType == 'T' && currentUser.role == 'US'">
                            <mat-icon>textsms</mat-icon>
                        </button>
                        <button (click)="showNotificationModal(sendNotificationModal)" mat-fab color="warn"
                            *ngIf="chatType == 'T' && currentUser.role == 'US'">
                            <mat-icon>notifications_active</mat-icon>
                        </button>
                    </div>
                    <div class="chat-respond" fxLayout="row" fxLayoutAlign="start center" *ngIf="!isAbleToSend()">
                        <span *ngIf="chatType == 'T'">{{'patient.ADDITIONAL.chatDisabledText' | transloco}}</span>
                        <span *ngIf="chatType == 'Q' || chatType == 'I'">{{'patient.QUOTES.noChatMessagesAllowed' |
                            transloco}}</span>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>

<ng-template #sendNotificationModal>
    <mat-card-content fxLayout="column" fxLayoutAlign="center center">
      <span>
        {{"patient.sendChatNotificationMessage" | transloco}}
      </span>
    </mat-card-content>
    <mat-card-actions>
      <div fxLayout="row" style="padding-top:25px;" fxLayoutGap="16px" fxLayoutAlign="center center">
        <button mat-raised-button color='warn' (click)="closeSendNotificationModal()">
          {{ 'COMMON.NoButton' | transloco}}
        </button>
        <button mat-raised-button class="btn-default" (click)="sendNotification()">
          {{ 'COMMON.ConfirmButton' | transloco}}
        </button>
      </div>
    </mat-card-actions>
  </ng-template>

<ng-template #chatSentencesModal>
    <mat-card-content fxLayout="column" fxLayoutAlign="center center">
        <span>
            <strong>{{"patient.ADDITIONAL.ChatTemplateSelectionTitle" | transloco}}</strong>
        </span>
        <div style="margin-top: 10px;width: 100%;">
            <select [(ngModel)]="chatSentenceCategorySelected" (change)="loadChatTemplate($event.target.value)" style="width: 100%;">
                <option value="0">{{'patient.ADDITIONAL.ChatTemplateSelectionCategory' | transloco}}</option>
                <option value="1">{{'patient.ADDITIONAL.ChatTemplateTitle1' | transloco}}</option>
                <option value="2">{{'patient.ADDITIONAL.ChatTemplateTitle2' | transloco}}</option>
                <option value="3">{{'patient.ADDITIONAL.ChatTemplateTitle3' | transloco}}</option>
                <option value="4">{{'patient.ADDITIONAL.ChatTemplateTitle4' | transloco}}</option>
                <option value="5">{{'patient.ADDITIONAL.ChatTemplateTitle5' | transloco}}</option>
                <option value="6">{{'patient.ADDITIONAL.ChatTemplateTitle6' | transloco}}</option>
                <option value="7">{{'patient.ADDITIONAL.ChatTemplateTitle7' | transloco}}</option>
            </select>
        </div>

        <div style="margin-top: 10px;width: 100%;" *ngIf="chatSentenceCategorySelected == 1">
            <a href="javascript:void(0);" (click)="doChooseSentence(item)" class="btn btn-default text-wrap"
                style="width: 100%;margin-bottom: 2px;" *ngFor="let item of chatSentences1">
                <span *ngIf="currentLanguage == 'it'" [innerHTML]="item.sentence_it"></span>
                <span *ngIf="currentLanguage == 'en'" [innerHTML]="item.sentence_en"></span>
            </a>
        </div>
        <div style="margin-top: 10px;width: 100%;" *ngIf="chatSentenceCategorySelected == 2">
            <a href="javascript:void(0);" (click)="doChooseSentence(item)" class="btn btn-default text-wrap"
                style="width: 100%;margin-bottom: 2px;" *ngFor="let item of chatSentences2">
                <span *ngIf="currentLanguage == 'it'" [innerHTML]="item.sentence_it"></span>
                <span *ngIf="currentLanguage == 'en'" [innerHTML]="item.sentence_en"></span>
            </a>
        </div>
        <div style="margin-top: 10px;width: 100%;" *ngIf="chatSentenceCategorySelected == 3">
            <a href="javascript:void(0);" (click)="doChooseSentence(item)" class="btn btn-default text-wrap"
                style="width: 100%;margin-bottom: 2px;" *ngFor="let item of chatSentences3">
                <span *ngIf="currentLanguage == 'it'" [innerHTML]="item.sentence_it"></span>
                <span *ngIf="currentLanguage == 'en'" [innerHTML]="item.sentence_en"></span>
            </a>
        </div>
        <div style="margin-top: 10px;width: 100%;" *ngIf="chatSentenceCategorySelected == 4">
            <a href="javascript:void(0);" (click)="doChooseSentence(item)" class="btn btn-default text-wrap"
                style="width: 100%;margin-bottom: 2px;" *ngFor="let item of chatSentences4">
                <span *ngIf="currentLanguage == 'it'" [innerHTML]="item.sentence_it"></span>
                <span *ngIf="currentLanguage == 'en'" [innerHTML]="item.sentence_en"></span>
            </a>
        </div>
        <div style="margin-top: 10px;width: 100%;" *ngIf="chatSentenceCategorySelected == 5">
            <a href="javascript:void(0);" (click)="doChooseSentence(item)" class="btn btn-default text-wrap"
                style="width: 100%;margin-bottom: 2px;" *ngFor="let item of chatSentences5">
                <span *ngIf="currentLanguage == 'it'" [innerHTML]="item.sentence_it"></span>
                <span *ngIf="currentLanguage == 'en'" [innerHTML]="item.sentence_en"></span>
            </a>
        </div>
        <div style="margin-top: 10px;width: 100%;" *ngIf="chatSentenceCategorySelected == 6">
            <a href="javascript:void(0);" (click)="doChooseSentence(item)" class="btn btn-default text-wrap"
                style="width: 100%;margin-bottom: 2px;" *ngFor="let item of chatSentences6">
                <span *ngIf="currentLanguage == 'it'" [innerHTML]="item.sentence_it"></span>
                <span *ngIf="currentLanguage == 'en'" [innerHTML]="item.sentence_en"></span>
            </a>
        </div>
        <div style="margin-top: 10px;width: 100%;" *ngIf="chatSentenceCategorySelected == 7">
            <a href="javascript:void(0);" (click)="doChooseSentence(item)" class="btn btn-default text-wrap"
                style="width: 100%;margin-bottom: 2px;" *ngFor="let item of chatSentences7">
                <span *ngIf="currentLanguage == 'it'" [innerHTML]="item.sentence_it"></span>
                <span *ngIf="currentLanguage == 'en'" [innerHTML]="item.sentence_en"></span>
            </a>
        </div>
        <div style="margin-top: 10px;width: 100%;" *ngIf="chatSentenceCategorySelected == 8">
            <a href="javascript:void(0);" (click)="doChooseSentence(item)" class="btn btn-default text-wrap"
                style="width: 100%;margin-bottom: 2px;" *ngFor="let item of chatSentences8">
                <span *ngIf="currentLanguage == 'it'" [innerHTML]="item.sentence_it"></span>
                <span *ngIf="currentLanguage == 'en'" [innerHTML]="item.sentence_en"></span>
            </a>
        </div>

    </mat-card-content>
    <mat-card-actions>


        <div fxLayout="row" style="padding-top:25px;" fxLayoutGap="16px" fxLayoutAlign="center center">
            <button mat-raised-button class="mat-primary" (click)="closeChatSentencesModal()">
                {{ 'COMMON.CloseButton' | transloco}}
            </button>
        </div>
    </mat-card-actions>
</ng-template>