import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from 'src/app/modules/security/models/user.model';
import { UserService } from 'src/app/modules/security/services/user.service';
import { version } from "../../../../../package.json";
import { environment } from '../../../../environments/environment';

declare function renderTawkChat(): any;    //js function to show Tawk Chat widget

@Component({
  selector: 'elastic-toolbar-gamma',
  templateUrl: './toolbar-gamma.component.html',
  styleUrls: ['./toolbar-gamma.component.scss']
})
export class ToolbarGammaComponent implements OnInit {

  
  nuvolaUser:User;

  @Input() quickpanelOpen: boolean;

  @Output() toggledSidenav = new EventEmitter();
  @Output() toggledQuickpanel = new EventEmitter();

  public version:string;
  public environmentName : string = "";

  constructor(
    private cd:ChangeDetectorRef,
    private userService:UserService,
  ) { }

  ngOnInit(
  ) {
    this.nuvolaUser = this.userService.readUserFromLocalStorage();    
    if (this.nuvolaUser!=null && this.nuvolaUser.role == 'DO')
      renderTawkChat();
    
    this.cd.detectChanges();
    this.version = version;
    if (environment.api.base.includes('apidev') || environment.api.base.includes('preview.nuvolaclient.eu')){
      this.environmentName ='DEV';
    }
    else if (environment.api.base.includes('apipre')){
      this.environmentName ='PREPROD';
    } 
    else {
      this.environmentName ='';
    }   
  }

  isDisabled(){
    if(!this.nuvolaUser.profileComplete){
      return true;
    } else {
      return false;
    }
  }


  toggleSidenav() {
    this.toggledSidenav.emit();
  }

  toggleQuickpanel() {
    this.toggledQuickpanel.emit();
  }
}
