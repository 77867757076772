import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private user = new BehaviorSubject<User>(null);
  constructor() { this.readUserFromLocalStorage();}
  
  public readUserFromLocalStorage(){
    const user:User = JSON.parse(localStorage.getItem("nuvolaUser") || null);
    return user;
  }

  public setUserToLocalStorage(user:User){
    let token = localStorage.getItem("nuvola_session_token");
    let refresh = localStorage.getItem("nuvola_refresh_token");
    user.accessToken = token;
    user.refreshToken = refresh;

    localStorage.setItem("nuvolaUser", JSON.stringify(user));
  }
}
