<div *ngIf="isEditLoading"
  fxLayout="row" fxLayoutAlign="center center"
  >
    <mat-progress-spinner
    class="margin"
    [mode]="'indeterminate'"
    [color]="'accent'"
    [value]="10"
    >
    </mat-progress-spinner>
  </div>
  <form fxLayout="column" [formGroup]="userForm" (ngSubmit)="save()" *ngIf="!isEditLoading">
    <mat-card fxFlex="100%" fxFlex.gt-sm>
      <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
      </div>

      <div fxLayout="row" style="padding-bottom: 25px;" *ngIf="selectedRow && !selectedRow.profileComplete && currentLoggedInUser.role == 'DO'">
        <div class="widget" fxFlex="auto" fxFlex.gt-xs *ngIf="!profileComplete">
          <nuvolaweb-treatment-resons  property="{{'REGISTRATION.ProfileIncompleteWarningTitle' | transloco}}" icon="warning"
            value="{{'REGISTRATION.ProfileIncompleteWarningAbstract' | transloco}}"
            backgroundColor="linear-gradient(0.1turn, #e4ba4e, #ffec5b)" textColor="white"></nuvolaweb-treatment-resons>
        </div>
      </div>

      <div fxLayout.gt-sm="row" fxLayoutGap.gt-sm="16px">
        <mat-form-field fxFlex>
          <input maxlength="50" matInput placeholder="{{'REGISTRATION.LastNamePlaceHolder' | transloco}}" type="text" required formControlName="lastName">
        </mat-form-field>
        <mat-form-field fxFlex>
          <input maxlength="50" matInput placeholder="{{'REGISTRATION.FirstNamePlaceHolder' | transloco}}" type="text" required formControlName="firstName"> 
        </mat-form-field>
        <mat-slide-toggle formControlName="active" fxFlex required *accessControl="{id:['AD','EX']}">
          {{'users.activeLabel' | transloco}}
        </mat-slide-toggle>
        <button type="button" mat-raised-button class="btn-default resetPasswordButton" fxFlex 
            (click)="openSendEmailModal(sendEmailModal)" *ngIf="showActiveDoctorButton" >
          {{'users.activeDoctorButton' | transloco}}
        </button>
      </div>
      <div fxLayout.gt-sm="row" fxLayoutGap.gt-sm="16px">
        <mat-form-field fxFlex fxFlex.gt-sm="32.3%">
          <input maxlength="190" matInput placeholder="Email" type="email" required formControlName="email">
        </mat-form-field>
        <button type="button" mat-raised-button class="btn-default resetPasswordButton" fxFlex (click)="openResetModalConfirm(resetPasswordModal)" *ngIf="selectedRow">
          {{'users.resetPasswordButton' | transloco}}
        </button>
        <div fxLayout="column">
          <mat-form-field fxFlex *ngIf="!selectedRow">
            <input matInput placeholder="Password" [type]="hide? 'password' : 'text'" required formControlName="password">
            <button mat-icon-button matSuffix (click)="hide = !hide" type="button" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button> 
          </mat-form-field>
        </div>
        <mat-form-field fxFlex>
          <mat-label>{{'users.languageLabel' | transloco}}</mat-label>
          <mat-select formControlName="language" aria-required="true" required>
            <!-- <mat-option value="null">
             {{'users.selectLanguageOption' | transloco}}
            </mat-option> -->
            <mat-option value="en">
              English
            </mat-option>
            <mat-option value="it">
              Italian
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex>
          <mat-label>{{'users.rolesLabel' | transloco}}</mat-label>
          <mat-select formControlName="role" aria-required="true" required (selectionChange)="onRoleChange()">
            <!-- <mat-option value="null">
              {{'users.selectRoleOption' | transloco}}
            </mat-option> -->
            <div *ngFor="let role of roles">
              <mat-option value="{{role.id}}">
                {{role.value}}
              </mat-option>
            </div>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="userForm.controls.role.value == 'EX'">
        <div fxLayout.gt-sm="row" fxLayoutGap.gt-sm="16px">
          <mat-form-field fxFlex="32%">
            <mat-label>{{'users.areaLabel' | transloco}}</mat-label>
            <mat-select formControlName="executiveArea" aria-required="true" required>
              <!-- <mat-option value="null">
                {{'users.selectAreaOption'|transloco}}
              </mat-option> -->
              <div *ngFor="let area of areas">
                <mat-option value="{{area.id}}">
                  {{area.value}}
                </mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="userForm.controls.role.value == 'AG' || userForm.controls.role.value=='DI'">
        <div fxLayout.gt-sm="row" fxLayoutGap.gt-sm="16px">
          <mat-form-field fxFlex="32%" *ngIf="superAgents">
            <mat-label>{{'users.superAgentLabel' | transloco}}</mat-label>
            <mat-select formControlName="idSuperAgent" aria-required="true" required>
              <mat-option value="0">
                {{'COMMON.NAValueOption' | transloco}}
              </mat-option>
              <div *ngFor="let agent of superAgents">
                <mat-option value="{{agent.idAdmin}}">
                  {{agent.lastName}} {{agent.firstName}}
                </mat-option>
              </div>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="32%" *ngIf="distributors">
            <mat-label>{{'users.distributorLabel'|transloco}}</mat-label>
            <mat-select formControlName="idDistributor" aria-required="true" required>
              <mat-option value="0">
                {{'COMMON.NAValueOption' | transloco}}
              </mat-option>
              <div *ngFor="let distr of distributors">
                <mat-option value="{{distr.idAdmin}}">
                  {{distr.lastName}} {{distr.firstName}}
                </mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="userForm.controls.role.value == 'DO' || userForm.controls.role.value == 'AG'">
       
        <div fxLayout.gt-sm="row" fxLayoutGap.gt-sm="16px" *ngIf="userForm.controls.role.value == 'DO'">
          <mat-form-field fxFlex="32%">
            <input maxlength="20" matInput placeholder="{{'REGISTRATION.VatNumberPlaceHolder' | transloco}}" formControlName="vat" required>
          </mat-form-field>
          <mat-form-field fxFlex fxFlex.gt-sm="32%">
            <mat-label>{{'REGISTRATION.agentLabel' | transloco}}</mat-label>
            <mat-select formControlName="idAgent" aria-required="true" required >
              <mat-option value="0">
                {{'COMMON.NAValueOption' | transloco}}
              </mat-option>
              <div *ngFor="let agent of agents">
                <mat-option value="{{agent.idAdmin}}">
                  {{agent.lastName}} {{agent.firstName}}
                </mat-option>
              </div>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex fxFlex.gt-sm="32%">
            <mat-label>{{'REGISTRATION.userLabel' | transloco}}</mat-label>
            <mat-select formControlName="idUser" aria-required="true" required >
              <mat-option value="0">
                {{'COMMON.NAValueOption' | transloco}}
              </mat-option>
              <div *ngFor="let user of users">
                <mat-option value="{{user.idAdmin}}">
                  {{user.lastName}} {{user.firstName}}
                </mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout.gt-sm="row" fxLayoutGap.gt-sm="16px" *ngIf="userForm.controls.role.value == 'DO'">
          <mat-form-field fxFlex fxFlex.gt-sm="25%">
            <mat-select matInput placeholder="{{'REGISTRATION.question1' | transloco}} *" 
                formControlName="question1" (selectionChange)="toggleAligners($event)">
              <mat-option value="B">{{'REGISTRATION.questionEmpty' | transloco}}</mat-option>
              <mat-option value="N">{{'REGISTRATION.questionNo' | transloco}}</mat-option>
              <mat-option value="Y">{{'REGISTRATION.questionYes' | transloco}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex fxFlex.gt-sm="25%" *ngIf="showHowManyPatients">
            <mat-select matInput placeholder="{{'REGISTRATION.question1.1' | transloco}} *" formControlName="question11">
              <mat-option value="1">{{'REGISTRATION.question1.1.1' | transloco}}</mat-option>
              <mat-option value="2">{{'REGISTRATION.question1.1.2' | transloco}}</mat-option>
              <mat-option value="3">{{'REGISTRATION.question1.1.3' | transloco}}</mat-option>
              <mat-option value="4">{{'REGISTRATION.question1.1.4' | transloco}}</mat-option>
            </mat-select>
          </mat-form-field>
          <!-- <mat-form-field fxFlex fxFlex.gt-sm="25%">
            <mat-select matInput placeholder="{{'REGISTRATION.question2' | transloco}} *" formControlName="question2">
              <mat-option value="B">{{'REGISTRATION.questionEmpty' | transloco}}</mat-option>
              <mat-option value="N">{{'REGISTRATION.questionNo' | transloco}}</mat-option>
              <mat-option value="Y">{{'REGISTRATION.questionYes' | transloco}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex fxFlex.gt-sm="25%">
            <mat-select matInput placeholder="{{'REGISTRATION.question3' | transloco}} *" formControlName="question3">
              <mat-option value="B">{{'REGISTRATION.questionEmpty' | transloco}}</mat-option>
              <mat-option value="N">{{'REGISTRATION.questionNo' | transloco}}</mat-option>
              <mat-option value="Y">{{'REGISTRATION.questionYes' | transloco}}</mat-option>
            </mat-select>
          </mat-form-field> -->
        </div>
        <div fxLayout.gt-sm="row" fxLayoutGap.gt-sm="16px" style="margin-bottom: 20px;" *ngIf="currentLoggedInUser.role == 'DO'">
          <div fxFlex fxFlex.gt-sm="25%">
            <mat-checkbox formControlName="privacyFlag" fxFlex required [style.color]="userForm.controls.privacyFlag.value == true ? 'black' : 'red'">
                {{'REGISTRATION.privacyPolicyToogle' | transloco}} <a href="{{storageBaseUrl}}/policies/GEO-NUV-Privacy-22_{{currentLanguage}}.pdf" target="_blank">{{'REGISTRATION.privacyPolicyLink' | transloco}}</a> *
            </mat-checkbox>              
          </div>
          <div fxFlex fxFlex.gt-sm="25%">
            <mat-checkbox formControlName="cookieFlag" fxFlex required [style.color]="userForm.controls.cookieFlag.value == true ? 'black' : 'red'">
              {{'REGISTRATION.cookiePolicyToggle' | transloco}}<a href="{{storageBaseUrl}}/policies/GEO-NUV-CP-22_{{currentLanguage}}.pdf" target="_blank">{{'REGISTRATION.cookiePolicyLink' | transloco}}</a> *
            </mat-checkbox>
          </div>
          <div fxFlex fxFlex.gt-sm="25%">
            <mat-checkbox formControlName="temrsFlag" fxFlex required [style.color]="userForm.controls.temrsFlag.value == true ? 'black' : 'red'">
              {{'REGISTRATION.termsPolicyToggle' | transloco}}<a href="{{storageBaseUrl}}/policies/GEO-NUV-GTC-22_{{currentLanguage}}.pdf" target="_blank">{{'REGISTRATION.termsPolicyLink' | transloco}}</a> *
            </mat-checkbox>
          </div>
        </div>
        
        <!-- Whatsapp Notification-->
        <div fxLayout.gt-sm="row" fxLayoutGap.gt-sm="16px" *ngIf="currentLoggedInUser.role == 'DO' || currentLoggedInUser.role == 'AG'">
          <div fxFlex fxFlex.gt-sm="495px" fxLayoutAlign="start center">
            <mat-checkbox formControlName="whatsappFlag" (change)="clearWhatsappSelection($event)" fxFlex>{{'REGISTRATION.whatsappFlag1' | transloco}}
              <span *ngIf="userForm.controls.whatsappFlag.value == true">{{'REGISTRATION.whatsappFlag2' | transloco}}</span>
            </mat-checkbox>       
          </div>
          <div fxFlex fxFlex.gt-sm="80%" *ngIf="userForm.controls.whatsappFlag.value == true">
            <select style="height: 35px; width: 200px;margin-right: 10px;" formControlName="whatsappCountryCode" (change)="chooseCountry($event.target.value)">
              <option value="">{{'REGISTRATION.whatsappChooseCountry' | transloco}} *</option>
              <option *ngFor="let country of allCountries" [value]="country.iso2">
                {{country.name}} (+{{country.dialCode}})</option>
            </select>
            <mat-form-field class="example-full-width">
              <input type="text" matInput  placeholder="{{'REGISTRATION.whatsappNumber' | transloco}} *" (keypress)="keyPressNumbers($event)" 
              formControlName="whatsappNumber" maxlength="15">
              <mat-icon matSuffix>whatsapp</mat-icon>
            </mat-form-field>            
          </div>
        </div>
        <input type="text" formControlName="whatsappDialCode" style="display:none">
        <!-- Show Steps Notification-->
        <div fxLayout.gt-sm="row" fxLayoutGap.gt-sm="16px" *ngIf="currentLoggedInUser.role == 'DO'">
          <div fxFlex fxFlex.gt-sm="495px" fxLayoutAlign="start center">
            <mat-checkbox formControlName="showStepsWarning" fxFlex>{{'REGISTRATION.showstepswarningLabel' | transloco}}</mat-checkbox>       
          </div>
        </div>
        <!-- Show Nuvola Junior Treatments-->
        <div fxLayout.gt-sm="row" fxLayoutGap.gt-sm="16px" *ngIf="currentLoggedInUser.role == 'DO'">
          <div fxFlex fxFlex.gt-sm="495px" fxLayoutAlign="start center">
            <mat-checkbox formControlName="showNuvolaJunior" [disabled]="true" fxFlex>{{'REGISTRATION.showNuvolaJuniorLabel' | transloco}}</mat-checkbox>       
          </div>
        </div>
        <div fxLayout.gt-sm="row" fxLayoutGap.gt-sm="16px" *ngIf="currentLoggedInUser.executiveArea == 'A' || currentLoggedInUser.role == 'SA'">
          <div fxFlex fxFlex.gt-sm="495px" fxLayoutAlign="start center">
            <mat-checkbox formControlName="showNuvolaJunior" fxFlex>{{'REGISTRATION.showNuvolaJuniorLabel' | transloco}}</mat-checkbox>       
          </div>
        </div>
        
        <div fxLayout.gt-sm="row" fxLayoutGap.gt-sm="16px" style="margin-bottom: 20px;" 
              *ngIf="(currentLoggedInUser.role != 'DO' && currentLoggedInUser.role != 'AG') && selectedRow && selectedRow.role == 'DO'">
          <div fxFlex fxFlex.gt-sm="50%">
            <div *ngIf="policyConfirmedDate == null">
              {{'REGISTRATION.policyConfirmedDate' | transloco}} <strong>N/D</strong>
            </div>
            <div *ngIf="policyConfirmedDate != null">
              {{'REGISTRATION.policyConfirmedDate' | transloco}} <strong>{{policyConfirmedDate | date: 'dd/MM/yyyy HH:mm'}}</strong>
            </div>            
            <mat-checkbox formControlName="privacyFlag" fxFlex style="display: none;"></mat-checkbox>    
          </div>
          <div fxFlex fxFlex.gt-sm="50%">
            {{'REGISTRATION.whatsappFlag1' | transloco}} 
            {{'REGISTRATION.whatsappFlag2' | transloco}}
            <strong *ngIf="userForm.controls.whatsappFlag.value == true">{{whatsappPhoneNumber}}</strong>
            <strong *ngIf="userForm.controls.whatsappFlag.value == false">N/D</strong>
          </div>
        </div>
        
        <div fxLayout.gt-sm="row" fxLayoutGap.gt-sm="16px" *ngIf="userForm.controls.role.value == 'DO'">
          <div fxFlex *ngIf="!isEditLoading">
            <nuvolaweb-doctor-address-grid [addresses]="addresses" (saveAddress)="addressSaveHandle($event)"></nuvolaweb-doctor-address-grid>
          </div>
        </div>  
      </div>
      <div *ngIf="userForm.controls.role.value == 'US'">
        <div fxLayout.gt-sm="row" fxLayoutGap.gt-sm="16px">
          <mat-form-field fxFlex fxFlex.gt-sm="32%">
            <mat-label>{{'REGISTRATION.executiveLabel' | transloco}}</mat-label>
            <mat-select formControlName="idExecutive" aria-required="true" required >
              <mat-option value="0">
                {{'COMMON.NAValueOption' | transloco}}
              </mat-option>
              <div *ngFor="let executive of executives">
                <mat-option value="{{executive.idAdmin}}">
                  {{executive.lastName}} {{executive.firstName}}
                </mat-option>
              </div>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex fxFlex.gt-sm="32%">
            <mat-label>{{'REGISTRATION.groupLabel' | transloco}}</mat-label>
            <mat-select formControlName="userGroup" aria-required="true">
              <mat-option value="">
                {{'COMMON.NAValueOption' | transloco}}
              </mat-option>
              <div *ngFor="let group of groups">
                <mat-option value="{{group.optionCode}}">
                  {{getCurrentGroupTittle(group)}}
                </mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
  </form>
  <div fxLaout='row' fxLayoutAlign="space-between center">
    <div fxFlex >
      <small *ngIf="!selectedRow" style="font-size: 12px;">{{'RESET.InfoText' | transloco}}</small>
    </div>
    <div fxFlex fxLayout='row' fxLayoutAlign="end" *ngIf="!isEditLoading" style="padding-top: 7px;" fxLayoutGap="16px"> 
      <button mat-raised-button color='warn' (click)="closeModal()" *ngIf="selectedRow && selectedRow.profileComplete">
        {{'COMMON.CancelButton' | transloco}}
      </button>
      <button *ngIf="!selectedRow" mat-raised-button class="btn-default" (click)="save()">
        {{'COMMON.SaveButton' | transloco}}
      </button>
      <button *ngIf="selectedRow && !isDoctorToApprove" mat-raised-button class="btn-default" (click)="update()">
        {{'COMMON.SaveButton' | transloco}}
      </button>
      <button *ngIf="selectedRow && isDoctorToApprove" mat-raised-button class="btn-default" (click)="update()">
        {{'COMMON.Approve' | transloco}}
      </button>
    </div>
  </div>
  

<ng-template #resetPasswordModal>
  <mat-card-content fxLayout="column" fxLayoutAlign="center center">
    <span style="width: 300px;">
      {{'COMMON.ResetPasswordText' | transloco}}
    </span>
  </mat-card-content>
  <mat-card-actions fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="end end">
      <button mat-raised-button color="warn" (click)="closeResetModal()">
        {{'COMMON.CancelButton' | transloco}}
      </button>
      <button mat-raised-button class="btn-default" (click)="reset()">
        {{ 'COMMON.ConfirmButton' | transloco}}
      </button>
  </mat-card-actions>
</ng-template>

<ng-template #sendEmailModal>
  <mat-card-content fxLayout="column" fxLayoutAlign="center center">
    <span style="width: 300px;">
      {{'users.activeDoctorMessage' | transloco}}
    </span>
  </mat-card-content>
  <mat-card-actions fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="end end">
      <button mat-raised-button color="warn" (click)="closeSendEmailModal()">
        {{'COMMON.CancelButton' | transloco}}
      </button>
      <button mat-raised-button class="btn-default" (click)="sendConfirmEmailToDoctor()">
        {{ 'COMMON.ConfirmButton' | transloco}}
      </button>
  </mat-card-actions>
</ng-template>