<div class="container" style="text-align: center;" *ngIf="displayName" [style.color]="disabled? 'rgba(0, 0, 0, 0.7)' : ''" >
		<h6>{{displayName}} {{required? '*' : ''}}</h6>
</div>

<div class="dnd-container" appDnd (fileDropped)="onFileDropped($event)" 
		[style.backgroundImage]="'url('+imgUrl+')'" style="background-size: 100% 100%; " *ngIf="!fileArePresent && file == null">
	<input type="file" [accept]="acceptedFileType" #fileDropRef id="{{tagId}}" (change)="fileBrowseHandler($event.target.files)" [disabled]="disabled"/>
	<div *ngIf="file == null">
		<svg xmlns="http://www.w3.org/2000/svg" class="dropImg" viewBox="0 0 63 64" >
			<g fill="#3B454F" fill-rule="nonzero">
				<path
					d="M42.656 15.135a1.953 1.953 0 0 1-1.391-.578L31.5 4.795l-9.765 9.762a1.97 1.97 0 1 1-2.785-2.785L30.106.616a1.97 1.97 0 0 1 2.785 0l11.157 11.156a1.97 1.97 0 0 1-1.392 3.363z" />
				<path
					d="M31.5 36.791a1.97 1.97 0 0 1-1.969-1.969V2.01a1.97 1.97 0 0 1 3.938 0v32.812a1.97 1.97 0 0 1-1.969 1.969z" />
				<path
					d="M55.781 63.041H7.22A7.225 7.225 0 0 1 0 55.822V41.385a4.599 4.599 0 0 1 4.594-4.594h7.234a4.567 4.567 0 0 1 4.402 3.276l2.814 9.382a.658.658 0 0 0 .628.467h23.656a.658.658 0 0 0 .628-.467l2.814-9.385a4.572 4.572 0 0 1 4.402-3.273h7.234A4.599 4.599 0 0 1 63 41.385v14.437a7.225 7.225 0 0 1-7.219 7.219zM4.594 40.729a.656.656 0 0 0-.657.656v14.437a3.286 3.286 0 0 0 3.282 3.282H55.78a3.286 3.286 0 0 0 3.282-3.282V41.385a.656.656 0 0 0-.657-.656h-7.234a.65.65 0 0 0-.628.467L47.73 50.58a4.628 4.628 0 0 1-4.402 3.274H19.672a4.567 4.567 0 0 1-4.402-3.276l-2.814-9.382a.65.65 0 0 0-.628-.467H4.594z" />
			</g>
		</svg>
	
		<h3 [style.color]="disabled ? 'rgba(0, 0, 0, 0.7)' : ''">{{'COMMON.dropHere' | transloco}}</h3>
		<h3 [style.color]="disabled ? 'rgba(0, 0, 0, 0.7)' : ''">{{'COMMON.or' | transloco}}</h3>
		<label for="{{tagId}}" 
			[style.background-color]="disabled ? 'rgba(0, 0, 0, 0.7)' : ''"
			[style.cursor]="disabled ? 'not-allowed' : 'pointer'">{{'COMMON.browserFile' | transloco}}</label>
	</div>
	<!-- <div *ngIf="file" >
		<img style="width: 250px; height: 175px;" [src]="fileBlob"/>
	</div> -->
</div>

<div class="files-list">
	<div class="single-file" *ngIf="file">
		<div class="file-icon" class="fileImg">
			<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
				x="0px" y="0px" viewBox="0 0 58 58" style="enable-background:new 0 0 58 58;" xml:space="preserve">
				<polygon style="fill:#EDEADA;" points="51.5,14 37.5,0 6.5,0 6.5,58 51.5,58 " />
				<g>
					<path style="fill:#CEC9AE;"
						d="M16.5,23h25c0.552,0,1-0.447,1-1s-0.448-1-1-1h-25c-0.552,0-1,0.447-1,1S15.948,23,16.5,23z" />
					<path style="fill:#CEC9AE;"
						d="M16.5,15h10c0.552,0,1-0.447,1-1s-0.448-1-1-1h-10c-0.552,0-1,0.447-1,1S15.948,15,16.5,15z" />
					<path style="fill:#CEC9AE;"
						d="M41.5,29h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,29,41.5,29z" />
					<path style="fill:#CEC9AE;"
						d="M41.5,37h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,37,41.5,37z" />
					<path style="fill:#CEC9AE;"
						d="M41.5,45h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,45,41.5,45z" />
				</g>
				<polygon style="fill:#CEC9AE;" points="37.5,0 37.5,14 51.5,14 " />
			</svg>
		</div>
		<div class="info">
			<h4 class="name">
				{{ fileName }}{{extention ? '.' + extention : ''}}
			</h4>
			<p class="size">
				{{ formatBytes(file?.size, 2) }}
			</p>
		</div>

		<div class="delete" (click)="deleteFileNotUploadedYet()" *ngIf="allowDelete">
			<mat-icon>delete</mat-icon>
		</div>
	</div>

	<div class="single-file" *ngIf="fileArePresent">
		<div class="file-icon" class="fileImg">
			<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
				x="0px" y="0px" viewBox="0 0 58 58" style="enable-background:new 0 0 58 58;" xml:space="preserve">
				<polygon style="fill:#EDEADA;" points="51.5,14 37.5,0 6.5,0 6.5,58 51.5,58 " />
				<g>
					<path style="fill:#CEC9AE;"
						d="M16.5,23h25c0.552,0,1-0.447,1-1s-0.448-1-1-1h-25c-0.552,0-1,0.447-1,1S15.948,23,16.5,23z" />
					<path style="fill:#CEC9AE;"
						d="M16.5,15h10c0.552,0,1-0.447,1-1s-0.448-1-1-1h-10c-0.552,0-1,0.447-1,1S15.948,15,16.5,15z" />
					<path style="fill:#CEC9AE;"
						d="M41.5,29h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,29,41.5,29z" />
					<path style="fill:#CEC9AE;"
						d="M41.5,37h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,37,41.5,37z" />
					<path style="fill:#CEC9AE;"
						d="M41.5,45h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,45,41.5,45z" />
				</g>
				<polygon style="fill:#CEC9AE;" points="37.5,0 37.5,14 51.5,14 " />
			</svg>
		</div>
		<div class="info">
			<h4 class="name">
				{{ fileName + fileExtension }}
			</h4>
			<!-- <app-progress [progress]="file?.progress"></app-progress> -->
		</div>

		<div class="openImage" (click)="openImage()" *ngIf="isAllowToRenderOpenImage()">
			<mat-icon>visibility</mat-icon>
		</div>

		<div class="delete" (click)="downloadFile()">
			<mat-icon *ngIf="!isDownloadingFile">download</mat-icon>
			<mat-spinner *ngIf="isDownloadingFile" diameter="20" color="accent"></mat-spinner>
		</div>

		<div class="delete" *ngIf="!disabled && !fileArePresent"  (click)="delete()">
			<mat-icon>delete</mat-icon>
		</div>

		<div class="delete" *ngIf="!disabled && fileArePresent && allowDelete" (click)="deleteFileModal(deleteImageConfirmation)">
			<mat-icon>delete</mat-icon>
		</div>

	</div>
	<div *ngIf="fileArePresent && fullImageUrl != ''" [style.backgroundImage]="'url('+fullImageUrlPreview+')'"
		style="border: dashed 1px #979797; width: 210px;height: 175px;background-repeat: no-repeat;background-position: center;background-size: contain;" ></div>
</div>

<ng-template #deleteImageConfirmation>
	<mat-card-content fxLayout="column" fxLayoutAlign="center center">
	  <span>
		{{'COMMON.confirmDeleteMessage' | transloco}}
	  </span>
	</mat-card-content>
	<mat-card-actions >
	  <div fxLayout="row" style="padding-top:25px;" fxLayoutGap="16px" fxLayoutAlign="center center">
		<button mat-raised-button color="warn" (click)="closeDeleteModal()">
		  {{'COMMON.CancelButton' | transloco}}
		</button>
		<button mat-raised-button class="btn-default" (click)="deleteFile()">
		  {{ 'COMMON.ConfirmButton' | transloco}}
		</button>
	  </div>
	</mat-card-actions>
  </ng-template>