import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { httpHeaders } from '../../../../environments/httpHeaders';
import { environment } from '../../../../environments/environment';
import { endWith, map } from 'rxjs/operators';
import { News } from '../shared/models/news';
import { Shopping } from '../shared/models/shopping';
import { StorageFile } from '../shared/models/file';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/modules/security/services/user.service';

const apiURL = environment.apiUrl
const headers = httpHeaders.headers
const fileHeader = httpHeaders.fileHeaders;

@Injectable({
  providedIn: 'root'
})
export class ContentsService {

  constructor(
    private _http:HttpClient,
    private _userService: UserService
  ) { }


  getNewsContent(onlyActive:boolean = false){
    const endPoint = "/api/news/"+onlyActive;
    return this._http.get(apiURL + endPoint, {headers:headers}).pipe(map((response:News[]) => {
      return response;
    }))
  }
  
  getNewsById(id){
    const endPoint = "/api/news/id/"+id;
    return this._http.get(apiURL + endPoint, {headers:headers}).pipe(map((response:News) => {
      return response;
    }))
  }

  getShoppingContent(onlyActive:boolean = false){
    const endPoint = "/api/shopping/"+onlyActive;
    return this._http.get(apiURL + endPoint, {headers:headers}).pipe(map((response:Shopping[]) => {
      return response;
    }));
  }

  getShoppingById(id:any){
    const endPoint = "/api/shopping/id/"+id;
    return this._http.get(apiURL + endPoint, {headers:headers}).pipe(map((response:Shopping) => {
      return response;
    }));
  }

  deleteShoppingContent(idShopping){
    const endPoint =  "/api/shopping/" + idShopping;
    return this._http.delete(apiURL + endPoint, {headers:headers});
  }

  updateShoppingContent(shopping){
    const endPoint =  "/api/shopping/";
    return this._http.post(apiURL + endPoint, shopping, {headers:headers});
  }

  createShoppingContent(shopping){
    const endPoint =  "/api/shopping/";
    return this._http.put(apiURL + endPoint, shopping, {headers:headers}).pipe(map((resp:Shopping) =>{
      return resp;
    }));
  }

  createNewsContent(news){
    const endPoint =  "/api/news/";
    return this._http.put(apiURL + endPoint, news, {headers:headers}).pipe(map((resp:News) =>{
      return resp;
    }));
  }
  
  deleteNewsContent(idNews){
    const endPoint =  "/api/news/" + idNews;
    return this._http.delete(apiURL + endPoint, {headers:headers});
  }

  updateNewsContent(news){
    const endPoint =  "/api/news/";
    return this._http.post(apiURL + endPoint, news, {headers:headers});
  }

  uploadContentFile(file:any, containerName:string){
    const endPoint = "/api/storage";
    var formData = new FormData();
    formData.append("fileObject", file, file.name);
    formData.append("containerName", containerName);
    formData.append("createContainerIfNotExists", "false");

    return this._http.put(apiURL + endPoint,formData).pipe(map((resp:StorageFile) => 
    {
      return resp;
    }));
  }

  uploadContentBlob(formData:FormData, containerName:string, createContainer:boolean = false) {
    const endPoint = "/api/storage";
    formData.append("createContainerIfNotExists", `${createContainer}`);
    formData.append("containerName", containerName);

    return this._http.put(apiURL + endPoint, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  getContentFile(containerName, fileName){
    const endPoint = "/api/storage/";
    var reques = {
      containerName:containerName? containerName:"",
      fileName:fileName
    };
    return this._http.get(apiURL + endPoint, {params:reques,  
      responseType: 'blob',
      observe: 'response',
      reportProgress:true}).pipe(map(resp => {
        return resp.body;
      }));
  }

  deleteContentFile(containerName, fileName){
    const endPoint = "/api/storage/";
    var reques = {
      containerName:containerName? containerName:"",
      fileName:fileName
    };
    return this._http.delete(apiURL + endPoint, {headers:headers, params:reques});
  }

  getPopupsForUser(id){
    var endPoint = `/api/news/popups/${id}`;

    return this._http.get(`${apiURL}${endPoint}`, {headers:headers});
  }

  markAsRead(idNews: string, idAdmin: number) {
    var endPoint = `/api/news/popups/markasread/${idNews}/${idAdmin}`;
    return this._http.put(`${apiURL}${endPoint}`, null, {headers:headers});
  }

  getSupportClinicContent(){
    var endPoint = "/api/support/clinic";
    return this._http.get(`${apiURL}${endPoint}`, {headers:headers});
  }

  deleteClinicSupport(idClinicSupport: any) {
    var endPoint = `/api/support/clinic/${idClinicSupport}`;
    return this._http.delete(apiURL + endPoint, {headers:headers});
  }

  getClinicContentById(idClinicSupport: any) {
    var endPoint = `/api/support/clinic/${idClinicSupport}`;
    return this._http.get(apiURL + endPoint, {headers:headers});
  }

  createClinicContent(request){
    var endPoint = `/api/support/clinic/`;
    return this._http.put(apiURL + endPoint, request, {headers:headers});
  }

  updateClinicContent(request){
    var endPoint = `/api/support/clinic/`;
    return this._http.post(apiURL + endPoint, request, {headers:headers});
  }

  getContentLink(containerName, fileName) {
    const endPoint = "/api/storage/url";
    var reques = {
      containerName:containerName? containerName:"",
      fileName:fileName
    };
    return this._http.get(apiURL + endPoint, {headers:headers, params:reques});
  }

  getWebViewerUrl(treatmentCode, idTreatment){
    const endPoint = `/api/storage/getwebviewerurl/${treatmentCode}/${idTreatment}`;
    return this._http.get(`${apiURL}${endPoint}`, {headers:headers});
  }

  getWebViewerUrlFromFile(treatmentCode, idTreatment, videoFile){
    const endPoint = `/api/storage/getwebviewerurlfromfile/${treatmentCode}/${idTreatment}/${videoFile}`;
    return this._http.get(`${apiURL}${endPoint}`, {headers:headers});
  }

  getWebViewerFiles(treatmentCode){
    const endPoint = `/api/storage/getwebviewerfiles/${treatmentCode}`;
    return this._http.get(`${apiURL}${endPoint}`, {headers:headers});
  }

  verifyWebViewerUrl(treatmentCode){
    const endPoint = `/api/storage/verifywebviewer/${treatmentCode}`;
    return this._http.get(`${apiURL}${endPoint}`, {headers:headers});
  }

  buy(request) {
    const endPoint = `/api/shopping/buy`;
    return this._http.post(`${apiURL}${endPoint}`, request, {headers:headers});
  }

  setContentHolderCountValue(container: string, value:number){
    let currentUser = this._userService.readUserFromLocalStorage();
    currentUser[container] = value;
    this._userService.setUserToLocalStorage(currentUser);
  }

  getContentHolderCountValue(container: string){
    let currentUser = this._userService.readUserFromLocalStorage();
    
    if(!currentUser){
      return;
    }

    if(!currentUser[container]){
      return 0;
    }

    if(currentUser[container] != undefined){
      return currentUser[container];
    }

    return 0;
  }
}
