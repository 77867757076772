<img src="../../../../assets/img/nuvola_logo.png" style="position:absolute;top:20;left:20;z-index:2;margin-left: 20px;">
<div style="z-index:2;min-height:70px;margin: 0 0 0 0;width: 100%;text-align: center;background-color: rgb(238, 238, 238)"
    *ngIf="showHeaderBar && treatmentId && currentUser!=null">
    <img src="../../../../assets/img/webviewer/webviewer-icon-ipr.svg" (click)="openIPRModal()" class="webviewerIcon"
        matTooltip="{{'patient.ADDITIONAL.IPR' | transloco }}">
    <img src="../../../../assets/img/webviewer/webviewer-icon-chat.svg" (click)="openChatModal(chatModal)"
        class="webviewerIcon" matTooltip="{{'patient.ADDITIONAL.chatLabel' | transloco }}">
    <img src="../../../../assets/img/webviewer/webviewer-icon-images.svg" (click)="openImagePreviewModal(imagePreview)"
        class="webviewerIcon" matTooltip="{{'patient.GENERAL_INFO.imagePreviewTitle' | transloco }}">
    <img src="../../../../assets/img/webviewer/webviewer-icon-pdt.svg" (click)="openPDT()" class="webviewerIcon"
        matTooltip="{{'patient.ADDITIONAL.valuesTableLabel' | transloco }}">
    <!-- *ngIf="treatmentId && currentUser!=null" -->
    <div style="width: 500px; position: absolute;right: 0;top:0;height: 70px;text-align: right;"
        *ngIf="treatmentId && currentUser!=null && currentUser.role=='DO'">
        <div *ngIf="isLoading" style="width: 100px;float: right;margin-top: 20px;">
            <mat-progress-spinner class="margin" [mode]="'indeterminate'" [color]="'accent'" [value]="10"
                [diameter]="35">
            </mat-progress-spinner>
        </div>
        <button *ngIf="!isLoading" class="btn-default" (click)="copyToClipboard(userinput)"
            style="margin-right: 10px;margin-top: 15px;" 
            mat-raised-button>{{'patient.ADDITIONAL.copyWebViewLinkToClipboard' | transloco}}</button>
        <button *ngIf="!isLoading && selectedTreatment?.status == 'INREVIEW'" class="btn-default"
            style="margin-right: 10px;margin-top: 15px;"
            (click)="openConfirmModal('sendNuvola', confirmSendToNuvolaModal)"
            mat-raised-button>{{'patient.ADDITIONAL.sendToNuvola' | transloco}}</button>
        <button *ngIf="!isLoading && selectedTreatment?.status == 'INREVIEW'" class="btn-default"
            style="margin-right: 10px;" (click)="openConfirmModal('approve', confirmModal)"
            mat-raised-button>{{'patient.ADDITIONAL.approveTratmentPlan' | transloco}}</button>
        <button *ngIf="!isLoading && selectedTreatment?.status == 'INREVIEW'" class="btn-default"
            style="margin-right: 20px;" (click)="openConfirmModal('reject', confirmModal)"
            mat-raised-button>{{'patient.ADDITIONAL.rejectTreatmentPlan' | transloco}}</button>
        <!-- <button onclick="ResizeiFrame()">resize</button> -->
    </div>
</div>
<div style="background-color: rgb(238, 238, 238);width: 100%;height: 300px;z-index: 2;display: none;border-bottom: 2px solid #72B5AE;" id="divIPR">

    <div >
        <div fxFlex="20" style="margin-left: 20px;margin-top: 50px;">
            <div fxLayout="column" fxLayoutAlign="center center" >
                <h5 style="color:#72B5AE">{{'patient.GENERAL_INFO.arches' | transloco}}</h5>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center" >
                <select (change)="onChangeIPRItem($event.target.value)">
                    <option value="U" *ngIf="selectedTreatment?.archesHandled == 'U' || selectedTreatment?.archesHandled == 'B'">{{'patient.ADDITIONAL.upperArchLabel' | transloco}}</option>
                    <option value="L" *ngIf="selectedTreatment?.archesHandled == 'L' || selectedTreatment?.archesHandled == 'B'">{{'patient.ADDITIONAL.lowerArchLabel' | transloco}}</option>
                </select>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center" style="margin-top: 50px;">
                <h6 style="color:#72B5AE">{{'patient.ADDITIONAL.iprLegend' | transloco}}</h6>
                <div style='display: inline-block;font-size:90%;margin-top: 10px;width: 100%;margin-left: 40px;'>
                    <div style='width: 33%; float: left; margin-bottom: 5px;'>
                        <div style='border:solid 1px #000;height:28px;width:18px;float:left;background-color:red;'></div>
                        <div style='float:left;margin-top:5px;margin-left:5px;'>0,10 mm</div>
                    </div>
                    <div style='width: 33%; float: left; margin-bottom: 5px;'>
                        <div style='border:solid 1px #000;height:28px;width:18px;float:left;background-color:blue;'></div>
                        <div style='float:left;margin-top:5px;margin-left:5px;'>0,20 mm</div>
                    </div>
                    <div style='width: 33%; float: left; margin-bottom: 5px;'>
                        <div style='border:solid 1px #000;height:28px;width:18px;float:left;background-color:green;'></div>
                        <div style='float:left;margin-top:5px;margin-left:5px;'>0,30 mm</div>
                    </div>
                </div>
                <div style='width: 100%; display: inline-block;font-size:90%;margin-top: 5px;margin-left: 40px;'>
                    <div style='width: 33%; float: left; margin-bottom: 5px;'>
                        <div style='margin-top:8px;margin-left:5px;'>N = {{ 'patient.ADDITIONAL.iprNeutral' | transloco}}</div>
                    </div>
                    <div style='width: 33%; float: left; margin-bottom: 5px;'>
                        <div style='margin-top:8px;margin-left:5px;'>D = {{ 'patient.ADDITIONAL.iprDistal' | transloco}}</div>
                    </div>
                    <div style='width: 33%; float: left; margin-bottom: 5px;'>
                        <div style='margin-top:8px;margin-left:5px;'>M = {{ 'patient.ADDITIONAL.iprMesial' | transloco}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div fxFlex="80" fxLayoutAlign="center center" style="margin-top: 20px;" *ngIf="showIPR_Upper"> 
            <nuvolaweb-upper-arch-table *ngIf="isUpperAbleToShow && showIPR_Upper" forceDisable="true" hideTitle="true"
                [upperArch_1]="iprModel.upperArch_1"
                [upperArch_2]="iprModel.upperArch_2"
                [upperArch_3]="iprModel.upperArch_3"
            ></nuvolaweb-upper-arch-table>
        </div>
        <div fxFlex="80" fxLayoutAlign="center center" style="margin-top: 20px;" *ngIf="showIPR_Lower">
            <nuvolaweb-lower-arch-table *ngIf="isLowerAbleToShow && showIPR_Lower" forceDisable="true" hideTitle="true"
                [lowerArch_1]="iprModel.lowerArch_1"
                [lowerArch_2]="iprModel.lowerArch_2"
                [lowerArch_3]="iprModel.lowerArch_3"
            ></nuvolaweb-lower-arch-table>
        </div>
    </div>

</div>
<div id="divWebViewer"></div>

<!-- <div style="position: absolute;top:0px;right: 0px;background-color: rgb(238, 238, 238);height: 100%;width: 800px;display: none;border-left: 2px solid #72B5AE;"
    id="div_webviewer_ImagesPreview">

    <div style="color:#72B5AE;width: 100%; text-align: center;">
        <h4>
            {{'patient.GENERAL_INFO.imagePreviewTitle' | transloco}}
        </h4>
    </div>
    <div style="margin-top: 20px;margin-left: 70px;">
        <carousel (events)="handleCarouselEvents($event)" [images]="imagesCollection" [cellWidth]="'100%'"
            [arrowsOutside]="true" [autoplay]="false" [dots]="false" [overflowCellsLimit]="1" [loop]="false"
            [width]="600" [height]="500" arrowsTheme="dark">
        </carousel>
    </div>
</div> -->

<div *ngIf="showImagePreviewPanel" style="position: absolute;top:70px;right: 80px;z-index: 2;width: 440px; background-color: rgb(238, 238, 238); box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);"
        cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="modalDragTitle">
    <mat-card-content fxLayout="column" fxLayoutAlign="center center" style="border: 2px solid #72B5AE" >
        <h4 mat-dialog-title> 
            {{'patient.GENERAL_INFO.imagePreviewTitle' | transloco}}
        </h4>
        <div style="margin-top: 20px;margin-bottom: 20px;">
            <carousel (events)="handleCarouselEvents($event)" [images]="imagesCollection" [cellWidth]="'100%'"
                [arrowsOutside]="true" [autoplay]="false" [dots]="false" [overflowCellsLimit]="1" [loop]="false"
                [width]="300" [height]="250" arrowsTheme="dark">
            </carousel>
        </div>
    </mat-card-content>  
</div>

<!-- <ng-template #imagePreview>
    <mat-card-content fxLayout="column" fxLayoutAlign="center center">
        <h4 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="modalDragTitle"> 
            {{'patient.GENERAL_INFO.imagePreviewTitle' | transloco}}
        </h4>
        <div style="margin-top: 20px;">
            <carousel (events)="handleCarouselEvents($event)" [images]="imagesCollection" [cellWidth]="'100%'"
                [arrowsOutside]="true" [autoplay]="false" [dots]="false" [overflowCellsLimit]="1" [loop]="false"
                [width]="300" [height]="250" arrowsTheme="dark">
            </carousel>
        </div>
    </mat-card-content>
    <mat-card-actions fxLayoutAlign="center center">
        <div fxLayout="row" style="padding-top:25px;" fxLayoutGap="16px">
            <button mat-raised-button class="mat-primary" (click)="closeImagesPreviewModal()">
                {{'COMMON.CloseButton' | transloco}}
            </button>
        </div>
    </mat-card-actions>
</ng-template> -->

<div *ngIf="showChatPanel" style="position: absolute;bottom:20px;right: 80px;z-index: 2;width: 440px; background-color: rgb(238, 238, 238); box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);"
        cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="modalDragTitle">
    <mat-card-content fxLayout="column" fxLayoutAlign="center center">
        <h4 mat-dialog-title>
            {{'patient.ADDITIONAL.chatLabel' | transloco}}
        </h4>

        <div style="position: relative;height: 400px;width:100%;text-align: center;">
            <div fxFlex style="height: 100%; width: 100%; position: absolute;">
                <nuvolaweb-treatment-chat [treatment]="selectedTreatment" [chatType]="'T'">
                </nuvolaweb-treatment-chat>
            </div>
        </div>

    </mat-card-content>
</div>

<ng-template #confirmModal>

    <mat-card-content *ngIf="!isLoading" fxLayout="column" fxLayoutAlign="center center">
        <span>
            {{ modalMessage | transloco}}
        </span>
    </mat-card-content>
    <mat-card-actions fxLayoutAlign="center center">
        <div fxLayout="row" style="padding-top:25px;" fxLayoutGap="16px">
            <button mat-raised-button color="warn" (click)="closeConfirmModal()">
                {{'COMMON.CancelButton' | transloco}}
            </button>
            <button mat-raised-button class="btn-default" (click)="confirm()">
                {{ 'COMMON.ConfirmButton' | transloco}}
            </button>
        </div>
    </mat-card-actions>
</ng-template>

<ng-template #confirmSendToNuvolaModal>

    <mat-card-content *ngIf="!isLoading" fxLayout="column" fxLayoutAlign="center center">
        <span>
            {{ modalMessage | transloco}}
        </span>
    </mat-card-content>

    <mat-card-content style="padding-top: 15px;">
        <div style="width: 100%;margin-top: 20px;margin-bottom: 10px;">{{ 'patient.InReviewReasonDoctor' | transloco}}
        </div>
        <div>
            <textarea fxFlex=100% matInput [(ngModel)]="sendToNuvolaDoctorMessage" rows="3"
                placeholder="{{ 'patient.incompleteReason' | transloco}}"></textarea>
        </div>
    </mat-card-content>

    <mat-card-actions fxLayoutAlign="center center">
        <div fxLayout="row" style="padding-top:25px;" fxLayoutGap="16px">
            <button mat-raised-button color="warn" (click)="closeconfirmSendToNuvolaModal()">
                {{'COMMON.CancelButton' | transloco}}
            </button>
            <button mat-raised-button class="btn-default" (click)="confirmSendToNuvola()">
                {{ 'COMMON.ConfirmButton' | transloco}}
            </button>
        </div>
    </mat-card-actions>
</ng-template>